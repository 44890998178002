import { makeStyles, createStyles } from '@material-ui/core/styles';

const ErrorsStyles = makeStyles((theme: any) => createStyles({
  gridHeader: {
    color: "#00B6FF",
    fontFamily: "Open Sans, sans-serif",
    position: "relative",
    fontSize: "2.25rem",
    fontWeight: 400,
    '@media(max-width: 850px)': {
      fontSize: "1.8rem"
    },
    '@media(max-width: 500px)': {
      fontSize: "1.7rem"
    }
  },
  gridHeaderErrors: {
    top: 995,
    '@media(max-width: 1080px)': {
      top: 925
    }
  },
  divider: {
    color: "white",
    backgroundColor: "white",
    opacity: 1,
    height: 1,
    position: "relative",
  },
  errorsDivider: {
    top: 1020,
    '@media(max-width: 1080px)': {
      top: 950
    }
  },
  errorsSubheader: {
    color: "#FFB700",
    position: "relative",
    top: 0,
    left: 0,
    fontSize: "1.5rem",
    '@media(max-width: 850px)': {
      fontSize: "1.3rem"
    }
  },
  errorsListHeader: {
    fontSize: "1.25rem",
    color: "#EEAA00",
    position: "relative",
    top: 40,
    '@media(max-width: 500px)': {
      fontSize: "1rem"
    }
  },
  errorsText: {
    color: "white",
    position:"relative",
    fontSize: "1rem",
    top: 50,
    width: 470,
    '@media(max-width: 1080px)': {
      width: 390
    },
    '@media(max-width: 850px)': {
      width: 310,
    },
    '@media(max-width: 675px)': {
      width: 480,
    },
    '@media(max-width: 500px)': {
      width: 285,
    }
  }
})
)

export { ErrorsStyles };