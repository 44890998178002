import { Link, Paper, Typography } from "@material-ui/core";
import React, { ReactElement, useEffect } from "react";

import styles from "./PrivacyPolicy.module.css";
import { PrivacyPolicyStyles } from "./PrivacyPolicyStyles";

interface Props {}

export default function PrivacyPolicy(props: Props): ReactElement {
  const classes = PrivacyPolicyStyles();

  return (
    <Paper className={classes.privacyPolicyHolder}>
      <div className={styles.privacyPolicyTextWrapper}>
        <Typography variant="h1" className={classes.privacyPolicyHeader}>
          Showboat Privacy Policy
        </Typography>

        <Typography variant="body1" className={classes.privacyPolicySubheader}>
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or make a purchase from
          showboat.live (the “Site”).
        </Typography>

        <Typography variant="h2" className={classes.privacyPolicyTextHeader}>
          PERSONAL INFORMATION WE COLLECT
        </Typography>

        <Typography variant="body1" className={classes.privacyPolicyText}>
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages that you view, what websites or search
          terms referred you to the Site, and information about how you interact
          with the Site. We refer to this automatically-collected information as
          “Device Information.”
        </Typography>

        <Typography variant="h3" className={classes.privacyPolicyTextSubheader}>
          PERSONAL INFORMATION WE COLLECT
        </Typography>

        <ul className={styles.list}>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              “Cookies” are data files that are placed on your device or
              computer and often include an anonymous unique identifier. For
              more information about cookies, and how to disable cookies, visit
              http://www.allaboutcookies.org.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              “Log files” track actions occurring on the Site, and collect data
              including your IP address, browser type, Internet service
              provider, referring/exit pages, and date/time stamps.
            </Typography>
          </li>
        </ul>

        <Typography variant="body1" className={classes.privacyPolicyText}>
          Additionally when you attend an event or attempt to attend an event
          through the Site, we collect certain information from you, including
          your name, address, email address, and phone number. We refer to this
          information as “Attendee Information.”
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.privacyPolicyText} ${classes.privacyPolicyTextBottom}`}
        >
          When we talk about “Personal Information” in this Privacy Policy, we
          are talking both about Device Information and Attendee Information.
        </Typography>

        <Typography variant="h2" className={classes.privacyPolicyTextHeader}>
          HOW DO WE USE YOUR PERSONAL INFORMATION?
        </Typography>

        <Typography variant="body1" className={classes.privacyPolicyText}>
          We use the Attendee Information that we collect primarily to help
          maintain accurate event attendance records. Additionally, we use this
          Order Information to:
        </Typography>

        <ul className={styles.list}>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Communicate with you
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Track engagement within the event space
            </Typography>
          </li>
        </ul>

        <Typography
          variant="body1"
          className={`${classes.privacyPolicyText} ${classes.privacyPolicyTextBottom}`}
        >
          We use the Device Information that we collect to help us screen for
          potential risk and fraud (in particular, your IP address), and more
          generally to improve and optimize our Site (for example, by generating
          analytics about how our customers browse and interact with the Site,
          and to assess the success of our marketing and advertising campaigns).
        </Typography>

        <Typography variant="h2" className={classes.privacyPolicyTextHeader}>
          SHARING YOUR PERSONAL INFORMATION
        </Typography>

        <Typography variant="body1" className={classes.privacyPolicyText}>
          We share your Personal Information with third parties to help us use
          your Personal Information, as described above. We also use Google
          Analytics to help us understand how our customers use the Site—you can
          read more about how Google uses your Personal Information here:
          https://www.google.com/intl/en/policies/privacy/. You can also opt-out
          of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.privacyPolicyText} ${classes.privacyPolicyTextBottom}`}
        >
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful request for information we receive, or to
          otherwise protect our rights.
        </Typography>

        <Typography variant="h2" className={classes.privacyPolicyTextHeader}>
          DO NOT TRACK
        </Typography>

        <Typography variant="body1" className={classes.privacyPolicyText}>
          Please note that we do not alter our Site’s data collection and use
          practices when we see a Do Not Track signal from your browser.
        </Typography>

        <Typography variant="body1" className={classes.privacyPolicyText}>
          If you are a European resident, you have the right to access personal
          information we hold about you and to ask that your personal
          information be corrected, updated, or deleted. If you would like to
          exercise this right, please contact us through the contact information
          below.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.privacyPolicyText} ${classes.privacyPolicyTextBottom}`}
        >
          Additionally, if you are a European resident we note that we are
          processing your information in order to fulfill contracts we might
          have with you (for example attend an event), or otherwise to pursue
          our legitimate business interests listed above. Additionally, please
          note that your information will be transferred outside of Europe,
          including to Canada and the United States.
        </Typography>

        <Typography variant="h2" className={classes.privacyPolicyTextHeader}>
          DATA RETENTION
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.privacyPolicyText} ${classes.privacyPolicyTextBottom}`}
        >
          When you use the Site, we will maintain your Attendee Information for
          our records unless and until you ask us to delete this information.
        </Typography>

        <Typography variant="h2" className={classes.privacyPolicyTextHeader}>
          MINORS
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.privacyPolicyText} ${classes.privacyPolicyTextBottom}`}
        >
          The Site is not intended for individuals under the age of 18.
        </Typography>

        <Typography variant="h2" className={classes.privacyPolicyTextHeader}>
          CHANGES
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.privacyPolicyText} ${classes.privacyPolicyTextBottom}`}
        >
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </Typography>

        <Typography variant="h2" className={classes.privacyPolicyTextHeader}>
          CONTACT US
        </Typography>

        <Typography variant="body1" className={classes.privacyPolicyText}>
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at support@showboat.live or by mail using the details
          provided below:
        </Typography>

        <Typography variant="body1" className={classes.privacyPolicyText}>
          1207 W Canal Street, Milwaukee, WI, 53233, United States
        </Typography>

        <Typography variant="body1" className={classes.privacyPolicyText}>
          Last Modified: October 14, 2021
        </Typography>

        <Typography variant="body1" className={classes.privacyPolicyText}>
          <Link
            target="_blank"
            href="https://showboat.live/terms"
            underline="always"
            className={classes.link}
          >
            Terms of Use
          </Link>
          <Link underline="always" className={classes.link} onClick={null}>
            Privacy Policy
          </Link>
        </Typography>

        <Typography variant="body1" className={classes.privacyPolicyText}>
          © 2021 No Small Magic. All Rights Reserved.
        </Typography>
      </div>
    </Paper>
  );
}
