import React, { ReactElement, useEffect } from "react";
import ReactGA from "react-ga";

import MainDisplay2 from "../../components/MainDisplay2/MainDisplay";
import NorthPole from "../../components/NorthPole/NorthPole";
import WideImage from "../../components/WideImage/WideImage";

import styles from "./NorthPolePage.module.css";

interface Props {
  ptp: boolean;
}

export default function NorthPolePage(props): ReactElement {
  useEffect(() => {
    document.title = "Showboat North Pole";

    //Trigger Google Analytics pageview
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div
      className={
        props.ptp
          ? `${styles.mainPageWrapper} ${styles.mainPageWrapperPTP}`
          : styles.mainPageWrapper
      }
    >
      <WideImage northPole={true} />

      <NorthPole ptp={props.ptp} />
    </div>
  );
}
