import { Link, Paper, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

import styles from './TourFooter.module.css';
import { TourFooterStyles } from './TourFooterStyles';

interface Props {
  
}

export default function TourFooter(props: Props): ReactElement {
  
  const classes = TourFooterStyles();
  
  return (
    <Paper square className={classes.tourFooterHolder}>
      <div className={styles.footerContentHolder}>
        <div className={styles.showboatLogoHolder}>
          <img
            className={styles.logo}
            src="assets/images/sb-logo.png"
            alt="showboat logo"
          />
          <Typography variant="h2" className={classes.showboatHeader}>
            Showboat
          </Typography>
        </div>

        <div className={styles.contactHolder}>
          <Typography variant="h2" className={classes.contactHeader}>
            Contact
          </Typography>

          <div className={styles.emailHolder}>
            <Typography variant="body1" className={classes.emailHeader}>
              Email:
            </Typography>

            <Link
              className={classes.emailLink}
              href="mailto:sales@showboat.live"
            >
              sales@showboat.live
            </Link>
          </div>

          <div className={styles.phoneHolder}>
            <Typography variant="body1" className={classes.phoneHeader}>
              Office:
            </Typography>

            <Typography variant="body1" className={classes.phoneNumber}>
              (414) 279-8405
            </Typography>
          </div>

          <div className={styles.socialIconsHolder}>
            <Link
              className={classes.iconLink}
              target="_blank"
              href="https://www.instagram.com/showboatvirtual/"
            >
              <InstagramIcon className={classes.instagramIcon} />
            </Link>

            <Link
              className={classes.iconLink}
              target="_blank"
              href="https://www.linkedin.com/showcase/showboat/"
            >
              <LinkedInIcon className={classes.linkedInIcon} />
            </Link>

            <a
              target="_blank"
              href="https://twitter.com/showboatvirtual"
              className={styles.iconLink}
            >
              <div className={styles.twitterIconHolder}>
                <TwitterIcon className={classes.twitterIcon} />
              </div>
            </a>
          </div>
        </div>
      </div>
    </Paper>
  );
}
