import { makeStyles, createStyles } from '@material-ui/core/styles';

const PresentationsStyles = makeStyles((theme: any) => createStyles({
  gridHeader: {
    color: "#00B6FF",
    fontFamily: "Open Sans, sans-serif",
    position: "relative",
    fontSize: "2.25rem",
    fontWeight: 400,
    ['@media(max-width: 850px)']: {
      fontSize: "1.8rem"
    },
    ['@media(max-width: 500px)']: {
      fontSize: "1.7rem"
    }
  },
  gridHeaderPresentations: {
    top: 1595,
    ['@media(max-width: 1080px)']: {
      top: 1135
    }
  },
  divider: {
    color: "white",
    backgroundColor: "white",
    opacity: 1,
    height: 1,
    position: "relative",
  },
  presentationsDivider: {
    top: 1620,
    ['@media(max-width: 1080px)']: {
      top: 1160
    },
    ['@media screen and (max-width: 850px) and (-ms-high-contrast: none), (-ms-high-contrast: active)']: {
      top: 1161
    },
    ['@media screen and (max-width: 500px) and (-ms-high-contrast: none), (-ms-high-contrast: active)']: {
      top: 1162,
    }
  },
  presentationsSubheader: {
    color: "#FFB700",
    position: "relative",
    top: 0,
    left: 0,
    fontSize: "1.5rem",
    ['@media(max-width: 850px)']: {
      fontSize: "1.3rem"
    }
  },
  presentationsListHeader: {
    fontSize: "1.25rem",
    color: "#EEAA00",
    position: "relative",
    top: 40,
    ['@media(max-width: 500px)']: {
      fontSize: "1rem"
    }
  },
  presentationsText: {
    color: "white",
    position:"relative",
    fontSize: "1rem",
    top: 50,
    width: 500,
    ['@media(max-width: 1080px)']: {
      width: 400,
    },
    ['@media(max-width: 850px)']: {
      width: 350,
    },
    ['@media(max-width: 675px)']: {
      width: 480,
    },
    ['@media(max-width: 500px)']: {
      width: 350
    },
    ['@media(max-width: 375px)']: {
      width: 295
    }
  }
})
)

export { PresentationsStyles };