import { Divider, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

import styles from './Presentations.module.css';
import { PresentationsStyles } from './PresentationsStyles';

interface Props {
  
}

export default function Presentations(props: Props): ReactElement {
  
  const classes = PresentationsStyles();
  
  return (
    <React.Fragment>

      <Typography variant="h2" className={`${classes.gridHeader} ${classes.gridHeaderPresentations}`}>
        Presentations
      </Typography>

      <Divider className={`${classes.divider} ${classes.presentationsDivider}`}></Divider>
      
      <div className={styles.presentationsGrid}>

        <div className={styles.presentationsGridItem}>
          <Typography variant="h3" className={classes.presentationsSubheader}>
            Presentations
          </Typography>

          <img className={`image ${styles.presentationsImage}`} alt="Presentation edit tab" src="assets/images/presentations-1.png" />

          <Typography variant="h4" className={classes.presentationsListHeader}>
            Uploading and Adjusting
          </Typography>

          <Typography variant="body1" className={classes.presentationsText}>
            These are the slide presentations that are available to be assigned to a presenter
          </Typography>

          <ul className={`list ${styles.presentationsList}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                Add
              </Typography>
            </li>

            <ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Click the "+Add" button to assign a slide show name and click "Save"
                </Typography>
              </li>

            </ul>

            <li className={`listItem`}>
              <Typography variant="body1">
                Edit
              </Typography>
            </li>

            <ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Name/Rename Presentation
                </Typography>
              </li>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Delete Selected
                </Typography>
              </li>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Delete All Slides
                </Typography>
              </li>
              
              <li className={`listItem`}>
                <Typography variant="body1">
                  Reorder Slides
                </Typography>
              </li>

            </ul>

            <li className={`listItem`}>
              <Typography variant="body1">
                Upload (How To)
              </Typography>
            </li>

            <ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Drag and drop
                </Typography>
              </li>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Click to browse computer
                </Typography>
              </li>

            </ul>

            <li className={`listItem`} style={{ color: "#FF0092"}}>
              <Typography variant="body1">
                Images must be 960px x 540px and .png format
              </Typography>
            </li>

            <li className={`listItem`}>
              <Typography variant="body1">
                Delete
              </Typography>
            </li>

            <ul>

              <li className={`listItem ${styles.listItemBottom}`}>
                <Typography variant="body1">
                  To delete an entire presentation click the trash can icon
                </Typography>
              </li>

            </ul>

          </ul>

          
        </div>

      </div>
    </React.Fragment>
  )
}
