import { Divider, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

import styles from './EmailBranding.module.css';
import { EmailBrandingStyles } from './EmailBrandingStyles';

interface Props {
  
}

export default function EmailBranding(props: Props): ReactElement {

  const classes = EmailBrandingStyles();
  
  return (
    <React.Fragment>

      <Typography variant="h2" className={`${classes.gridHeader} ${classes.emailBrandingHeader}`}>
        Email Branding
      </Typography>

      <Divider className={`${classes.divider} ${classes.emailBrandingDivider}`}></Divider>

      <div className={styles.emailBrandingGrid}>
        
        <div className={styles.emailBrandingGridItem}>

          <Typography variant="h3" className={classes.emailBrandingSubheader}>
            Email (Branding Tab)
          </Typography>

          <img className={`image ${styles.emailBrandingImage}`} alt="Email branding tab" src="assets/images/emailBranding-1.png" />

          <Typography variant="h4" className={classes.emailBrandingListHeader}>
            Changing Your Header Image
          </Typography>

          <Typography variant="body1" className={classes.emaiLBrandingText}>
            Every email that is sent out of the Showboat email system includes a configurable
            header image. The default header image will have Showboat branding. Make sure to update to match the look
            of your brand or event.
          </Typography>
          
        </div>

        <div className={styles.emailBrandingGridItem}>

          <Typography variant="h3" className={classes.emailBrandingSubheader}>
            Email Header
          </Typography>

          <img className={`image ${styles.emailBrandingImage}`} alt="Email header image upload" src="assets/images/emailBranding-2.png" />


          <ul className={`list ${styles.emailBrandingList}`} >

            <li className={`listItem`}>
              <Typography variant="body1" style={{ color: "#FF0092" }}>
                Must be 600px by 160px .png file
              </Typography>
            </li>

          </ul>
        </div>

      </div>
    </React.Fragment>
  )
}
