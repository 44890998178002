import { makeStyles, createStyles } from '@material-ui/core/styles';

const TourDisplayStyles = makeStyles((theme: any) => createStyles({
  mainDisplayHolder: {
    background: "linear-gradient(180deg, black 50%, transparent 50%)",
    borderTop: "5px solid #FF0190",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    width: 1166,
    minHeight: 1400,
    height: "auto",
    textAlign: "left",
    marginBottom: -70,
    [theme.breakpoints.down('md')]: {
      width: 920,
      marginBottom: -100,
    },
    [theme.breakpoints.down('sm')]: {
      width: 720,
      marginTop: "3%",
      marginBottom: -120,
    },
    [theme.breakpoints.down('xs')]: {
      width: 600,
      marginTop: "4%",
      minHeight: 1300,
      marginBottom: -100,
    },
    '@media (max-width: 614.95px)': {
      width: "100%",
      borderRadius: 0,
      marginTop: "12%",
      marginBottom: -100,
    },
    '@media (max-width: 425px)': {
      marginTop: "18%",
    },
    '@media (max-width: 374.95px)': {
      marginTop: "20%",
      marginBottom: -120,
    },
    '@media (max-width: 349.95px)': {
      marginBottom: -140,
    }
  },
  mainHeader: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: "2.5rem",
    position: "relative",
    top: 73,
    display: "inline-block",
    width: 648,
    marginBottom: 155,
    [theme.breakpoints.down('sm')]: {
      fontSize: "2rem",
      width: 500,
      marginBottom: 130,
    },
    [theme.breakpoints.down('xs')]: {
      top: 50,
      marginBottom: 100,
    },
    '@media (max-width: 614.95px)': {
      fontSize: "2rem",
      width: "100%"
    },
    '@media (max-width: 425px)': {
      width: "100%",
      fontSize: "1.75rem"
    },
  },
  gridHeader: {
    fontSize: "1.5rem",
    position: "relative",
    color: "#FFB700",
    fontWeight: 400,
    marginBottom: 15,
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.25rem"
    }
  },
  gridText: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.8,
    [theme.breakpoints.down('sm')]: {
      fontSize: ".9rem"
    },
    '@media (max-width: 614.95px)': {
      marginBottom: 35,
    }
  },
  gridTwoHeader: {
    fontSize: "2.25rem",
    fontFamily: "Open Sans, sans-seif",
    position: "relative",
    color: "#FFB700",
    marginBottom: 25,
    top: 25,
    [theme.breakpoints.down('md')]: {
      fontSize: "2rem"
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.75rem"
    },
    '@media (max-width: 614.95px)': {

    }
  },
  gridTwoText: {
    lineHeight: 1.5,
    position: "relative",
    top: 25,
    fontSize: "1.25rem",
    display: "inline-block",
    width: "480",
    [theme.breakpoints.down('md')]: {
      marginBottom: 70,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "1rem",
      marginBottom: 50,
    },
    '@media (max-width: 614.95px)': {
      marginBottom: 80,
    }
  },
  gridThreeHeader: {
    fontSize: "1.5rem",
    fontFamily: "Open Sans, sans-serif",
    display: "inline-block",
    color: "#FFB700",
    position: "relative",
    top: 40,
    marginBottom: 70,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.25rem",

    }
  },
  gridThreeRightHeader: {
    fontSize: "1.5rem",
    fontFamily: "Open Sans, sans-serif",
    display: "inline-block",
    color: "#FFB700",
    position: "relative",
    top: 40,
    marginBottom: 65,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      marginBottom: 60,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 55,
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 52,
    }
  },
  gridThreeSubHeader: {
    fontSize: "2.25rem",
    position: "relative",
    fontFamily: "Open Sans, sans-serif",
    display: "inline-block",
    textAlign: "left",
    [theme.breakpoints.down('md')]: {
      fontSize: "1.75rem",
      width: 510
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.5rem",
      width: 450,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: "1.3rem",
      display: "block",
      marginBottom: 10,
      width: 325,
    },
    '@media (max-width: 614.95px)': {
    },
    '@media (max-width: 374.95px)': {
      width: 290,
    }
  },
  gridThreeSubheaderBottom: {
    ['@media (max-width: 614.95px)']: {
      marginBottom: 50,
    }
  },
  gridThreeSubheaderRight: {
    marginBottom: 15,
  },
  showboatLogoHeaderText: {
    fontSize: 40,
    color: "#FFB700",
    position: "absolute",
    left: 80,
    bottom: -45,
    fontFamily: "Open Sans, sans-serif"
  },
  tourHeader: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: "2.5rem",
    color: "white",
    display: "inline-block",
    width: 700,
    position: "relative",
    top: 163,
    [theme.breakpoints.down('sm')]: {
      fontSize: "2.25rem",
      width: 600,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: "1.8rem",
      width: 500,
    },
    ['@media (max-width: 614.95px)']: {
      width: 400,
    },
    ['@media (max-width: 480px)']: {
      fontSize: "1.7rem",
      width: 380,
    },
    ['@media (max-width: 425px)']: {
      width: 330,
    },
    ['@media (max-width: 374.95px)']: {
      width: 300,
    }
  },
  tourButton: {
    position: "relative",
    display: "block",
    padding: 0,
    width: 324,
    height: 86,
    top: 230,
    fontSize: 30,
    textTransform: "none",
    left: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down('xs')]: {
      width: 275,
      height: 75,
    },
    ['@media (max-width: 614.95px)']: {
      fontSize: 26,
      width: 240,
      height: 65,
    }
  },
})
)

export { TourDisplayStyles };