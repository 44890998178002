import { makeStyles, createStyles } from '@material-ui/core/styles';

const NavStyles = makeStyles((theme: any) =>
  createStyles({
    nav: {
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      height: 80,
      borderRadius: 0,
      [theme.breakpoints.down("xs")]: {},
    },
    requestTourButton: {
      position: "absolute",
      right: 10,
      top: 15,
      textTransform: "none",
      display: "inline-block",
      width: 196,
      height: 48,
      fontSize: 20,
      fontWeight: "bold",
      zIndex: 9999,
      [theme.breakpoints.down("xs")]: {
        width: 150,
        height: 45,
        fontSize: 16,
        top: 17,
      },
      "@media (max-width: 349.95px)": {
        width: 130,
        height: 45,
        fontSize: 13,
      },
    },
    showboatHeader: {
      fontFamily: "Open Sans, sans-serif",
      fontSize: 30,
      color: "#FFB700",
      fontStyle: "none !important",
      display: "inline-block",
      position: "absolute",
      left: 65,
      top: 23,
      [theme.breakpoints.down("xs")]: {
        fontSize: 25,
        left: 55,
        top: 25
      },
      "@media (max-width: 349.95px)": {
        fontSize: 22,
        top: 26,
      },
    },
    showboatHeaderPresenter: {
      left: 35,
      [theme.breakpoints.down("md")]: {
        left: 130,
      },
      [theme.breakpoints.down("sm")]: {
        left: 110,
      },
      [theme.breakpoints.down("xs")]: {
        left: 40,
      },
      "@media (max-width: 500px)": {
        position: "relative",
        left: -18,
        top: -27,
      },
    },
    headerNorthPole: {
      ["@media(max-width: 425px)"]: {
        display: "none"
      }
    },
    showboatHeaderProducerPortal: {
      left: 35,
      ["@media(max-width: 1080.95px)"]: {
        left: 60,
      },
      ["@media(max-width: 850px)"]: {
        left: 55,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 30,
        left: 55,
      },
      "@media (max-width: 500px)": {
        fontSize: 22,
        top: 26,
        position: "absolute",
        left: 48,
      },
    },
    toursText: {
      color: "#888888",
      fontSize: 16,
      position: "absolute",
      right: 245,
      top: 29,
      fontWeight: 500,
      [theme.breakpoints.down("xs")]: {
        display: "inline-block",
        width: 120,
        top: 17,
        right: 190,
      },
      "@media (max-width: 614.95px)": {
        display: "none !important",
      },
    },
    arrowRightIcon: {
      position: "absolute",
      color: "#FFB700",
      fontSize: 40,
      right: 210,
      top: 20,
      [theme.breakpoints.down("xs")]: {
        right: 160,
      },
      "@media (max-width: 614.95px)": {
        display: "none",
      },
    },
    homeButton: {
      color: "#00B6FF",
      zIndex: 9999,
      position: "absolute",
      right: 65,
      top: 18,
      padding: 5,
      "@media (max-width: 614.95px)": {
        right: 10,
      },
    },
    homeButtonNorthPole: {
      right: 245,
      [theme.breakpoints.down("xs")]: {
        right: 200,
      },
      ["@media (max-width: 480px)"]: {
        right: 180,
      },
    },
    homeIcon: {
      fontSize: 35,
    },
  })
);

export { NavStyles }