import { Divider, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

import styles from './InRoomAssets.module.css';
import { InRoomAssetsStyles } from './InRoomAssetsStyles';

interface Props {
  
}

export default function InRoomAssets(props: Props): ReactElement {

  const classes = InRoomAssetsStyles();
  
  return (
    <React.Fragment>
      <Typography variant="h2" className={`${classes.gridHeader}`}>
        In-Room
      </Typography>

      <Divider className={`${classes.divider}`}></Divider>

      <div className={styles.inRoomGrid}>

        <div className={styles.inRoomGridItem}>
          <Typography variant="h3" className={classes.inRoomSubHeader}>
            Avatar Colors
          </Typography>

          <img className={styles.inRoomImage} alt="Avatar color selection" src="assets/images/inRoom-1.png" />

          <div className={styles.inRoomContentHolder}>
            <ul className={styles.inRoomList}>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.inRoomListText}>
                  Choose up to 8 Avatar color combinations
                </Typography>
              </li>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.inRoomListText}>
                  Different color for body and face
                </Typography>
              </li>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.inRoomListText}>
                  Color picker or hex value
                </Typography>
              </li>
            </ul>

          </div>
        </div>

        <div className={styles.inRoomGridItem}>
          <Typography variant="h3" className={classes.inRoomSubHeader}>
            Brand Display (1-6)
          </Typography>

          <img className={styles.inRoomImage} alt="In room brand displays" src="assets/images/inRoom-2.png" />

          <div className={styles.inRoomContentHolder}>
            <ul className={styles.inRoomList}>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.inRoomListText}>
                  Image to display on the 6 brand monitors scattered throughout the room
                </Typography>
              </li>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.inRoomListText}>
                  Format
                </Typography>
              </li>
              <ul>
                <li className={`listItem`}>
                  <Typography variant="body1" className={classes.inRoomListText}>
                    .PNG
                  </Typography>
                </li>
                <li className={`listItem`}>
                  <Typography variant="body1" className={classes.inRoomListText}>
                    960px x 540px
                  </Typography>
                </li>
              </ul>
              
            </ul>

          </div>

        </div>

        <div className={styles.inRoomGridItem}>
          <Typography variant="h3" className={classes.inRoomSubHeader}>
            Welcome Image
          </Typography>

          <img className={styles.inRoomImage} alt="In room welcome image" src="assets/images/inRoom-3.png" />

          <div className={styles.inRoomContentHolder}>
            <ul className={styles.inRoomList}>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.inRoomListText}>
                  Banner that hangs above the entrance to the main room
                </Typography>
              </li>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.inRoomListText}>
                  Format
                </Typography>
              </li>
              <ul>
                <li className={`listItem`}>
                  <Typography variant="body1" className={classes.inRoomListText}>
                    .PNG
                  </Typography>
                </li>
                <li className={`listItem`}>
                  <Typography variant="body1" className={classes.inRoomListText}>
                    960px x 540px
                  </Typography>
                </li>
              </ul>
              
            </ul>

          </div>

        </div>

        <div className={styles.inRoomGridItem}>
          <Typography variant="h3" className={classes.inRoomSubHeader}>
            Welcome Display
          </Typography>

          <img className={styles.inRoomImage} alt="In room welcome display" src="assets/images/inRoom-4.png" />
          
          <div className={styles.inRoomContentHolder}>
            <ul className={styles.inRoomList}>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.inRoomListText}>
                  Free standing display located in the lobby
                </Typography>
              </li>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.inRoomListText}>
                  Format
                </Typography>
              </li>
              <ul>
                <li className={`listItem`}>
                  <Typography variant="body1" className={classes.inRoomListText}>
                    .PNG
                  </Typography>
                </li>
                <li className={`listItem`}>
                  <Typography variant="body1" className={classes.inRoomListText}>
                    960px x 540px
                  </Typography>
                </li>
              </ul>
              
            </ul>

          </div>

        </div>
        
      </div>


    </React.Fragment>
  )
}
