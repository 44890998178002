import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from "react-ga";
import axios from "axios";

import "./index.css";
import App from "./App";

if (process.env.REACT_APP_API_STAGE === "dev") {
  axios.defaults.baseURL =
    "https://8887jyvpp4.execute-api.us-east-1.amazonaws.com/dev";
} else {
  axios.defaults.baseURL = "https://8887jyvpp4.execute-api.us-east-1.amazonaws.com/prod"
}

//Initialize Google Analytics
ReactGA.initialize("UA-183143817-2");

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

