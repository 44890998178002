import { makeStyles, createStyles } from '@material-ui/core/styles';

const MainFooterStyles = makeStyles((theme: any) =>
  createStyles({
    mainFooterHolder: {
      backgroundColor: "#1A1A1A",
      width: "100vw",
      height: 375,
      position: "relative",
      zIndex: 1,
      textAlign: "center",
      [theme.breakpoints.down("md")]: {},
      [theme.breakpoints.down("sm")]: {
        height: 325,
      },
      ["@media (max-width: 614.95px)"]: {
        top: 220,
        height: 400,
      },
      ["@media (max-width: 425px)"]: {
        top: 600,
      },
      ["@media (max-width: 374.95px)"]: {
        top: 155,
      },
    },
    contactHeader: {
      fontSize: "1.5rem",
      fontFamily: "Open Sans, sans-serif",
      display: "inline-block",
      color: "#FFB700",
      position: "relative",
      fontWeight: 400,
    },
    emailHeader: {
      fontSize: "1rem",
      color: "white",
    },
    emailLink: {
      color: "white",
      fontSize: "1rem",
      position: "relative",
      display: "block",
      "@media (max-width: 959.95px)": {
        right: 20,
      },
      "@media (max-width: 739.95px)": {
        right: 35,
      },
      "&:hover": {
        cursor: "pointer",
      },
    },
    phoneHeader: {
      fontSize: "1rem",
      color: "white",
    },
    phoneNumber: {
      fontSize: "1rem",
      color: "white",
      position: "relative",
      right: 15,
      "@media (max-width: 959.95px)": {
        right: 35,
      },
      "@media (max-width: 739.95px)": {
        right: 50,
      }
    },
    twitterIcon: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
      margin: "auto",
      color: "#1A1A1A",
      zIndex: 10,
      fontSize: 27,
    },
    linkedInIcon: {
      fontSize: 47,
      zIndex: 10,
      marginRight: 5,
      "&:hover": {
        cursor: "pointer",
      },
    },
    instagramIcon: {
      fontSize: 42,
      zIndex: 10,
      position: "relative",
      top: 3,
      "&:hover": {
        cursor: "pointer",
      },
    },
    iconLink: {
      color: "white",
    },
    tourLink: {
      color: "white",
      fontSize: "1rem",
      position: "relative",
      top: 47,
      left: 2,
      "&:hover": {
        cursor: "pointer",
      },
    },
    showboatHeader: {
      fontSize: 40,
      position: "relative",
      bottom: 5,
      left: 80,
      top: -47,
      color: "#FFB700",
      fontWeight: 300,
      zIndex: 2,
      "@media (max-width: 614.95px)": {
        left: 85,
      },
      "@media (max-width: 425px)": {
        fontSize: 36,
        top: -35,
        left: 75,
      },
    },
  })
);

export { MainFooterStyles };