import { makeStyles, createStyles } from '@material-ui/core/styles';

const BrandingInRoomStyles = makeStyles((theme: any) => createStyles({
  gridHeader: {
    color: "#00B6FF",
    fontFamily: "Open Sans, sans-serif",
    position: "relative",
    fontSize: "2.25rem",
    fontWeight: 400,
    '@media(max-width: 850px)': {
      fontSize: "1.8rem"
    },
    '@media(max-width: 500px)': {
      fontSize: "1.7rem"
    }
  },
  brandingInRoomGridHeader: {
    top: 1100,
    '@media(max-width: 1080px)': {
      top: 870
    }
  },
  divider: {
    color: "white",
    backgroundColor: "white",
    opacity: 1,
    height: 1,
    position: "relative",
  },
  brandingInRoomDivider: {
    top: 1125,
    '@media(max-width: 1080px)': {
      top: 895
    }
  },
  brandingInRoomSubheader: {
    color: "#FFB700",
    position: "relative",
    top: 0,
    left: 0,
    fontSize: "1.5rem",
    '@media(max-width: 850px)': {
      fontSize: "1.3rem"
    }
  },
  brandingInRoomListHeader: {
    fontSize: "1.25rem",
    color: "#EEAA00",
    position: "relative",
    top: 20,
    '@media(max-width: 500px)': {
      fontSize: "1rem"
    }
  },
  brandingInRoomListHeaderTwo: {
    top: 40,
  },
  brandingInRoomText: {
    fontSize: "1rem",
    color: "white",
    position: "relative",
    top: 35,
    display: "inline-block",
    width: "95%",
    '@media(max-width: 675px)': {
      width: "100%"
    }
  },
  brandingInRoomTextPreview: {
    top: 55,
  },
  brandingInRoomListHeaderWelcome: {
    top: 67,
  }
 
})
)

export { BrandingInRoomStyles };