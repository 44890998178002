import { makeStyles, createStyles } from '@material-ui/core/styles';

const PresentersStyles = makeStyles((theme: any) => createStyles({
  gridHeader: {
    color: "#00B6FF",
    fontFamily: "Open Sans, sans-serif",
    position: "relative",
    fontSize: "2.25rem",
    fontWeight: 400,
    ['@media(max-width: 850px)']: {
      fontSize: "1.8rem"
    },
    ['@media(max-width: 500px)']: {
      fontSize: "1.7rem"
    }
  },
  divider: {
    color: "white",
    backgroundColor: "white",
    opacity: 1,
    height: 1,
    position: "relative",
  },
  presentersDivider: {
    top: 426,
    ['@media(max-width: 500px)']: {
      top: 480
    }
  },
  presentersHeader: {
    top: 440,
  },
  presenterToolsButton: {
    position: "relative",
    display: "inline-block",
    width: 271,
    height: 42,
    top: 400,
    left: 725,
    ['@media (max-width: 1080px)']: {
      left: 530
    },
    ['@media(max-width: 850px)']: {
      left: 380
    },
    ['@media(max-width: 675px)']: {
      left: 205,
    },
    ['@media(max-width: 500px)']: {
      left: 0,
      top: 460
    }
  },
  linkIcon: {
    position: "absolute",
    left: -20,
  },
  presenterToolsButtonLabel: {
    position: "relative",
    left: 15,
  },
  presentersSubheader: {
    color: "#FFB700",
    position: "relative",
    top: 0,
    left: 0,
    fontSize: "1.5rem",
    ['@media(max-width: 850px)']: {
      fontSize: "1.3rem"
    }
  },
  presentersListHeader: {
    fontSize: "1.25rem",
    color: "#EEAA00",
    position: "relative",
    top: 40,
    ['@media(max-width: 500px)']: {
      fontSize: "1rem"
    }
  },
  presentersText: {
    color: "white",
    position:"relative",
    fontSize: "1rem",
    top: 50,
    width: 500,
    ['@media (max-width: 1080px)']: {
      width: 395
    },
    ['@media(max-width: 850px)']: {
      width: 320,
    },
    ['@media(max-width: 675px)']: {
      width: 480,
    },
    ['@media(max-width: 500px)']: {
      width: 290,
    }
  },
  addIcon: {
    color: "#EEAA00",
    display: "inline",
    position: "relative",
    top: 5,
    marginRight: 5
  }
})
)

export { PresentersStyles };