import { makeStyles, createStyles } from '@material-ui/core/styles';

const EmailBrandingStyles = makeStyles((theme: any) => createStyles({
  gridHeader: {
    color: "#00B6FF",
    fontFamily: "Open Sans, sans-serif",
    position: "relative",
    fontSize: "2.25rem",
    fontWeight: 400,
    '@media(max-width: 850px)': {
      fontSize: "1.8rem"
    },
    '@media(max-width: 500px)': {
      fontSize: "1.7rem"
    }
  },
  emailBrandingHeader: {
    top: 1530,
    '@media(max-width: 1080px)': {
      top: 1030
    }
  },
  divider: {
    color: "white",
    backgroundColor: "white",
    opacity: 1,
    height: 1,
    position: "relative",
  },
  emailBrandingDivider: {
    top: 1560,
    '@media(max-width: 1080px)': {
      top: 1060
    },
    '@media screen and (max-width: 850px) and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      top: 1061,
    },
    '@media screen and (max-width: 675px) and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      top: 1062
    }

  },
  emailBrandingSubheader: {
    color: "#FFB700",
    position: "relative",
    top: 0,
    left: 0,
    fontSize: "1.5rem",
    '@media(max-width: 850px)': {
      fontSize: "1.3rem"
    }
    
  },
  emailBrandingListHeader: {
    fontSize: "1.25rem",
    position: "relative",
    color: "#FFB700",
    fontWeight: 400,
    top: 35,
    '@media(max-width: 500px)': {
      fontSize: "1rem"
    }
  },
  emaiLBrandingText: {
    color: "white",
    position:"relative",
    fontSize: "1rem",
    top: 45,
    '@media(max-width: 1080px)': {
      width: 380
    },
    '@media(max-width: 850px)': {
      width: 330
    },
    '@media(max-width: 675px)': {
      width: 480,
    },
    '@media(max-width: 500px)': {
      width: 360
    },
    '@media(max-width: 375px)': {
      width: 290
    }

  }
    
})
)

export { EmailBrandingStyles };