import { makeStyles, createStyles } from '@material-ui/core/styles';

const EmailStyles = makeStyles((theme: any) => createStyles({
  gridHeader: {
    color: "#00B6FF",
    fontFamily: "Open Sans, sans-serif",
    position: "relative",
    fontSize: "2.25rem",
    fontWeight: 400,
    '@media(max-width: 850px)': {
      fontSize: "1.8rem"
    },
    '@media(max-width: 500px)': {
      fontSize: "1.7rem"
    }
  },
  gridHeaderEmail: {
    top: 520,
    '@media(max-width: 1080px)': {
      top: 490
    }
  },
  divider: {
    color: "white",
    backgroundColor: "white",
    opacity: 1,
    height: 1,
    position: "relative",
  },
  emailDivider: {
    top: 542,
    '@media(max-width: 1080px)': {
      top: 512
    }
  },
  emailSubheader: {
    color: "#FFB700",
    position: "relative",
    top: 0,
    left: 0,
    fontSize: "1.5rem",
    '@media(max-width: 850px)': {
      fontSize: "1.3rem"
    }
  },
  emailListHeader: {
    fontSize: "1.25rem",
    color: "#EEAA00",
    position: "relative",
    top: 40,
    '@media(max-width: 500px)': {
      fontSize: "1rem"
    }
  },
  emailText: {
    color: "white",
    position:"relative",
    fontSize: "1rem",
    top: 50,
    width: 500,
    '@media(max-width: 1080px)': {
      width: 400
    },
    '@media(max-width: 850px)': {
      width: 320
    },
    '@media(max-width: 675px)': {
      width: 480
    },
    '@media(max-width: 500px)': {
      width: 340,
    },
    '@media(max-width: 375px)': {
      width: 295,
    }
  },
  emailTextSendEmail: {
    top: 30
  },
  addIcon: {
    color: "#EEAA00",
    display: "inline",
    position: "relative",
    top: 5,
    marginRight: 5
  }
})
)

export { EmailStyles };