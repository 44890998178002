import React, { ReactElement } from 'react';

import { BrandingInRoomStyles } from './BrandingInRoomStyles';
import styles from './BrandingInRoom.module.css';
import { Divider, Typography } from '@material-ui/core';

interface Props {
  
}

export default function BrandingInRoom(props: Props): ReactElement {

  const classes = BrandingInRoomStyles();
  
  return (
    <React.Fragment>
      
      <Typography variant="h2"className={`${classes.gridHeader} ${classes.brandingInRoomGridHeader}`}>
        Branding: In-Room
      </Typography>

      <Divider className={`${classes.divider} ${classes.brandingInRoomDivider}`}></Divider>

      <div className={styles.brandingInRoomGrid}>

        <div className={styles.brandingInRoomGridItem}>
          <img className="image" alt="Branding > In-Room tab" src="assets/images/brandingInRoom-1.png" />

          <Typography variant="h4" className={`${classes.brandingInRoomListHeader}`}>
            In-Room Section
          </Typography>

          <Typography variant="body1" className={classes.brandingInRoomText}>
            Branding allows you to customize the screens in the event room with your branding
          </Typography>
        </div>

        <div className={styles.brandingInRoomGridItem}>

          <img className="image" alt="Avatar colors preview" src="assets/images/brandingInRoom-2.png" />

          <Typography variant="h4" className={`${classes.brandingInRoomListHeader}`}>
            Preview Avatar Colors
          </Typography>

          <ul className={`list ${styles.brandingInRoomList}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                Preview your avatar colors by clicking the preview button
              </Typography>
            </li>

            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1">
                You can choose up to 8 avatar color combinations (don't have to use them all)
              </Typography>
            </li>

          </ul>

        </div>

        <div className={styles.brandingInRoomGridItem}>
          
          <img className="image" alt="Avatar colors edit popup" src="assets/images/brandingInRoom-3.png" />

          <ul className={`list ${styles.brandingInRoomListManage}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                Click the MANAGE button to access color pickers for your 8 options
              </Typography>
            </li>

            <ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Select a color for the body and the face
                </Typography>
              </li>
              
              <li className={`listItem`}>
                <Typography variant="body1">
                  Typically it is a good idea to make the face a lighter color than the body
                </Typography>
              </li>

            </ul>

          </ul>
        </div>

        <div className={`${styles.brandingInRoomGridItem} ${styles.brandingInRoomGridItemImage}`}>

          <img className={styles.avatarImage} alt="Showboat avatar design" src="assets/images/brandingInRoom-4.png" />

        </div>

        <div className={styles.brandingInRoomGridItem}>

          <Typography variant="h3" className={classes.brandingInRoomSubheader}>
            Brand Displays
          </Typography>

          <img className={`image ${styles.brandingInRoomImage}`} alt="Showboat in-room brand displays" src="assets/images/brandingInRoom-5.png" />

          <Typography variant="h4" className={`${classes.brandingInRoomListHeader} ${classes.brandingInRoomListHeaderTwo}`}>
            Brand Displays Layout
          </Typography>

          <ul className={`list ${styles.brandingInRoomListPreview}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                (Assuming that your back is against the "Presentation Display Default")
              </Typography>
            </li>

          </ul>

          <Typography variant="h4" className={`${classes.brandingInRoomListHeader} ${classes.brandingInRoomListHeaderTwo}`}>
            Brand Displays 1-6
          </Typography>

          <Typography variant="body1" className={`${classes.brandingInRoomText} ${classes.brandingInRoomTextPreview}`}>
            These are the screens for branding images that can be displayed in the event room's first and second floor
          </Typography>

          <ul className={`list ${styles.brandingInRoomListPreviewMiddle}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                Preview the image uploaded by clicking the preview button
              </Typography>
            </li>

          </ul>

          <Typography variant="h4" className={`${classes.brandingInRoomListHeader} ${classes.brandingInRoomListHeaderTwo}`}>
            Presentation Display Default
          </Typography>

          <ul className={`list ${styles.brandingInRoomListPreviewMiddle}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                This is the main presentation screen in the center of the event space
              </Typography>
            </li>

            <li className={`listItem`}>
              <Typography variant="body1">
                Preview the image uploaded by clicking the preview button
              </Typography>
            </li>

            <li className={`listItem`}>
              <Typography variant="body1">
                Presentation Display Default can be an image or a video
              </Typography>
            </li>

          </ul>

        </div>

        <div className={`${styles.brandingInRoomGridItem} ${styles.brandingInRoomGridItemWelcomeDisplay}`}>

          <img className={`image ${styles.brandingInRoomWelcomeImage}`} alt="Showboat in-room welcome displays" src="assets/images/brandingInRoom-6.png" />

          <Typography variant="h4" className={`${classes.brandingInRoomListHeader} ${classes.brandingInRoomListHeaderWelcome}`}>
            Welcome Displays & Welcome Image
          </Typography>

          <ul className={`list ${styles.brandingInRoomListWelcome}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                The welcome display is the large display mounted on the floor in the lobby area
              </Typography>
            </li>

            <li className={`listItem`}>
              <Typography variant="body1">
                The welcome image is the screen above the entrance to the main room
              </Typography>
            </li>

            <li className={`listItem`}>
              <Typography variant="body1">
                Preview the image uploaded by clicking the preview button
              </Typography>
            </li>

          </ul>


        </div>

        <div className={styles.brandingInRoomGridItem}>

          <img className="image" alt="Showboat in-room patio displays" src="assets/images/brandingInRoom-7.png" />

          <Typography variant="h4" className={`${classes.brandingInRoomListHeader}`}>
            Patio Displays
          </Typography>

          <ul className={`list ${styles.brandingInRoomList}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                Presentation Display Default syncs with Patio Displays
              </Typography>
            </li>

          </ul>

        </div>

        <div className={styles.brandingInRoomGridItem}>

          <Typography variant="h3" className={classes.brandingInRoomSubheader}>
            Uploading Displays
          </Typography>

          <img className={`image ${styles.brandingInRoomImage}`} alt="Upload graphics popup" src="assets/images/brandingInRoom-8.png" />

          <Typography variant="h4" className={`${classes.brandingInRoomListHeader} ${classes.brandingInRoomListHeaderTwo}`}>
            How to Upload Branding Graphics
          </Typography>

          <ul className={`list ${styles.brandingInRoomListPreview}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                To upload your branding graphics click the "Upload" button and drag and drop your file
              </Typography>
            </li>

            <li className={`listItem`}>
              <Typography variant="body1">
                Click "Save"
              </Typography>
            </li>

            <li className={`listItem`}>
              <Typography variant="body1">
                All images must be 960px by 540px and a .png file
              </Typography>
            </li>

            <li className={`listItem`}>
              <Typography variant="body1">
                Presentation Display Default video must be in .MP4 format and may not exceed 10MB
              </Typography>
            </li>

          </ul>

        </div>
        
        
      </div>
    </React.Fragment>
  )
}
