import { Button, Checkbox, CircularProgress, FormControlLabel, Paper, TextField, Typography } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import axios from "axios";
import ReactGA from "react-ga";

import styles from "./EBookForm.module.css";
import { EBookFormStyles } from './EBookFormStyles';

interface Props {
  
}

export default function EBookForm({}: Props): ReactElement {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [nameRequiredError, setNameRequiredError] = useState(false);
  const [emailRequiredError, setEmailRequiredError] = useState(false);

  const [newsletter, setNewsletter] = useState(true);
  const [demoContact, setDemoContact] = useState(false);

  const [submitLoadingSpinner, setSubmitLoadingSpinner] = useState(false);
  
  const handleNameChange = (e)  => {
    setNameRequiredError(false);

    setName(e.target.value);
  }

  const handleEmailChange = (e) => {
    setEmailRequiredError(false);
    setEmail(e.target.value);
  }

  const downloadEBookPDF = () => {
    const a = document.createElement("a");

    if (window.location.hostname.includes("showboat.live")) {
      a.href = "/eBook/Effective-Virtual-Networking.pdf";
    } else {
      a.href = "/eBook-local/Effective-Virtual-Networking.pdf";
    }

    a.download = "Effective-Virtual-Networking.pdf";

    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);
  }
  
  const handleFormSubmit = async () => {
    let formHasError;

    //Check for required inputs
    if (!name.trim().length) {
      setNameRequiredError(true);
      formHasError = true;
    }

    if (!email.trim().length) {
      setEmailRequiredError(true);
      formHasError = true;
    }

    if (formHasError) return;

    //Show loading spinner
    setSubmitLoadingSpinner(true);

    try {
      let dataObj = {
        name,
        email,
        newsletter,
        demo: demoContact,
      };

      const dataSubmitForm = await axios.post(
        "/ebook-email",
        JSON.stringify(dataObj)
      );

      setSubmitLoadingSpinner(false);

      //Trigger google analytics event
      ReactGA.event({
        category: "Actions",
        action: "Submitted Effective Virtual Networking eBook Form",
      });

      //Scroll to top of page
      document.body.scrollTop = document.documentElement.scrollTop = 0;

      //Download the eBook PDF
      downloadEBookPDF();

      setFormSubmitted(true);
    } catch (error) {
      console.log("ERROR", error);
      setSubmitLoadingSpinner(false);
    }
  }

  const handleNewsletterChange = () => {
    setNewsletter(!newsletter)
  }

  const handleDemoContactChange = () => {
    setDemoContact(!demoContact);
  }
  
  const classes = EBookFormStyles();
  
  return (
    <Paper
      elevation={0}
      className={
        formSubmitted
          ? `${classes.mainDisplayHolder} ${classes.mainDisplayHolderFormSubmitted}`
          : classes.mainDisplayHolder
      }
    >
      <div className={styles.mainTextWrapper}>
        <div className={styles.showboatLogoHeader}>
          <img
            alt="Showboat logo"
            className={styles.showboatLogoHeader}
            src="/assets/images/sb-logo.png"
          />

          <Typography variant="h1" className={classes.showboatLogoHeaderText}>
            Download our eBook
          </Typography>
        </div>

        {formSubmitted ? (
          <div className={styles.formMessageHolder}>
            <Typography className={classes.formMessage}>
              Virtual Networking is about to get a whole lot more fun.
            </Typography>

            <Typography
              variant="h1"
              className={`${classes.showboatLogoHeaderText} ${classes.showboatLogoHeaderTextFormMessage}`}
            >
              See you in Showboat!
            </Typography>
          </div>
        ) : (
          <>
            <div className={styles.formWrapper}>
              <div className={styles.formHolder}>
                <Typography className={classes.formHeader}>
                  About You
                </Typography>

                <TextField
                  variant="outlined"
                  value={name}
                  onChange={handleNameChange}
                  className={classes.tourFormField}
                  label="Name"
                  placeholder="Name"
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                      root: classes.inputRoot,
                      focused: classes.inputFocused,
                    },
                  }}
                  error={nameRequiredError}
                />

                <TextField
                  variant="outlined"
                  value={email}
                  onChange={handleEmailChange}
                  className={classes.tourFormField}
                  name="Email"
                  label="Email"
                  placeholder="Email"
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                  }}
                  error={emailRequiredError}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newsletter}
                      onChange={handleNewsletterChange}
                      name="newsletter"
                      value="newsletter"
                      style={{
                        color: "white",
                      }}
                    />
                  }
                  label="Add me to your newsletter"
                  classes={{
                    label: classes.checkboxLabel,
                    root: classes.checkbox,
                  }}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={demoContact}
                      onChange={handleDemoContactChange}
                      name="request-demo"
                      value="request-demo"
                      style={{
                        color: "white",
                      }}
                    />
                  }
                  label="Contact me to schedule a demo"
                  classes={{
                    label: classes.checkboxLabel,
                    root: classes.checkbox,
                  }}
                />

                <Button
                  className={classes.requestTourButton}
                  variant="contained"
                  color="primary"
                  onClick={handleFormSubmit}
                  disabled={submitLoadingSpinner}
                >
                  Submit & Download
                </Button>

                {submitLoadingSpinner && (
                  <CircularProgress className={classes.loadingSpinner} />
                )}
              </div>
            </div>
          </>
        )}

        <div className={styles.mainGridHolderThree}>
          <div className={styles.leftTextHolder}>
            <Typography variant="h2" className={classes.gridThreeHeader}>
              Easy
            </Typography>
            <div className={styles.leftSubHeaderHolder}>
              <Typography variant="h2" className={classes.gridThreeSubHeader}>
                Showboat runs in a browser.
              </Typography>
              <Typography
                variant="h2"
                className={`${classes.gridThreeSubHeader} ${classes.gridThreeSubheaderBottom}`}
              >
                No software to download or learn.
              </Typography>
            </div>
          </div>
          <div className={styles.rightTextHolder}>
            <Typography variant="h2" className={classes.gridThreeRightHeader}>
              Powerful
            </Typography>

            <div className={styles.rightSubHeaderHolder}>
              <Typography
                variant="h2"
                className={`${classes.gridThreeSubHeader} ${classes.gridThreeSubheaderRight}`}
              >
                Event Chat
              </Typography>
              <Typography
                variant="h2"
                className={`${classes.gridThreeSubHeader} ${classes.gridThreeSubheaderRight}`}
              >
                Direct Messages
              </Typography>
              <Typography
                variant="h2"
                className={`${classes.gridThreeSubHeader} ${classes.gridThreeSubheaderRight}`}
              >
                Teleport
              </Typography>
              <Typography
                variant="h2"
                className={`${classes.gridThreeSubHeader} ${classes.gridThreeSubheaderRight}`}
              >
                Screenshare
              </Typography>
              <Typography
                variant="h2"
                className={`${classes.gridThreeSubHeader} ${classes.gridThreeSubheaderRight}`}
              >
                Slide Presentations
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
}
