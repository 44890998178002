import { makeStyles, createStyles } from '@material-ui/core/styles';

const LoginProjectsStyles = makeStyles((theme: any) => createStyles({
  gridHeader: {
    color: "#00B6FF",
    fontFamily: "Open Sans, sans-serif",
    position: "relative",
    fontSize: "2.25rem",
    fontWeight: 400,
    ['@media(max-width: 850px)']: {
      fontSize: "1.8rem"
    },
    ['@media(max-width: 500px)']: {
      fontSize: "1.7rem"
    }
  },
  loginProjectsHeader: {
    top: 75,
  },
  divider: {
    color: "white",
    backgroundColor: "white",
    opacity: 1,
    height: 1,
    position: "relative",
  },
  loginProjectsDivider: {
    top: 100,
  },
  loginProjectsSubheader: {
    color: "#FFB700",
    position: "relative",
    top: 0,
    left: 0,
    fontSize: "1.5rem",
    ['@media(max-width: 850px)']: {
      fontSize: "1.3rem"
    },
    ['@media(max-width: 500px)']: {
    }
  },
  loginProjectsListHeader: {
    fontSize: "1.25rem",
    position: "relative",
    color: "#FFB700",
    fontWeight: 400,
    top: 45,
    ['@media(max-width: 500px)']: {
      fontSize: "1.1rem",
      top: 35
    }
  },
  loginProjectsListText: {
    color: "white",
    position:"relative",
    fontSize: "1rem"
  },
  loginProjectsText: {
    color: "white",
    position:"relative",
    fontSize: "1rem",
    top: 40,
  },
})
)

export { LoginProjectsStyles };