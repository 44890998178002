import { makeStyles, createStyles } from '@material-ui/core/styles';

const EBookImageHeaderStyles = makeStyles((theme: any) =>
  createStyles({
    ebookHeader: {
      fontFamily: "Open Sans, sans-serif",
      fontSize: 50,
      color: "white",
      marginBottom: 15,
      display: "inline-block",
      "@media (max-width: 1280.95px)": {
        fontSize: 42,
        width: 400,
      },
      "@media (max-width: 959.95px)": {
        fontSize: 39,
      },
      "@media (max-width: 800px)": {
        fontSize: 32,
        width: "100%",
      },
      "@media (max-width: 614.95px)": {
        fontSize: 28,
        width: 360,
      }
    },
    ebookSubHeader: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: 30,
      color: "#3F01A8",
      display: "inline-block",
      "@media (max-width: 959.95px)": {
        fontSize: 26,
      },
      "@media (max-width: 800px)": {
        fontSize: 22,
      }
    },
    iconText: {
      color: "#3F01A8",
    },
  })
);

export { EBookImageHeaderStyles };