import { Divider, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

import "../../../App.css";
import styles from './BookingsOverview.module.css';
import { BookingsOverviewStyles } from './BookingsOverviewStyles';

interface Props {
  
}

export default function BookingsOverview(props: Props): ReactElement {

  const classes = BookingsOverviewStyles();  
  
  return (
    <React.Fragment>
      
      <Typography variant="h2" className={`${classes.gridHeader} ${classes.bookingsOverviewHeader}`}>
        Bookings Overview
      </Typography>
      
      <Divider className={`${classes.divider} ${classes.bookingsOverviewDivider}`}></Divider>
      
      <div className={styles.bookingsOverviewGrid}>
        <div className={styles.bookingsOverviewGridItem}>
          <Typography variant="h3" className={classes.loginProjectsSubheader}>
            Adding a Booking
          </Typography>

          <img className={`image ${styles.bookingsOverviewImage}`} alt="Adding a booking" src="assets/images/bookingsOverview-1.png" />

          <Typography variant="body1" className={classes.bookingsOverviewText}>
            A booking is an event created within a space. Think of bookings as a room scheduler
            however, bookings can overlap (multiple copies of the same room in use at the same time).
          </Typography>

          <ul className={`list ${styles.bookingsOverviewList}`} >

            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                Click "Add and Edit"
              </Typography>
            </li>

            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                Enter booking name and click "Save"
              </Typography>
            </li>

            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                This will bring you to the editing page of your new booking
              </Typography>
            </li>

            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                This name will show up in the landing page and email
              </Typography>
            </li>

          </ul>

          <Typography variant="h4" className={classes.bookingsOverviewSubheader}>
            Edit
          </Typography>

          <ul className={`list ${styles.bookingsOverviewList}`} >
            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                You can also edit any booking already created
              </Typography>
            </li>
          </ul>

        </div>

        <div className={styles.bookingsOverviewGridItem}>
          <Typography variant="h3" className={classes.loginProjectsSubheader}>
            Event Information
          </Typography>

          <img className={`image ${styles.bookingsOverviewImage}`} alt="Booking information overview" src="assets/images/bookingsOverview-2.png" />

          <Typography variant="body1" className={classes.bookingsOverviewText}>
            This section gives you a short overview of your event running times, capacity,
            and guest links for sign-up and intake
          </Typography>

          <ul className={`list ${styles.bookingsOverviewList}`} >

            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                Edit the event name by clicking the pencil
              </Typography>
            </li>

            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                Duplicate your event by clicking the duplicate icon (next to pencil)
              </Typography>
            </li>

            <ul className={styles.loginProjectsSublist}>
              <li className={`listItem ${styles.bookingsOverviewListItem}`}>
                <Typography variant="body1" className={classes.bookingsOverviewListText}>
                  Useful for when your event has multiple time slots
                </Typography>
              </li>
            </ul>

            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                To delete your booking click the trash can
              </Typography>
            </li>

          </ul>
        </div>

        <div className={`${styles.bookingsOverviewGridItem} ${styles.bookingsOverviewGridItemSignUp}`}>
          <Typography variant="h3" className={classes.loginProjectsSubheader}>
            Sign-Up URL
          </Typography>

          <img className={`image ${styles.bookingsOverviewImage}`} alt="Sign up and intake URL" src="assets/images/bookingsOverview-3.png" />

          <Typography variant="h4" className={`${classes.bookingsOverviewSubheader} ${classes.bookingsOverviewSubheaderSignUp}`}>
            Copy and Regenerate
          </Typography>

          <Typography variant="body1" className={classes.bookingsOverviewText}>
            A link to a registration page where future attendees can sign-up and will receive an email confirmation
          </Typography>

          <ul className={`list ${styles.bookingsOverviewList}`}>

            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                <b>Copy button:</b> This will automatically copy the link to your clipboard so that it can
                be pasted into emails, group chats, etc.
              </Typography>
            </li>
            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                <b>Regenerate button:</b> This generates a completely new link for the event
              </Typography>
            </li>

            <ul className={styles.loginProjectsSublist}>
              <li className={`listItem ${styles.bookingsOverviewListItem}`}>
                <Typography variant="body1" className={classes.bookingsOverviewListText}>
                  Useful if original link gets sent out to wrong group or if event information
                  has been changed within event
                </Typography>
              </li>
              <li className={`listItem ${styles.bookingsOverviewListItem}`}>
                <Typography variant="body1" className={classes.bookingsOverviewListText}>
                  If the link is regenerated while the event is happening attendees will get locked
                  out if they refresh their browser
                </Typography>
              </li>
            </ul>

          </ul>
          
          
        </div>

        <div className={styles.bookingsOverviewGridItem}>
          <Typography variant="h3" className={classes.loginProjectsSubheader}>
            Intake URL
          </Typography>

          <Typography variant="h4" className={`${classes.bookingsOverviewSubheader} ${classes.bookingsOverviewSubheaderIntake}`}>
            Copy and Regenerate
          </Typography>

          <Typography variant="body1" className={`${classes.bookingsOverviewText} ${classes.bookingsOverviewTextIntake}`}>
            Entrance link to event. Anyone with this link will be able to attend the event. Registered guests
            do not require this link as they will have their own unique one sent to them upon registration. Events can be
            kept private so long as Intake URLs are not shared.
          </Typography>
          
          <ul className={`list ${styles.bookingsOverviewList} ${styles.bookingsOverviewListIntake}`}>

            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                <b>Copy button:</b> This will automatically copy the link to your clipboard so that it can
                be pasted into emails, group chats, etc.
              </Typography>
            </li>

            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                <b>Regenerate button:</b> This generates a completely new link for the event
              </Typography>
            </li>

            <ul className={styles.loginProjectsSublist}>

              <li className={`listItem ${styles.bookingsOverviewListItem}`}>
                <Typography variant="body1" className={classes.bookingsOverviewListText}>
                  Useful if original link gets sent out to wrong group chat or if event information has been changed within event
                </Typography>
              </li>

              <li className={`listItem ${styles.bookingsOverviewListItem}`}>
                <Typography variant="body1" className={classes.bookingsOverviewListText}>
                  If the link is regenerated while the event is happening attendees will get locked out if they refresh their browser
                </Typography>
              </li>

              <li className={`listItem ${styles.bookingsOverviewListItem}`}>
                <Typography variant="body1" className={classes.bookingsOverviewListText}>
                  Attendees with old link will not be able to enter anymore
                </Typography>
              </li>

              <li className={`listItem ${styles.bookingsOverviewListItem}`}>
                <Typography variant="body1" className={classes.bookingsOverviewListText}>
                  Registered guests will remain in-room and can still enter through their unique URL 
                  if Intake URL is regenerated
                </Typography>
              </li>

            </ul>

          </ul>
          
        </div>

        <div className={styles.bookingsOverviewGridItem}>
          <Typography variant="h3" className={classes.loginProjectsSubheader}>
            Calendar Entry
          </Typography>

          <img alt="Calendar entry toggle" src="assets/images/bookingsOverview-cal1.png" className={`image ${styles.bookingsOverviewImage}`} />

          <Typography variant="h4" className={`${classes.bookingsOverviewSubheader} ${classes.bookingsOverviewSubheaderSignUp}`}>
            Toggle
          </Typography>

          <Typography variant="body1" className={classes.bookingsOverviewText}>
            The Calendar Entry switch allows you to assign multiple bookings as a calendar entry
            and by default will be set to OFF when creating a new booking. Useful for when your event has multiple time slots.
          </Typography>
        </div>

        <div className={styles.bookingsOverviewGridItem}>
          <img alt="Assign calendar popup" src="assets/images/bookingsOverview-cal2.png" className={`image ${styles.bookingsOverviewImage} ${styles.bookingsOverviewImage2}`} />

          <Typography variant="h4" className={`${classes.bookingsOverviewSubheader} ${classes.bookingsOverviewSubheaderCalendarEntry}`}>
            Assigning a Booking to a Calendar Entry
          </Typography>

          <ul className={`list ${styles.bookingsOverviewList} ${styles.bookingsOverviewListEntry}`}>
            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                Switch the "Calendar Entry" toggle to ON (green)
              </Typography>
            </li>

            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                Select a calendar you wish to assign to
              </Typography>
            </li>

            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                Click "Save"
              </Typography>
            </li>

            <ul className={styles.loginProjectsSublist}>
              <li className={`listItem ${styles.bookingsOverviewListItem}`}>
                <Typography variant="body1" className={classes.bookingsOverviewListText}>
                  You may wish to name your booking the same as your calendar name, as booking names are not
                  shown on the calendar
                </Typography>
              </li>
            </ul>

          </ul>
          
        </div>

        <div className={styles.bookingsOverviewGridItem}>

          <Typography variant="h3" className={classes.loginProjectsSubheader}>
            Run
          </Typography>

          <img alt="Booking run times edit" src="assets/images/bookingsOverview-4.png" className={`image ${styles.bookingsOverviewImage}`} />

          <Typography variant="h4" className={`${classes.bookingsOverviewSubheader} ${classes.bookingsOverviewSubheaderRun}`}>
            Start Time
          </Typography>

          <Typography variant="body1" className={`${classes.bookingsOverviewText}`}>
            This is the time your event starts
          </Typography>
          
          <ul className={`list ${styles.bookingsOverviewList}`}>
            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                Edit by clicking the pencil icon
              </Typography>
            </li>

            <ul className={styles.loginProjectsSublist}>

              <li className={`listItem ${styles.bookingsOverviewListItem}`}>
                <Typography variant="body1" className={classes.bookingsOverviewListText}>
                  <b>Date:</b> Select a starting date by clicking the calendar icon
                </Typography>
              </li>

              <li className={`listItem ${styles.bookingsOverviewListItem}`}>
                <Typography variant="body1" className={classes.bookingsOverviewListText}>
                  <b>Time:</b> Select a starting time using the clock icon
                </Typography>
              </li>

              <li className={`listItem ${styles.bookingsOverviewListItem}`}>
                <Typography variant="body1" className={classes.bookingsOverviewListText}>
                  <b>Timezone:</b> The producer sets the timezone for the event they are creating
                </Typography>
              </li>

            </ul>

          </ul>
          
        </div>

        <div className={styles.bookingsOverviewGridItem}>
          <Typography variant="h3" className={classes.loginProjectsSubheader}>
            Run Start/End Time
          </Typography>

          <img className={`image ${styles.bookingsOverviewImage}`} alt="Run start/end time edit" src="assets/images/bookingsOverview-5.png" />

          <Typography variant="h4" className={`${classes.bookingsOverviewSubheader} ${classes.bookingsOverviewSubheaderRun}`}>
            End Time
          </Typography>

          <Typography variant="body1" className={classes.bookingsOverviewText}>
            This is the time your event ends
          </Typography>

          <ul className={`list ${styles.bookingsOverviewList}`}>
            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                Edit by clicking the pencil icon
              </Typography>
            </li>
          </ul>

          <Typography variant="body1" className={classes.bookingsOverviewNoteText}>
            <b>Note:</b> Information on the event will be emailed to attendees in the 
            timezone the producer set the event for.
            Once guests go to intake, their browser will adjust to their timezone.
          </Typography>
          
        </div>

        <div className={styles.bookingsOverviewGridItem}>
          <Typography variant="h3" className={classes.loginProjectsSubheader}>
            Capacity
          </Typography>

          <img className={`image ${styles.bookingsOverviewImage}`} alt="Capacity and grace time edit" src="assets/images/bookingsOverview-6.png" />

          <Typography variant="h4" className={`${classes.bookingsOverviewSubheader} ${classes.bookingsOverviewSubheaderRun}`}>
            Capacity
          </Typography>

          <Typography variant="body1" className={classes.bookingsOverviewText}>
            This is the total booking capacity
          </Typography>

          <ul className={`list ${styles.bookingsOverviewList} ${styles.bookingsOverviewListCapacity}`}>

            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                To adjust the number of guests per booking, click the pencil icon and set a 
                number for the event
              </Typography>
            </li>

            <ul className={styles.loginProjectsSublist}>

              <li className={`listItem ${styles.bookingsOverviewListItem}`}>
                <Typography variant="body1" className={classes.bookingsOverviewListText}>
                  The booking capacity can be larger than the room capacity
                </Typography>
              </li>

              <li className={`listItem ${styles.bookingsOverviewListItem}`}>
                <Typography variant="body1" className={classes.bookingsOverviewListText}>
                  A Showboat room capacity is currently set to 50 attendees per room
                </Typography>
              </li>

              <li className={`listItem ${styles.bookingsOverviewListItem}`}>
                <Typography variant="body1" className={classes.bookingsOverviewListText}>
                  When a room reaches 90% capacity, a new room will open up
                </Typography>
              </li>

              <li className={`listItem ${styles.bookingsOverviewListItem}`}>
                <Typography variant="body1" className={classes.bookingsOverviewListText}>
                  This gives attendees the option to be able to switch rooms if they want
                </Typography>
              </li>

              <li className={`listItem ${styles.bookingsOverviewListItem}`}>
                <Typography variant="body1" className={classes.bookingsOverviewListText}>
                  Different spaces have different capacities and are set by Showboat. Check with your Showboat representative about your room capacity.
                </Typography>
              </li>

            </ul>
          </ul>

        </div>

        <div className={`${styles.bookingsOverviewGridItem} ${styles.bookingsOverviewGridItemGraceTime}` }>
          <Typography variant="h4" className={`${classes.bookingsOverviewSubheader} ${classes.bookingsOverviewSubheaderRun}`}>
            Grace Time
          </Typography>

          <Typography variant="body1" className={classes.bookingsOverviewText}>
            Grace time is the number of minutes/hours attendees can linger in the space after the booking
            end time has been reached
          </Typography>
          
          <ul className={`list ${styles.bookingsOverviewList}`}>

            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                To edit click the pencil icon and type in the number (in minutes)
              </Typography>
            </li>

          </ul>

          <Typography variant="h4" className={`${classes.bookingsOverviewSubheader} ${classes.bookingsOverviewSubheaderRun}`}>
            User Hours
          </Typography>

          <Typography variant="body1" className={classes.bookingsOverviewText}>
            This is the total number of usage hours for this booking
          </Typography>

          <ul className={`list ${styles.bookingsOverviewList}`}>
            <li className={`listItem ${styles.bookingsOverviewListItem}`}>
              <Typography variant="body1" className={classes.bookingsOverviewListText}>
                Refresh to update count
              </Typography>
            </li>
          </ul>
          
          <Typography variant="h4" className={`${classes.bookingsOverviewSubheader} ${classes.bookingsOverviewSubheaderRun}`}>
            Save Changes
          </Typography>

          <Typography variant="body1" className={classes.bookingsOverviewText}>
            Click the "Done" button to save all changes made to the booking and to return to the booking space
          </Typography>
        </div>
          
      </div>
    </React.Fragment>
  )
}
