import { Divider, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

import "../../../App.css";
import styles from './Errors.module.css';
import { ErrorsStyles } from './ErrorsStyles';

interface Props {
  
}

export default function Errors(props: Props): ReactElement {

  const classes = ErrorsStyles();
  
  return (
    <React.Fragment>
      
      <Typography variant="h2" className={`${classes.gridHeader} ${classes.gridHeaderErrors}`}>
        Messages
      </Typography>

      <Divider className={`${classes.divider} ${classes.errorsDivider}`}></Divider>

      <div className={styles.errorsGrid}>

        <div className={`${styles.errorsGridItem} ${styles.errorsGridItemMessages}`}>
          <Typography variant="h3" className={classes.errorsSubheader}>
            Messages
          </Typography>

          <img className={`image ${styles.errorsImage}`} alt="Producer Portal messages tab" src="assets/images/errors-1.png" />

          

        </div>

        <div className={styles.errorsGridItem}>
        <Typography variant="h4" className={classes.errorsListHeader}>
            Messages Section
          </Typography>

          <Typography variant="body1" className={classes.errorsText}>
            Configure the messaging visible to attendees and presenters if they arrive
            at a link that has been updated
          </Typography>

          <ul className={`list ${styles.errorsList}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Support Message:</b>
              </Typography>
            </li>

            <ul>
              <li className={`listItem`}>
                <Typography variant="body1">
                  Post information inside your Showboat booking to allow attendees access to support.
                  This can be text or a link which will open up a new browser tab.
                </Typography>
              </li>
            </ul>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Configurable web page error messages are:</b>
              </Typography>
            </li>

            <ul>
              <li className={`listItem`}>
                <Typography variant="body1">
                  <b>Event Link Error:</b> Displayed when the Intake URL has changed
                </Typography>
              </li>

              <li className={`listItem`}>
                <Typography variant="body1">
                  <b>Registration Link Error:</b> Displayed when event link has been regenerated
                </Typography>
              </li>

              <li className={`listItem`}>
                <Typography variant="body1">
                  <b>Attendee Link Error:</b> Displayed when attendee invite link has been regenerated 
                </Typography>
              </li>

              <li className={`listItem`}>
                <Typography variant="body1">
                  <b>Presenter Link Error:</b> Displayed when guest is deleted as a presenter or presenter link is regenerated
                </Typography>
              </li>

            </ul>

          </ul>
        </div>

        <div className={styles.errorsGridItem}>

          <Typography variant="h3" className={classes.errorsSubheader}>
            Editing Support Messages
          </Typography>

          <img className={`image ${styles.errorsImage}`} alt="Error message edit tab" src="assets/images/errors-2.png" />

          <Typography variant="h4" className={classes.errorsListHeader}>
            How to Edit
          </Typography>

          <ul className={`list ${styles.errorsList}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                To edit Support Message, click the pencil icon which will prompt a text
                box to customize your message. ("Support Active" toggle will be off by default).
              </Typography>
            </li>

            <li className={`listItem`}>
              <Typography variant="body1">
                Add your own custom workflow in order to provide support for your booking
              </Typography>
            </li>

            <li className={`listItem`}>
              <Typography variant="body1">
                To turn on "Support Message" feature, switch "Support Active" toggle on (green).
                This will add a button in the in-room app.
              </Typography>
            </li>

          </ul>


        </div>
        
        <div className={styles.errorsGridItem}>

          <Typography variant="h4" className={classes.errorsSubheader}>
            Editing Web Page Error Messages
          </Typography>

          <img className={`image ${styles.errorsImage}`} alt="Error message edit icons" src="assets/images/errors-3.png" />

          <Typography variant="h4" className={classes.errorsListHeader}>
            How to Edit
          </Typography>
          
          <ul className={`list ${styles.errorsList}`}>
            <li className={`listItem`}>
              <Typography>
                To edit error messages for each different link (Event Link, Registration Link, Attendee Link, Presenter Link) click 
                the pencil which will prompt a text box to customize your error message
              </Typography>
            </li>
          </ul>


        </div>

        <div className={styles.errorsGridItem}>

          <Typography variant="h4" className={classes.errorsSubheader}>
            Support Message Example
          </Typography>

          <img className={`image ${styles.errorsImage} ${styles.errorsImageSupport}`} alt="Registration page error example" src="assets/images/errors-4.png" />

        </div>

        <div className={styles.errorsGridItem}>
          <Typography variant="h4" className={classes.errorsSubheader}>
            Web Page Error Message Example
          </Typography>

          <img className={`image ${styles.errorsImage} ${styles.errorsImageWebPage}`} alt="Registration page error example" src="assets/images/errors-5.png" />
        </div>


      </div>

    </React.Fragment>
  )
}
