import { Divider, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

import styles from './WebAssets.module.css';
import { WebAssetsStyles } from './WebAssetsStyles';

interface Props {
  
}

export default function WebAssets(props: Props): ReactElement {

  const classes = WebAssetsStyles();
  
  return (
    <React.Fragment>
      <Typography variant="h2" className={`${classes.gridHeader}`}>
        Web
      </Typography>

      <Divider className={`${classes.divider}`}></Divider>

      <div className={styles.webGrid}>
        
        <div className={styles.webGridItem}>
          <Typography variant="h3" className={classes.webSubHeader}>
            Logo Icon
          </Typography>

          <img alt="Logo icon asset" src="assets/images/webAssets-1.png" className={styles.webAssetsImage}/>
          <img className={styles.arrowWebAssets1} src="assets/images/arrowUp.png" alt="Arrow pointer" />
          
          <div className={styles.webAssetContentHolder}>
            <ul className={styles.webAssetList}>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.webAssetListText}>
                  Appears on the registration and intake screens
                </Typography>
              </li>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.webAssetListText}>
                  Format
                </Typography>
              </li>
              <ul>
                <li className={`listItem`}>
                  <Typography variant="body1" className={classes.webAssetListText}>
                    .PNG
                  </Typography>
                  
                </li>
                <li className={`listItem ${styles.listItemBottom}`}>
                  <Typography variant="body1" className={classes.webAssetListText}>
                    145px x 145px
                  </Typography>
                </li>
              </ul>
            </ul>
          </div>
        </div>

        <div className={styles.webGridItem}>
          <Typography variant="h3" className={classes.webSubHeader}>
            Highlight Color
          </Typography>
          
          <img alt="Highlight color" src="assets/images/webAssets-2.png" className={styles.webAssetsImage}/>
          <img className={styles.arrowWebAssets2} src="assets/images/arrowRight.png" alt="Arrow pointer" />

          <div className={styles.webAssetContentHolder}>
            <ul className={styles.webAssetList}>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.webAssetListText}>
                  Controls the selected state color for various input fields, as well as some button colors
                </Typography>
              </li>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.webAssetListText}>
                  Format
                </Typography>
              </li>
              <ul>
                <li className={`listItem`}>
                  <Typography variant="body1" className={classes.webAssetListText}>
                    Color picker or Hex color value
                  </Typography>
                </li>
              </ul>
            </ul>
          </div>
          
        </div>

        <div className={styles.webGridItem}>
          <Typography variant="h3" className={classes.webSubHeader}>
            Welcome Asset
          </Typography>

          <img alt="Welcome asset" src="assets/images/webAssets-3.png" className={styles.webAssetsImage}/>
          <img className={styles.arrowWebAssets3} src="assets/images/arrowRight.png" alt="Arrow pointer" />

          <div className={styles.webAssetContentHolder}>
            <ul className={styles.webAssetList}>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.webAssetListText}>
                  An image or video that appears on the landing page for the event
                </Typography>
              </li>
            </ul>

            <ul className={`${styles.webAssetList} ${styles.webAssetListSmall}`}>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.webAssetListText}>
                  <b>Image Format</b>
                </Typography>
              </li>

              <ul>
                <li className={`listItem`}>
                  <Typography variant="body1" className={classes.webAssetListText}>
                    .PNG
                  </Typography>
                </li>
                <li className={`listItem`}>
                  <Typography variant="body1" className={classes.webAssetListText}>
                    960px x 540px
                  </Typography>
                </li>
              </ul>
            </ul>

            <ul className={`${styles.webAssetList} ${styles.webAssetListSmallRight}`}>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.webAssetListText}>
                  <b>Video Format</b>
                </Typography>
              </li>

              <ul>
                <li className={`listItem`}>
                  <Typography variant="body1" className={classes.webAssetListText}>
                    .MP4
                  </Typography>
                </li>
                <li className={`listItem`}>
                  <Typography variant="body1" className={classes.webAssetListText}>
                    Up to 10MB
                  </Typography>
                </li>
              </ul>

            </ul>
            


          </div>


        </div>

      </div>

    </React.Fragment>
  )
}
