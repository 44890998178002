import { makeStyles, createStyles } from '@material-ui/core/styles';

const PresenterToolsStyles = makeStyles((theme: any) => createStyles({
  presenterToolsHolder: {
    width: 996,
    minHeight: 4250,
    height: "auto",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    top: 80,
    textAlign: "left",
    ['@supports ( -moz-appearance:none )']: {
      minHeight: 4100,
    },
    [theme.breakpoints.down('md')]: {
      width: 786,
      minHeight: 2650,
    },
    [theme.breakpoints.down('sm')]: {
      width: 575,
      minHeight: 2550,
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 5300,
    },
    '@media (max-width: 614.95px)': {
      width: 475,
      minHeight: 5670
    },
    '@media (max-width: 500px)': {
      width: 390,
      minHeight: 6500
    },
    '@media (max-width: 435px)': {
      width: 345,
      minHeight: 6800
    },
    '@media (max-width: 360px)': {
      width: 300,
      minHeight: 6900,
    }
  },
  presenterToolsHeader: {
    fontFamily: "Open Sans, sans-serif",
    position: "relative",
    fontSize: "2.5rem",
    color: "white",
    top: 14,
    right: 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.9rem"
    },
    '@media (max-width: 360px)': {
      fontSize: "1.65rem",
      width: "100%"
    }
  },
  presenterToolsCaption: {
    fontSize: "1rem",
    color: "white",
    position: "relative",
    top: 70
  },
  modesHeader: {
    color: "white",
    fontSize: "1.875rem",
    position: "relative",
    top: 115,
    fontFamily: "Open Sans, sans-serif",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.75rem"
    },
    '@media (max-width: 360px)': {
      fontSize: "1.6rem"
    }
  },
  modesSubheader: {
    color: "white",
    position: "relative",
    fontSize: "1rem",
    top: 128,
  },
  modesGridSubheader: {
    fontSize: "1.5rem",
    color: "#FFB700",
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.2rem"
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: "1.4rem"
    }
  },
  modesGridText: {
    position: "relative",
    color: "white",
    fontSize: "1rem",
    top: 50,
    [theme.breakpoints.down('sm')]: {

    }
  },
  modesGridListText: {
    position: "relative",
    color: "white",
    fontSize: "1rem",
  },
  additionalFeaturesHeader: {
    color: "white",
    fontSize: "1.875rem",
    position: "relative",
    top: 225,
    fontFamily: "Open Sans, sans-serif",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.75rem",
      top: 240,
    },
    '@media (max-width: 500px)': {
      top: 170,
    },
    '@media (max-width: 360px)': {
      fontSize: "1.6rem"
    }
  },
  additionalFeaturesHeaderControls: {
    top: 0,
  },
  additionalFeaturesGridSubheader: {
    fontSize: "1.5rem",
    color: "#FFB700",
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.2rem"
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: "1.4rem"
    }
  },
  additionalFeaturesGridSubheaderTeleport: {
    fontSize: "1.45rem",
    [theme.breakpoints.down('md')]: {
      fontSize: "1.45rem"
    },
    [theme.breakpoints.down('sm')]: {
      display: "inline-block",
      fontSize: "1.2rem",
      width: "150%"
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: "1.4rem"
    },
    '@media (max-width: 614.95px)': {
      width: "125%"
    },
    '@media (max-width: 500px)': {
      display: "inline"
    }
  },
  additionalFeaturesGridText: {
    position: "relative",
    color: "white",
    fontSize: "1rem",
    top: 28
  },
  additionalFeaturesGridTextBottom: {
    [theme.breakpoints.down('sm')]: {
      width: 280
    },
    '@media (max-width: 614.95px)': {
      width: 220
    },
    '@media (max-width: 500px)': {
      width: "auto"
    }
  },
  additionalFeaturesListText: {
    position: "relative",
    color: "white",
    fontSize: "1rem",
  },
  imageHolder: {
    backgroundColor: "#1B1B1D",
    display: "inline-block",
    position: "relative",
    width: 316,
    height: 134,
    top: 28,
    [theme.breakpoints.down('md')]: {
      width: 250,
      height: 106
    },
    [theme.breakpoints.down('sm')]: {
      width: 182,
      height: 77
    },
    [theme.breakpoints.down('xs')]: {
      width: 276,
      height: 117
    },
    ['@media screen and (max-width: 739.95px) and (-ms-high-contrast: none), (-ms-high-contrast: active)']: {
      width: 266
    },
    '@media (max-width: 614.95px)': {
      width: 228,
      height: 97
    },
    ['@media screen and (max-width: 614.95px) and (-ms-high-contrast: none), (-ms-high-contrast: active)']: {
      width: 218,
    },
    '@media (max-width: 500px)': {
      width: 358,
      height: 152,
      left: "50%",
      transform: "translateX(-50%)"
    },
    ['@media screen and (max-width: 500px) and (-ms-high-contrast: none), (-ms-high-contrast: active)']: {
      transform: "translateX(0%)"
    },
    '@media (max-width: 435px)': {
      width: 345,
      height: 146,
    },
    ['@media screen and (max-width: 435px) and (-ms-high-contrast: none), (-ms-high-contrast: active)']: {
      transform: "translateX(-50%)"
    },
    '@media (max-width: 360px)': {
      width: 300,
      height: 127,
    }
  }
})
) 

export { PresenterToolsStyles };  