import { Button, Divider, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import AddIcon from '@material-ui/icons/AddBox';
import LinkIcon from '@material-ui/icons/Launch'

import "../../../App.css";
import { PresentersStyles } from './PresentersStyles';
import styles from './Presenters.module.css';

interface Props {
  
}

export default function Presenters(props: Props): ReactElement {

  const classes = PresentersStyles();
  
  
  return (
    <React.Fragment>
      <Typography variant="h2" className={`${classes.gridHeader} ${classes.presentersHeader}`}>
        Presenters
      </Typography>
      
      <a href="https://showboat.live/presenter-tools" target="_blank">
        <Button classes={{ root: classes.presenterToolsButton, label: classes.presenterToolsButtonLabel}} variant="contained" color="primary" >
          <LinkIcon className={classes.linkIcon} />
          View Presenter Tools Guide
        </Button>
      </a>

      <Divider className={`${classes.divider} ${classes.presentersDivider}`}></Divider>

      <div className={styles.presentersGrid}>

        <div className={styles.presentersGridItem}>

          <Typography variant="h3" className={classes.presentersSubheader}>
            Presenters
          </Typography>

          <img className={`image ${styles.presentersImage}`} alt="Presenters tab" src="assets/images/presenters-1.png" />

          <Typography variant="h4" className={classes.presentersListHeader}>
            Presenter Information
          </Typography>

          <Typography variant="body1" className={classes.presentersText}>
            A presenter is a guest that has additional capabilites for controlling the space. Some notes
            about presenters in Showboat:
          </Typography>

          <ul className={`list ${styles.presentersList}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                Bookings can have multiple presenters
              </Typography>
            </li>

            <li className={`listItem`}>
              <Typography variant="body1">
                Each presenter can have one slide presentation assigned to them
              </Typography>
            </li>
            
            <ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Presentations are configured in the Presentations Tab
                </Typography>
              </li>
              
            </ul>

          </ul>


        </div>

        <div className={`${styles.presentersGridItem} ${styles.presentersGridItemAdd}`}>

          <div className={styles.addPresenterHolder}>
            
            <img className={`image ${styles.presentersImage}`} alt="Add presenter popup" src="assets/images/presenters-2.png" />

            <ul className={`list ${styles.presentersList} ${styles.presentersListIcon}`}>

              <li className={`listItem`}>
                <Typography variant="body1">
                  <AddIcon className={classes.addIcon} />
                  Click the add button to manually enter a presenter's information
                </Typography>
              </li>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Click the "Manage" button next to presenter's name to update their information
                </Typography>
              </li>

            </ul>


          </div>

        </div>

        <div className={styles.presentersGridItem}>

          <Typography variant="h3" className={classes.presentersSubheader}>
            Manage Presenters
          </Typography>

          <img className={`image ${styles.presentersImage}`} alt="Manage presenter popup" src="assets/images/presenters-3.png" />

          <Typography variant="h4" className={classes.presentersListHeader}>
            Managing Presenters
          </Typography>

          <ul className={`list ${styles.presentersList}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>URL</b>
              </Typography>
            </li>

            <ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  URL Link for the presenter to enter the event and have access to presenter tools
                </Typography>
              </li>

            </ul>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Regenerate Link</b>
              </Typography>
            </li>

            <ul>
              <li className={`listItem`}>
                <Typography variant="body1">
                  Allows a new presenter link to be generated. Useful if the link goes to the wrong person(s)
                </Typography>
              </li>
              <li className={`listItem`}>
                <Typography variant="body1">
                  Creates a new entrance link for the presenter(s), and the old link won't work
                </Typography>
              </li>
            </ul>

          </ul>

        </div>

        <div className={`${styles.presentersGridItem} ${styles.presentersGridItemResend}`}>


            <ul className={`list ${styles.presentersList}`}>

              <li className={`listItem`}>
                <Typography variant="body1">
                  <b>Resend Invite</b>
                </Typography>
              </li>

              <ul>
                <li className={`listItem`}>
                  <Typography variant="body1">
                    Resend an invite if the presenter can't find it in their e-mails or if you generated a new link
                  </Typography>
                </li>
              </ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  <b>Copy Link</b>
                </Typography>
              </li>

              <ul>
                <li className={`listItem`}>
                  <Typography variant="body1">
                    Automatically copies the link to your clipboard
                  </Typography>
                </li>
              </ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  <b>Delete Presenter</b>
                </Typography>
              </li>
              
              <ul>

                <li className={`listItem`}>
                  <Typography variant="body1">
                    Deleting a presenter takes away their ability to present and control the room
                    through the Presenter Tools
                  </Typography>
                </li>

                <li className={`listItem`}>
                  <Typography variant="body1">
                    Their presenter link will no longer work and instead an error message will display
                    if they try to enter using their link
                  </Typography>
                </li>

              </ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  <b>Presenter Info</b>
                </Typography>
              </li>

              <ul>
                <li className={`listItem`}>
                  <Typography variant="body1">
                    Presenter basic info will automatically be populated on their avatar
                  </Typography>
                </li>

                <li className={`listItem`}>
                  <Typography variant="body1">
                    This information can be changed on intake
                  </Typography>
                </li>

                <li className={`listItem`}>
                  <Typography variant="body1">
                    The email address on the presenter info is how they will get their event link (important!)
                  </Typography>
                </li>

              </ul>

              <ul>
                <li className={`listItem`}>
                  <Typography variant="body1">
                    <b>Allow Early Access Toggle</b>
                  </Typography>
                </li>

                <ul>
                  <li className={`listItem`}>
                    <Typography variant="body1">
                      This allows each presenter the ability to enter the room before the event starts
                      and will be automatically toggled on when adding a presenter.
                    </Typography>
                  </li>
                </ul>
                
              </ul>

              <ul>
                <li className={`listItem`}>
                  <Typography variant="body1">
                    <b>Presentation Selection</b>
                  </Typography>
                </li>

                <ul>

                  <li className={`listItem`}>
                    <Typography variant="body1">
                      Assign slide decks that are uploaded in the Presentations tab
                    </Typography>
                  </li>

                  <li className={`listItem`}>
                    <Typography variant="body1">
                      Only one presentation per presenter
                    </Typography>
                  </li>

                </ul>
              </ul>

            </ul>

        </div>
    
      </div>

    </React.Fragment>
  )
}
