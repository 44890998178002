import { Divider, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import BookingsOverview from './BookingsOverview/BookingsOverview';
import BrandingInRoom from './BrandingInRoom/BrandingInRoom';
import Calendar from './Calendar/Calendar';
import Email from './Email/Email';
import EmailBranding from './EmailBranding/EmailBranding';
import Errors from './ErrorsSection/Errors';
import GuestsAndAttendees from './GuestsAndAttendees/GuestsAndAttendees';
import LoginProjects from './LoginProjects/LoginProjects';
import Presentations from './Presentations/Presentations';
import Presenters from './Presenters/Presenters';

import styles from './ProducerPortalGuide.module.css';
import { ProducerPortalGuideStyles } from './ProducerPortalGuideStyles'; 
import WebBranding from './WebBranding/WebBranding';

interface Props {
  
}

export default function ProducerPortalGuide(props: Props): ReactElement {

  const classes = ProducerPortalGuideStyles();
  
  return (
    <div className={classes.producerPortalGuideHolder}>
      <Typography variant="h1" className={classes.producerPortalGuideHeader}>
        Producer Portal User Guide
      </Typography>

      <LoginProjects />

      <Calendar />

      <BookingsOverview />

      <GuestsAndAttendees />

      <Presenters />

      <Email />

      <Errors />

      <BrandingInRoom />

      <WebBranding />

      <EmailBranding />

      <Presentations />

    </div>
  )
}
