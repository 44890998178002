import { makeStyles, createStyles } from '@material-ui/core/styles';

const GuestsAndAttendeesStyles = makeStyles((theme: any) => createStyles({
  gridHeader: {
    color: "#00B6FF",
    fontFamily: "Open Sans, sans-serif",
    position: "relative",
    fontSize: "2.25rem",
    fontWeight: 400,
    '@media(max-width: 850px)': {
      fontSize: "1.8rem"
    },
    '@media(max-width: 500px)': {
      fontSize: "1.7rem"
    }
  },
  guestsAttendeesHeader: {
    top: 315,
    '@media(max-width: 1080px)': {
      top: 320
    }
  },
  divider: {
    color: "white",
    backgroundColor: "white",
    opacity: 1,
    height: 1,
    position: "relative",
  },
  guestsAttendeesDivider: {
    top: 340,
    '@media(max-width: 1080px)': {
      top: 345
    }
  },
  guestsAndAttendeesSubheader: {
    color: "#FFB700",
    position: "relative",
    top: 0,
    left: 0,
    fontSize: "1.5rem",
    '@media(max-width: 850px)': {
      fontSize: "1.3rem"
    }
  },
  guestsAndAttendeesListHeader: {
    fontSize: "1.25rem",
    color: "#EEAA00",
    position: "relative",
    top: 40,
    '@media(max-width: 500px)': {
      fontSize: "1rem"
    }
  },
  guestsAndAttendeesText: {
    color: "white",
    position:"relative",
    fontSize: "1rem",
    top: 50,
    width: 450,
    '@media (max-width: 1080px)': {
      width: 390
    },
    '@media (max-width: 850px)': {
      width: 340
    },
    '@media (max-width: 675px)': {
      width: 500
    },
    '@media(max-width: 500px)': {
      width: 350,
    },
    '@media(max-width: 375px)': {
      width: 300,

    }

  },
  guestsAndAttendeesListText: {

  },
  guestsAndAttendeesDetailsHeader: {
    top: 67,
    
  },
  guestsAndAttendeesTextDetails: {
    top: 77,
  },
  guestsAndAttendeesTextDetailsTwo: {
    top: 55,
  },
  guestsAndAttendeesTextGuestList: {
    width: 490,
    '@media (max-width: 1080px)': {
      width: 400
    },
    '@media (max-width: 850px)': {
      width: 320,
    },
    '@media (max-width: 675px)': {
      width: 475
    },
    '@media(max-width: 500px)': {
      width: 350,
    },
    '@media(max-width: 375px)': {
      width: 300,
    }
  },
  guestListDetailsHeader: {
    fontSize: "1.25rem",
    color: "#EEAA00",
    position: "relative",
    '@media(max-width: 500px)': {
      fontSize: "1rem"
    }
  },
  listIcon: {
    color: "#EEAA00",
    display: "inline",
    position: "relative",
    top: 5,
    marginRight: 5
  },
  
})
)

export { GuestsAndAttendeesStyles };