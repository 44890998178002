import { makeStyles, createStyles } from '@material-ui/core/styles';

const EmailAssetStyles = makeStyles((theme: any) => createStyles({
  gridHeader: {
    color: "white",
    fontFamily: "Open Sans, sans-serif",
    position: "relative",
    fontSize: "2.25rem",
    fontWeight: 400,
    top: 73,
    '@media(max-width: 850px)': {
      fontSize: "1.8rem"
    },
    '@media(max-width: 500px)': {
      fontSize: "1.7rem"
    }
  },
  divider: {
    color: "white",
    backgroundColor: "white",
    opacity: 1,
    height: 1,
    position: "relative",
    top: 91
  },
  emailSubHeader: {
    color: "#FFB700",
    position: "relative",
    top: 0,
    left: 0,
    fontSize: "1.5rem",
    '@media(max-width: 850px)': {
      fontSize: "1.3rem"
    },
    '@media(max-width: 500px)': {
    }
  },
  emailListText: {
    color: "white",
    position:"relative",
    fontSize: "1rem"
  }
})
)

export { EmailAssetStyles };