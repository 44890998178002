import { Typography } from '@material-ui/core';
import React, { ReactElement } from 'react'
import EbookIcon from './EbookIcon/EbookIcon';

import styles from "./EBookImageHeader.module.css";
import { EBookImageHeaderStyles } from "./EBookImageHeaderStyles";


interface Props {
  header: string;
  subHeader: string;
}

export default function EBookImageHeader(props: Props): ReactElement {

  const classes = EBookImageHeaderStyles();
  
  return (
    <div className={styles.headerHolder}>
      <div className={styles.iconHolder}>

        <EbookIcon />
        
        <Typography variant="body1" className={classes.iconText}>
          eBook
        </Typography>

      </div>
      
      <Typography variant="h1" className={classes.ebookHeader}>
        {props.header}
      </Typography>
      <Typography variant="h2" className={classes.ebookSubHeader}>
        {props.subHeader}
      </Typography>
    </div>
  );
}
