import React, { ReactElement, useEffect } from 'react';
import ReactGA from "react-ga";

import MainDisplay2 from '../../components/MainDisplay2/MainDisplay';
import WideImage from '../../components/WideImage/WideImage';

import styles from './MainPage.module.css';

interface Props {
  
}

export default function MainPage({}: Props): ReactElement {

  useEffect(() => {
    document.title = "Showboat";

    //Trigger Google Analytics pageview
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])
  
  
  return (
    <div className={styles.mainPageWrapper}>
      <WideImage />

      <MainDisplay2 />

    </div>
  )
}
