import { Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import "react-slideshow-image/dist/styles.css";
import EBookImageHeader from "../EbookImageHeader/EBookImageHeader";

import styles from "./WideImage.module.css";
import { WideImageStyles } from "./WideImageStyles";

const { Fade } = require("react-slideshow-image");

interface Props {
  northPole?: boolean;
  ebookEffectiveVirtualNetworking?: boolean;
}

export default function WideImage(props: Props): ReactElement {
  let fadeImages;

  if (props.northPole) {
    fadeImages = [
      "assets/images/SB-NorthPole-1.png",
      "assets/images/SB-NorthPole-2.png",
      "assets/images/SB-NorthPole-3.png",
    ];
  } else if (props.ebookEffectiveVirtualNetworking) {
    fadeImages = [
      "/assets/images/ebook-networking-bkg.png"
    ]
  } else {
    fadeImages = [
      "assets/images/Showboat-RoomView-01.png",
      "assets/images/Showboat-RoomView-02.png",
      "assets/images/Showboat-RoomView-03.png",
      "assets/images/Showboat-RoomView-04.png",
      "assets/images/Showboat-RoomView-05.png",
    ];
  }

  const classes = WideImageStyles();

  if (props.northPole) {
    return (
      <div className={styles.slideshowHolder}>
        <Fade arrows={false} duration={5000}>
          <div className="each-fade">
            <div>
              <img
                alt="Showboat North Pole room slide 1"
                className={styles.slideshowImage}
                src={fadeImages[0]}
              />
            </div>
          </div>
          <div className="each-fade">
            <div>
              <img
                alt="Showboat North Pole room slide 2"
                className={styles.slideshowImage}
                src={fadeImages[1]}
              />
            </div>
          </div>
          <div className="each-fade">
            <div>
              <img
                alt="Showboat North Pole room slide 3"
                className={styles.slideshowImage}
                src={fadeImages[2]}
              />
            </div>
          </div>
        </Fade>
      </div>
    );
  } else if (props.ebookEffectiveVirtualNetworking) { 
    return (
      <div className={`${styles.slideshowHolder} ${styles.slideshowHolderEbook}`}>

        <EBookImageHeader
          header={"Effective Networking for Virtual Events"}
          subHeader={"How to rescue virtual ROI."}
        />
        
        <img
          alt="Showboat in-room slide 1"
          className={styles.slideshowImage}
          src={fadeImages[0]}
        />
      </div>
    )
  } else {
    return (
      <div className={styles.slideshowHolder}>
        <Fade arrows={false} duration={5000}>
          <div className="each-fade">
            <div>
              <img
                alt="Showboat in-room slide 1"
                className={styles.slideshowImage}
                src={fadeImages[0]}
              />
            </div>
          </div>
          <div className="each-fade">
            <div>
              <img
                alt="Showboat in-room slide 2"
                className={styles.slideshowImage}
                src={fadeImages[1]}
              />
            </div>
          </div>
          <div className="each-fade">
            <div>
              <img
                alt="Showboat in-room slide 3"
                className={styles.slideshowImage}
                src={fadeImages[2]}
              />
            </div>
          </div>
          <div className="each-fade">
            <div>
              <img
                alt="Showboat in-room slide 4"
                className={styles.slideshowImage}
                src={fadeImages[3]}
              />
            </div>
          </div>
          <div className="each-fade">
            <div>
              <img
                alt="Showboat in-room slide 5"
                className={styles.slideshowImage}
                src={fadeImages[4]}
              />
            </div>
          </div>
        </Fade>
      </div>
    );
  }
}
