import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { ArrowForwardIos } from "@material-ui/icons";
import InputMask from "react-input-mask";
import { CheckCircleOutline } from "@material-ui/icons";

import { MainLandingStyles } from "./NorthPoleStyles";
import styles from "./NorthPole.module.css";
import axios from "axios";

interface Props {
  ptp: boolean,
}

export default function NorthPole(props: Props): ReactElement {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");

  const [tour, setTour] = useState(false);
  const [pricing, setPricing] = useState(false);
  const [moreInformation, setMoreInformation] = useState(false);

  const [nameRequiredError, setNameRequiredError] = useState(false);
  const [emailRequiredError, setEmailRequiredError] = useState(false);
  const [helpWithError, setHelpWithError] = useState(false);

  const [interestedInFreeEvent, setInterestedInFreeEvent] = useState(false);

  const [submitLoadingSpinner, setSubmitLoadingSpinner] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [formSubmitError, setFormSubmitError] = useState(false);

  const handleNameChange = (e) => {
    setNameRequiredError(false);

    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmailRequiredError(false);
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  };

  const handleTourChange = (e) => {
    setHelpWithError(false);
    setTour(e.target.checked);
  };

  const handlePricingChange = (e) => {
    setHelpWithError(false);
    setPricing(e.target.checked);
  };

  const handleMoreInformationChange = (e) => {
    setHelpWithError(false);
    setMoreInformation(e.target.checked);
  };

  const handleInterestedInFreeEventChange = (e) => {
    setInterestedInFreeEvent(e.target.checked);
  }

  const handleFormSubmit = async () => {
    setFormSubmitted(false);

    setFormSubmitError(false);

    let formHasError;

    if (!props.ptp) {
      //Check for required inputs
      if (!name.trim().length) {
        setNameRequiredError(true);
        formHasError = true;
      }

      if (!email.trim().length) {
        setEmailRequiredError(true);
        formHasError = true;
      }

      if (!tour && !pricing && !moreInformation) {
        setHelpWithError(true);
        formHasError = true;
      }

      if (formHasError) return;
    }

    

    let helpWithList = "";
    let helpWithArray = [];

    if (tour) helpWithArray.push("Tour");
    if (pricing) helpWithArray.push("Pricing");
    if (moreInformation) helpWithArray.push("More information");

    helpWithList = helpWithArray.join(", ");

    try {

      console.log("Interested in free event:", interestedInFreeEvent)

      let dataObj = {
        name,
        email,
        phone,
        company,
        helpWithList,
        interestedInFreeEvent,
        ptp: props.ptp,
      };

      setSubmitLoadingSpinner(true);

      const dataSubmitForm = await axios.post(
        "/north-pole-email",
        JSON.stringify(dataObj)
      );

      setSubmitLoadingSpinner(false);

      if (dataSubmitForm.data.result === false) {
        console.log("ERROR");
        return;
      }

      //Trigger Google Analytics event
      ReactGA.event({
        category: "Actions",
        action: "Submitted North Pole Form",
      });

      //Clear form
      setName("");
      setEmail("");
      setPhone("");
      setCompany("");

      setTour(false);
      setPricing(false);
      setMoreInformation(false);

      setFormSubmitted(true);

      //Scroll page
      let formHeader = document.getElementById("northPoleFormHeader");

      if (formHeader) {
        formHeader.scrollIntoView();
      }
    } catch (error) {
      console.log("ERROR", error);
      setSubmitLoadingSpinner(false);
      setFormSubmitError(true);
    }
  };

  const classes = MainLandingStyles();

  return (
    <Paper elevation={0} className={classes.mainDisplayHolder}>
      <div className={styles.mainTextWrapper}>
        {props.ptp ? (
          <>
            <Typography
              variant="h1"
              className={`${classes.mainHeader} ${classes.mainHeaderPeopleTech}`}
            >
              Hello PeopleTech!
            </Typography>

            <Typography variant="h2" className={classes.peopleTechSubheader}>
              Showboat has applied to the 2022 Q1 PeopleTech Growth program and
              are hopeful and incredibly excited about the opportunity. Everyone
              that we have interacted with in connection with PeopleTech have
              been amazing and to show our appreciation we are offering PTP
              companies VERY special pricing on a holiday party or all-hands
              meeting. Use the form below and we will get in touch with more
              information. See you in Showboat.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h1" className={classes.mainHeader}>
              {`BRING THE WHOLE COMPANY `}
              <br className={styles.break} />
              {`TO THE NORTH POLE FOR A `}
              <br className={styles.break} />
              SHOWBOAT VIRTUAL HOLIDAY PARTY
            </Typography>
          </>
        )}

        <div className={styles.mainGridHolderOne}>
          <div className={styles.gridOneItem}>
            <Typography variant="h2" className={classes.gridHeader}>
              MIX-N-MINGLE
            </Typography>

            <Typography
              variant="body1"
              className={
                props.ptp
                  ? `${classes.gridOneText} ${classes.gridOneTextPTP}`
                  : classes.gridOneText
              }
            >
              Showboat is the best networking platform on the web today.
              Distribute a simple web link to your organization and jump into an
              immersive world that merges the latest 3D technology with the most
              advanced video conferencing system available. Roam our holiday
              wonderland, chit-chat in small or large groups, and bring your
              hybrid team together for the most in-person-like virtual event
              possible today. Your team will love it.
            </Typography>

            <img
              className={`${styles.mainImage} ${styles.mainImageGridOne}`}
              alt="North Pole room"
              src="assets/images/NorthPole-Large.png"
            />
          </div>
        </div>

        <div
          className={
            formSubmitted
              ? `${styles.mainGridHolderTwo} ${styles.gridHolderTwoFormSubmitted}`
              : styles.mainGridHolderTwo
          }
        >
          <div className={styles.gridTwoTextHolder}>
            <Typography variant="h2" className={classes.gridTwoHeader}>
              DELIVER GOOD TIDINGS
            </Typography>
            <Typography variant="body1" className={classes.gridTwoText}>
              Whether pre-recorded or live, pass on your well wishes for the new
              year on state-of-the-art integrated presentation displays in a
              plaza-style setting. Showboat can handle simple impromptu talks to
              studio mixed feeds. And once the show is over, everyone can
              instantly get back to the party without loading another app or
              trying to talk each other on a Zoom.
            </Typography>

            <Typography variant="h2" className={classes.gridTwoHeader}>
              PLAY LIKE A KID
            </Typography>
            <Typography variant="body1" className={classes.gridTwoText}>
              Beyond the mix-and-mingle and good-tidings, your team will have
              fun sledding down Candy Cane Hill and searching the North Pole for
              the elusive Narwhal. This will be the hybrid holiday party that
              will get everyone engaged and the team feeling the holiday spirit.
            </Typography>
          </div>

          <div className={styles.gridTwoFormHolder}>
            <Typography
              variant="h2"
              style={{ color: "#94F84B" }}
              className={`${classes.gridTwoHeader} ${classes.gridTwoHeaderForm}`}
              id="northPoleFormHeader"
            >
              {`YOUR JOURNEY TO THE `}
              <br className={styles.break} />
              NORTH POLE STARTS HERE.
            </Typography>

            {formSubmitted ? (
              <>
                <CheckCircleOutline className={classes.successIcon} />

                <Typography className={classes.successMessage}>
                  SUCCESS
                </Typography>

                <Typography className={classes.successText}>
                  Thanks! One of our team members will be in touch shortly.
                </Typography>
              </>
            ) : (
              <div className={styles.formWrapper}>
                <div className={styles.formHolder}>
                  {formSubmitError && (
                    <Typography className={classes.formError}>
                      An error occurred
                    </Typography>
                  )}
                  <TextField
                    variant="outlined"
                    value={name}
                    onChange={handleNameChange}
                    className={classes.tourFormField}
                    label="Name"
                    placeholder="Name"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                        root: classes.inputRoot,
                        focused: classes.inputFocused,
                      },
                    }}
                    error={nameRequiredError}
                  />

                  <TextField
                    variant="outlined"
                    value={email}
                    onChange={handleEmailChange}
                    className={classes.tourFormField}
                    name="Email"
                    label="Email"
                    placeholder="Email"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      },
                    }}
                    error={emailRequiredError}
                  />

                  <InputMask
                    mask="(999) 999-9999"
                    value={phone}
                    disabled={false}
                    onChange={handlePhoneChange}
                    maskChar=" "
                    key="input mask"
                  >
                    {() => (
                      <TextField
                        variant="outlined"
                        className={classes.tourFormField}
                        name="Phone"
                        placeholder="Phone"
                        label="Phone"
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                            input: classes.input,
                          },
                        }}
                      />
                    )}
                  </InputMask>

                  <TextField
                    variant="outlined"
                    value={company}
                    onChange={handleCompanyChange}
                    className={classes.tourFormField}
                    name="Company"
                    label="Company"
                    placeholder="Company"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      },
                    }}
                  />

                  <FormControl component="fieldset" variant="standard">
                    {props.ptp ? (
                      <>
                        <FormLabel
                          component="legend"
                          className={`${classes.formHeader} ${classes.formLegend}`}
                          style={{
                            lineHeight: 1.3,
                          }}
                        >
                          Interested in a free event?
                        </FormLabel>
                      </>
                    ) : (
                      <>
                        <FormLabel
                          component="legend"
                          className={`${classes.formHeader} ${classes.formLegend}`}
                          style={{
                            lineHeight: 1.3,
                          }}
                        >
                          What can we help you with?
                        </FormLabel>
                      </>
                    )}

                    {helpWithError && (
                      <Typography className={classes.helpWithError}>
                        Please make a selection
                      </Typography>
                    )}

                    {props.ptp ? (
                      <FormGroup
                        style={{
                          position: "relative",
                          left: 15,
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={interestedInFreeEvent}
                              onChange={handleInterestedInFreeEventChange}
                              name="Interested in free event"
                              value="Interested in free event"
                              color="secondary"
                              className={classes.ptpCheckbox}
                            />
                          }
                          label="We would be interested in potentially letting Showboat use our brand logo in its marketing in exchange for a free event."
                          classes={{
                            label: `${classes.helpLabel} ${classes.helpLabelPTP}`,
                          }}
                        />
                      </FormGroup>
                    ) : (
                      <FormGroup
                        style={{
                          position: "relative",
                          left: 15,
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={tour}
                              onChange={handleTourChange}
                              name="events"
                              value="events"
                              color="secondary"
                            />
                          }
                          label="I'd like a tour"
                          classes={{
                            label: classes.helpLabel,
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={pricing}
                              onChange={handlePricingChange}
                              name="salesEnvironments"
                              value="salesEnvironments"
                              color="secondary"
                            />
                          }
                          label="I'd like pricing"
                          classes={{
                            label: classes.helpLabel,
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={moreInformation}
                              onChange={handleMoreInformationChange}
                              name="internalMeetings"
                              value="internalMeetings"
                              color="secondary"
                            />
                          }
                          label="I'd like more information"
                          classes={{
                            label: classes.helpLabel,
                          }}
                        />
                      </FormGroup>
                    )}
                  </FormControl>

                  <Button
                    className={classes.contactUsButton}
                    variant="contained"
                    color="primary"
                    onClick={handleFormSubmit}
                    disabled={submitLoadingSpinner}
                  >
                    Contact Us
                    {submitLoadingSpinner && (
                      <CircularProgress className={classes.loadingSpinner} />
                    )}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Paper>
  );
}
