import { makeStyles, createStyles } from '@material-ui/core/styles';

const EBookFormStyles = makeStyles((theme: any) =>
  createStyles({
    mainDisplayHolder: {
      background: "linear-gradient(180deg, black 50%, transparent 50%)",
      borderTop: "5px solid #FF0190",
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
      width: 1166,
      minHeight: 1400,
      height: "auto",
      textAlign: "left",
      marginBottom: -215,
      [theme.breakpoints.down("md")]: {
        width: 920,
        marginBottom: -190,
      },
      [theme.breakpoints.down("sm")]: {
        width: 720,
        marginTop: "3%",
        marginBottom: -230,
      },
      [theme.breakpoints.down("xs")]: {
        width: 600,
        marginTop: "4%",
        minHeight: 1300,
        marginBottom: -170,
      },
      "@media (max-width: 614.95px)": {
        width: "100%",
        borderRadius: 0,
        marginTop: "12%",
        marginBottom: -290,
      },
      "@media (max-width: 480px)": {
        marginBottom: -240,
      },
      "@media (max-width: 425px)": {
        marginTop: "18%",
        marginBottom: -620
      },
      "@media (max-width: 374.95px)": {
        marginTop: "20%",
        marginBottom: -120,
      },
      "@media (max-width: 349.95px)": {
        marginBottom: -140,
      },
    },
    mainDisplayHolderFormSubmitted: {
      minHeight: 1220,
      [theme.breakpoints.down("sm")]: {
        minHeight: 1066,
      },
      "@media (max-width: 614.95px)": {
        minHeight: 1000,
      },
    },
    showboatLogoHeaderText: {
      fontSize: 40,
      color: "#FFB700",
      position: "absolute",
      left: 80,
      bottom: -43,
      fontFamily: "Open Sans, sans-serif",
      "@media (max-width: 614.95px)": {
        fontSize: 34,
      },
      "@media (max-width: 480px)": {
        width: 250,
        bottom: -130,
        left: 0,
        position: "relative",
        display: "inline-block",
      },
    },
    tourHeader: {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "2.5rem",
      color: "white",
      display: "inline-block",
      width: 700,
      position: "relative",
      top: 163,
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.25rem",
        width: 600,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.8rem",
        width: 500,
      },
      ["@media (max-width: 614.95px)"]: {
        width: 400,
      },
      ["@media (max-width: 480px)"]: {
        fontSize: "1.7rem",
        width: 380,
      },
      ["@media (max-width: 425px)"]: {
        width: 330,
      },
      ["@media (max-width: 374.95px)"]: {
        width: 300,
      },
    },
    tourSubheader: {
      fontSize: "2.3rem",
      fontFamily: "Open Sans, sans serif",
      color: "white",
      position: "relative",
      fontWeight: "lighter",
      display: "inline-block",
      width: 920,
      top: 140,
      [theme.breakpoints.down("md")]: {
        fontSize: "2.25rem",
        width: 800,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
        width: 600,
      },
      [theme.breakpoints.down("xs")]: {
        width: 500,
        fontSize: "1.6rem",
      },
      "@media (max-width: 614.95px)": {
        fontSize: "1.4rem",
        width: 450,
      },
      ["@media (max-width: 480px)"]: {
        fontSize: "1.3rem",
        width: 400,
      },
      ["@media (max-width: 425px)"]: {
        width: 320,
      },
    },
    tourButton: {
      position: "relative",
      display: "block",
      padding: 0,
      width: 324,
      height: 86,
      top: 230,
      fontSize: 30,
      textTransform: "none",
      left: "50%",
      transform: "translateX(-50%)",
      [theme.breakpoints.down("xs")]: {
        width: 275,
        height: 75,
      },
      ["@media (max-width: 614.95px)"]: {
        fontSize: 26,
        width: 240,
        height: 65,
      },
    },
    formHeader: {
      fontSize: 24,
      fontFamily: "Open Sans, sans-serif",
      color: "#FFB700 !important",
      position: "relative",
    },
    formLegend: {
      marginBottom: 30,
    },
    tourFormField: {
      width: "100%",
    },
    requestTourButton: {
      textTransform: "none",
      display: "inline-block",
      width: 243,
      height: 48,
      fontSize: 20,
      zIndex: 9999,
      position: "relative",
      top: 35,
    },
    notchedOutline: {
      border: "2px solid white",
      color: "white !important",
      "&:focus": {
        border: "2px solid white !important",
      },
    },
    notchedOutlineError: {
      border: "2px solid red",
    },
    input: {
      color: "white !important",
    },
    inputRoot: {
      "&.MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "white",
        },
      },
    },
    inputFocused: {
      "& $notchedOutline": {
        borderColor: "white",
      },
    },
    gridThreeHeader: {
      fontSize: "1.5rem",
      fontFamily: "Open Sans, sans-serif",
      display: "inline-block",
      color: "#FFB700",
      position: "relative",
      top: 40,
      marginBottom: 70,
      fontWeight: 400,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.25rem",
      },
    },
    gridThreeRightHeader: {
      fontSize: "1.5rem",
      fontFamily: "Open Sans, sans-serif",
      display: "inline-block",
      color: "#FFB700",
      position: "relative",
      top: 40,
      marginBottom: 65,
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        marginBottom: 60,
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: 55,
        fontSize: "1.25rem",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: 52,
      },
    },
    gridThreeSubHeader: {
      fontSize: "1.875rem",
      position: "relative",
      fontFamily: "Open Sans, sans-serif",
      display: "inline-block",
      textAlign: "left",
      width: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: "1.75rem",
        width: 510,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
        width: 450,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.3rem",
        display: "block",
        marginBottom: 10,
        width: 325,
      },
      "@media (max-width: 614.95px)": {},
      "@media (max-width: 374.95px)": {
        width: 290,
      },
    },
    gridThreeSubheaderBottom: {
      ["@media (max-width: 614.95px)"]: {
        marginBottom: 50,
      },
    },
    gridThreeSubheaderRight: {
      marginBottom: 15,
    },
    formMessage: {
      fontSize: "2.3rem",
      fontFamily: "Open Sans, sans serif",
      color: "white",
      fontWeight: "lighter",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
      },
      "@media (max-width: 614.95px)": {
        fontSize: "1.5rem",
      },
    },
    showboatLogoHeaderTextFormMessage: {
      position: "relative",
      left: 0,
      bottom: -45,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
      },
      "@media (max-width: 614.95px)": {
        fontSize: "1.5rem",
      },
    },
    loadingSpinner: {
      width: 30,
      height: 30,
      position: "absolute",
      color: "grey",
      left: 515,
      bottom: -30,
      [theme.breakpoints.down("md")]: {
        left: 410,
      },
      [theme.breakpoints.down("sm")]: {
        left: 330,
      },
      [theme.breakpoints.down("xs")]: {
        left: 280,
      },
    },
    primaryInterestError: {
      position: "relative",
      color: "red",
      bottom: 9,
    },
    gridTwoHeader: {
      fontSize: "2.25rem",
      fontFamily: "Open Sans, sans-seif",
      position: "relative",
      marginBottom: 25,
      top: 25,
      [theme.breakpoints.down("md")]: {
        fontSize: "2rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.75rem",
      },
      "@media (max-width: 614.95px)": {},
    },
    gridTwoText: {
      lineHeight: 2,
      position: "relative",
      top: 25,
      marginBottom: 80,
      [theme.breakpoints.down("sm")]: {
        fontSize: ".9rem",
      },
      "@media (max-width: 614.95px)": {
        marginBottom: 80,
      },
    },
    checkbox: {
      position: "relative",
      left: 10,
      marginBottom: -30
    },
    checkboxLabel: {
      position: "relative",
      top: 2,
      left: 32,
    }
  })
);

export { EBookFormStyles }; 