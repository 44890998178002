import React, { ReactElement, useEffect } from 'react';
import ReactGA from "react-ga";

import PrivacyPolicy from '../../components/PrivacyPolicy/PrivacyPolicy';
import WideImage from '../../components/WideImage/WideImage';

import styles from './PrivacyPage.module.css';
import { PrivacyPageStyles } from './PrivacyPageStyles';

interface Props {
  
}

export default function PrivacyPage(props: Props): ReactElement {

  useEffect(() => {
    document.title = "Showboat Privacy Policy";

    //Trigger Google Analytics pageview
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])
  
  return (
    <div className={styles.privacyPageWrapper}>
      <WideImage />

      <PrivacyPolicy />
    </div>
  )
}
