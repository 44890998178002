import { makeStyles, createStyles } from '@material-ui/core/styles';

const MainLandingStyles = makeStyles((theme: any) =>
  createStyles({
    mainDisplayHolder: {
      /*  backgroundColor: theme.palette.background.paper, */
      background: "linear-gradient(180deg, black 50%, transparent 50%)",
      borderTop: "5px solid #FF0190",
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
      width: 1166,
      minHeight: 1800,
      height: "auto",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        width: 920,
      },
      [theme.breakpoints.down("sm")]: {
        width: 720,
        marginTop: "3%",
      },
      [theme.breakpoints.down("xs")]: {
        width: 600,
        marginTop: "4%",
      },
      "@media (max-width: 614.95px)": {
        width: "100%",
        borderRadius: 0,
        marginTop: "12%",
      },
      "@media (max-width: 425px)": {
        marginTop: "18%",
      },
      "@media (max-width: 374.95px)": {
        height: 2300,
        marginTop: "20%",
      },
    },
    mainHeader: {
      fontFamily: "Roboto, sans-serif",
      fontSize: "3.54rem",
      position: "relative",
      fontStyle: "italic",
      fontWeight: "bold",
      top: 73,
      display: "inline-block",
      marginBottom: 113,
      width: "97%",
      [theme.breakpoints.down("md")]: {
        fontSize: "2.85rem",
        width: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.7rem",
        marginBottom: 130,
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        top: 50,
        fontSize: "2.2rem",
        marginBottom: 100,
      },
      "@media (max-width: 614.95px)": {
        fontSize: "2rem",
        width: "100%",
      },
      ["@media (max-width: 480px)"]: {
        fontSize: "1.7rem",
      },
      "@media (max-width: 425px)": {
        width: "100%",
        fontSize: "1.75rem",
      },
    },
    mainHeaderPeopleTech: {
      marginBottom: 93,
      [theme.breakpoints.down("xs")]: {
        marginBottom: 75,
      },
    },
    peopleTechSubheader: {
      fontSize: "1.875rem",
      color: "white",
      fontFamily: "Roboto, sans-serif",
      marginBottom: 28,
      fontWeight: "normal",
      lineHeight: 1.3,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.6rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.45rem",
      },
      "@media (max-width: 424.95px)": {
        fontSize: "1.3rem"
      },
      
    },
    gridHeader: {
      fontSize: "2.75rem",
      position: "relative",
      fontStyle: "italic",
      fontWeight: "bold",
      marginBottom: 15,
      color: "#F84B59",
      [theme.breakpoints.down("md")]: {
        fontSize: "2.5rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.8rem",
      },
      ["@media (max-width: 480px)"]: {
        fontSize: "1.6rem",
      },
    },
    gridText: {
      fontSize: "",
      fontWeight: 400,
      lineHeight: 1.5,
      [theme.breakpoints.down("sm")]: {
        fontSize: ".9rem",
        marginBottom: 50,
      },
      "@media (max-width: 614.95px)": {
        marginBottom: 35,
      },
    },
    gridOneText: {
      color: "white",
      fontSize: "1.875rem",
      width: "101%",
      marginBottom: 35,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
      "@media (max-width: 614.95px)": {
        width: "107%",
        fontSize: "1.3rem",
      },
      ["@media (max-width: 480px)"]: {
        fontSize: "1.2rem",
      },
    },
    gridOneTextPTP: {
      fontSize: "1.375rem",
      ["@media (max-width: 480px)"]: {
        fontSize: "1.2rem",
      },
    },
    gridTextNetworking: {
      "@media (max-width: 614.95px)": {
        marginBottom: 25,
      },
      "@media (max-width: 424.95px)": {
        marginBottom: 35,
      },
    },
    gridTwoHeader: {
      fontSize: "1.8125rem",
      fontFamily: "Roboto, sans-serif",
      position: "relative",
      marginBottom: 15,
      top: 25,
      color: "#F84B59",
      fontStyle: "italic",
      fontWeight: "bold",
      [theme.breakpoints.down("md")]: {
        fontSize: "2rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.3rem",
      },
      "@media (max-width: 614.95px)": {},
    },
    gridTwoHeaderForm: {
      marginBottom: 25,
    },
    gridTwoText: {
      lineHeight: 1.7,
      position: "relative",
      top: 25,
      marginBottom: 30,
      fontSize: "1.375rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.1rem",
      },
      "@media (max-width: 614.95px)": {
        marginBottom: 50,
      },
    },
    tourHolder: {
      width: 1166,
      display: "inline-block",
      minHeight: 319,
      height: "auto",
      position: "relative",
      top: -20,
      backgroundColor: theme.palette.background.light,
      left: "50%",
      transform: "translateX(-50%)",
      marginTop: 30,
      marginBottom: 34,
      [theme.breakpoints.down("md")]: {
        width: 920,
      },
      [theme.breakpoints.down("sm")]: {
        width: 720,
        height: 275,
      },
      [theme.breakpoints.down("xs")]: {
        width: 600,
        minHeight: 175,
      },
      "@media (max-width: 614.95px)": {
        top: -90,
        minHeight: 150,
        width: "100vw",
        textAlign: "center",
      },
    },
    tourHeader: {
      fontFamily: "Open Sans, sans-serif",
      fontSize: 40,
      display: "inline-block",
      width: 650,
      position: "relative",
      top: 33,
      left: 85,
      [theme.breakpoints.down("md")]: {
        left: 66,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
        width: 550,
        left: 52,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.75rem",
        width: 500,
        left: 46,
      },
      "@media (max-width: 614.95px)": {
        width: 400,
        top: 40,
        left: 0,
      },
      "@media (max-width: 424.95px)": {
        fontSize: "1.5rem",
        width: 350,
      },
      "@media (max-width: 374.95px)": {
        width: 270,
      },
    },
    tourButton: {
      textTransform: "none",
      position: "absolute",
      left: 85,
      fontSize: 30,
      width: 324,
      height: 86,
      display: "inline-block",
      bottom: 50,
      [theme.breakpoints.down("md")]: {
        left: 66,
      },
      [theme.breakpoints.down("sm")]: {
        width: 250,
        height: 75,
        fontSize: 25,
        left: 52,
        bottom: 45,
      },
      [theme.breakpoints.down("xs")]: {
        left: 45,
        width: 200,
        height: 50,
        fontSize: 20,
      },
      "@media (max-width: 614.95px)": {
        display: "inline-block",
        position: "absolute",
        left: 0,
        bottom: -150,
      },
    },
    gridThreeHeader: {
      fontSize: "1.5rem",
      fontFamily: "Open Sans, sans-serif",
      display: "inline-block",
      color: "#FFB700",
      position: "relative",
      top: 40,
      marginBottom: 70,
      fontWeight: 400,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.25rem",
      },
    },
    gridThreeRightHeader: {
      fontSize: "1.5rem",
      fontFamily: "Open Sans, sans-serif",
      display: "inline-block",
      color: "#FFB700",
      position: "relative",
      top: 40,
      marginBottom: 65,
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        marginBottom: 60,
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: 55,
        fontSize: "1.25rem",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: 52,
      },
    },
    gridThreeSubHeader: {
      fontSize: "2.25rem",
      position: "relative",
      fontFamily: "Open Sans, sans-serif",
      display: "inline-block",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.75rem",
        width: 510,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
        width: 450,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.3rem",
        display: "block",
        marginBottom: 10,
        width: 325,
      },
      "@media (max-width: 614.95px)": {},
      "@media (max-width: 349.95px)": {
        width: 290,
      },
    },
    gridThreeSubheaderRight: {
      marginBottom: 15,
    },
    findOutMoreButton: {
      color: "white",
      backgroundColor: "#F84B59",
      width: 316,
      height: 54,
      display: "inline-block",
      fontSize: 24,
      fontWeight: "bold",
      fontStyle: "italic",
      position: "absolute",
      right: 0,
      "&:hover": {
        backgroundColor: "#a6323b",
      },
    },
    gridTextHoliday: {
      width: "50%",
      display: "inline-block",
    },
    arrowForwardIcon: {
      fontSize: 16,
      display: "inline",
      marginLeft: 10,
    },
    notchedOutline: {
      border: "2px solid white",
      color: "white !important",
      "&:focus": {
        border: "2px solid white !important",
      },
    },
    notchedOutlineError: {
      border: "2px solid red",
    },
    input: {
      color: "white !important",
    },
    inputRoot: {
      "&.MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "white",
        },
      },
    },
    inputFocused: {
      "& $notchedOutline": {
        borderColor: "white",
      },
    },
    tourFormField: {
      width: "100%",
    },
    formHeader: {
      fontSize: 24,
      fontFamily: "Open Sans, sans-serif",
      color: "#FFB700 !important",
      position: "relative",
    },
    formLegend: {
      marginBottom: 30,
      fontWeight: "bold",
      fontSize: "1.5rem",
      color: "#94F84B !important",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.2rem",
      },
    },
    helpWithError: {
      position: "relative",
      color: "red",
      bottom: 9,
    },
    helpLabel: {
      fontWeight: "bold",
    },
    helpLabelPTP: {
      display: "inline-block",
      width: 220,
      position: "relative",
      left: 25,
      bottom: 6,
      [theme.breakpoints.down("xs")]: {
        width: 190,
      },
      "@media (max-width: 614.95px)": {
        width: "78%",
      },
    },
    ptpCheckbox: {
      position: "relative",
      bottom: 54,
      "@media (max-width: 614.95px)": {
        bottom: 29,
      },
    },
    contactUsButton: {
      textTransform: "none",
      fontSize: 30,
      fontWeight: "bold",
      padding: 0,
      width: "100%",
      height: 69,
      borderRadius: 5,
      border: "4px solid #94F84B",
      backgroundColor: "#0F2400",
      boxShadow: "none",
      position: "relative",
      bottom: 5,
      "&:hover": {
        backgroundColor: "#94F84B",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 25,
      },
    },
    successMessage: {
      position: "relative",
      color: "white",
      fontSize: "1.813rem",
      fontFamily: "Roboto, sans-serif",
      fontWeight: "bold",
      fontStyle: "italic",
      left: 75,
      bottom: 20,
    },
    successIcon: {
      color: "#94F84B",
      fontSize: 50,
      position: "relative",
      top: 30,
    },
    successText: {
      fontSize: "1.375rem",
      position: "relative",
      width: "90%",
      "@media (max-width: 614.95px)": {
        fontSize: "1.2rem",
      },
    },
    loadingSpinner: {
      color: "lightgrey",
      width: "30px !important",
      height: "30px !important",
      position: "absolute",
      right: 85,
      [theme.breakpoints.down("md")]: {
        right: 50,
      },
      [theme.breakpoints.down("sm")]: {
        right: 20,
      },
      [theme.breakpoints.down("xs")]: {
        right: 10,
      },
      "@media (max-width: 614.95px)": {
        right: 110,
      },
      "@media (max-width: 480px)": {
        right: 80,
      },
      ["@media (max-width: 425px)"]: {
        right: 50,
      },
      "@media (max-width: 374.95px)": {
        right: 30,
      },
    },
    formError: {
      color: "red",
      fontSize: 16,
      marginTop: -10,
      marginBottom: -10,
    },
  })
);

export { MainLandingStyles };