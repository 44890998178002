import { makeStyles, createStyles } from '@material-ui/core/styles';

const BookingsOverviewStyles = makeStyles((theme: any) => createStyles({
  gridHeader: {
    color: "#00B6FF",
    fontFamily: "Open Sans, sans-serif",
    position: "relative",
    fontSize: "2.25rem",
    fontWeight: 400,
    '@media(max-width: 850px)': {
      fontSize: "1.8rem"
    },
    '@media(max-width: 500px)': {
      fontSize: "1.7rem"
    }
  },
  divider: {
    color: "white",
    backgroundColor: "white",
    opacity: 1,
    height: 1,
    position: "relative",
  },
  bookingsOverviewHeader: {
    top: 150,
  },
  bookingsOverviewDivider: {
    top: 175,
    '@media screen and (max-width: 675px) and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      top: 176,
    }
  },
  bookingsOverviewText: {
    fontSize: "1rem",
    color: "white",
    position: "relative",
    top: 45,
    display: "inline-block",
    width: "95%",
    '@media (max-width: 675px)': {
      width: "100%"
    },
    '@media(max-width: 500px)': {
      top: 35,
    }
  },
  bookingsOverviewListText: {
    color: "white",
    position:"relative",
    fontSize: "1rem",
  },
  bookingsOverviewSubheader: {
    fontSize: "1.25rem",
    color: "#EEAA00",
    position: "relative",
    top: 45,
    '@media(max-width: 500px)': {
      fontSize: "1rem",
      top: 35,
    }
  },
  bookingsOverviewSubheaderCalendarEntry: {
    top: 75,
  },
  bookingsOverviewSubheaderIntake: {
    top: 15,
  },
  bookingsOverviewTextIntake: {
    top: 30,
  },
  bookingsOverviewSubheaderSignUp: {
    marginBottom: 20,
  },
  loginProjectsSubheader: {
    color: "#FFB700",
    position: "relative",
    top: 0,
    left: 0,
    fontSize: "1.5rem",
    '@media(max-width: 850px)': {
      fontSize: "1.3rem"
    }
  },
  bookingsOverviewSubheaderRun: {
    top: 40,
    '@media(max-width: 500px)': {
      top: 30,
    }
  },
  bookingsOverviewNoteText: {
    fontSize: "1rem",
    color: "#FF0092",
    position: "relative",
    display: "inline-block",
    width: 445,
    top: 35,
    '@media(max-width: 1080px)': {
      width: 400
    },
    '@media(max-width: 850px)': {
      width: 325
    },
    '@media(max-width: 675px)': {
      width: 480
    },
    '@media(max-width: 500px)': {
      top: 10,
      width: 375
    },
    '@media(max-width: 375px)': {
      width: 300,
      top: 30,
    }
    
  }
})
)

export { BookingsOverviewStyles };