import { Divider, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

import styles from './Calendar.module.css';
import { CalendarStyles } from './CalendarStyles';

interface Props {
  
}

export default function Calendar(props: Props): ReactElement {

  const classes = CalendarStyles();
  
  return (
    <div>
      <Typography variant="h2" className={`${classes.gridHeader} ${classes.calendarHeader}`}>
        Calendar
      </Typography>
      
      <Divider className={`${classes.divider} ${classes.calendarDivider}`}></Divider>

      <div className={styles.calendarGrid}>

        <div className={styles.calendarGridItem}>
          <Typography variant="h3" className={classes.loginProjectsSubheader}>
            Calendar Tab
          </Typography>

          <img className={`image ${styles.calendarImage}`} alt="Calendar tab" src="assets/images/calendar-1.png" />

          <Typography variant="body1" className={classes.calendarText}>
            You can create a calendar for your future bookings that may start at
            certain times and days and allow attendees to choose which event they want
            to attend.
          </Typography>
          
        </div>

        <div className={styles.calendarGridItem}>
          <Typography variant="h3" className={classes.loginProjectsSubheader}>
            Creating a Calendar
          </Typography>
          
          <img className={`image ${styles.calendarImage}`} alt="Calendar tab" src="assets/images/calendar-2.png" />

          <ul className={`list ${styles.calendarList}`}>

            <li className={`listItem ${styles.calenderListItem}`}>
              <Typography variant="body1" className={classes.calendarListText}>
                Click "Add and Edit" under the Calendar tab
              </Typography>
            </li>

            <li className={`listItem ${styles.calenderListItem}`}>
              <Typography variant="body1" className={classes.calendarListText}>
                Enter calendar name
              </Typography>
            </li>
            
            <li className={`listItem ${styles.calenderListItem}`}>
              <Typography variant="body1" className={classes.calendarListText}>
                Click "Save"
              </Typography>
            </li>

          </ul>

          
        </div>

        <div className={styles.calendarGridItem}>
          <img className={`image ${styles.calendarImage}`} alt="Calendar tab" src="assets/images/calendar-3.png" />

          <ul className={`list ${styles.calendarList}`}>
            <li className={`listItem ${styles.calenderListItem}`}>
              <Typography variant="body1" className={classes.calendarListText}>
                <b>Copy button:</b> This will automatically copy the link to your clipboard so that it can 
                be pasted into emails, group chats, etc
              </Typography>
              
              
            </li>

            <li className={`listItem ${styles.calenderListItem}`}>
              <Typography variant="body1" className={classes.calendarListText}>
                <b>Regenerate:</b> This button generates a completely new link for the calendar
              </Typography>
            </li>

            <li className={`listItem ${styles.calenderListItem}`}>
              <Typography variant="body1" className={classes.calendarListText}>
                Once your calendar has been created, you can assign specific bookings to the calendar
                by switching the "Calendar Entry" toggle ON & selecting the calendar you'd like to assign to
              </Typography>
            </li>

            <li className={`listItem ${styles.calenderListItem}`}>
              <Typography variant="body1" className={classes.calendarListText}>
                You may wish to name your bookings the same as your calendar name, as booking names
                are not shown on the calendar
              </Typography>
            </li>

          </ul>

          
        </div>

      </div>
    </div>
  )
}
