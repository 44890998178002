import { makeStyles, createStyles } from '@material-ui/core/styles';

const ProducerPortalAssetListStyles = makeStyles((theme: any) => createStyles({
  producerPortalAssetListHolder: {
    width: 996,
    minHeight: 3400,
    height: "auto",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    top: 80,
    textAlign: "left",
    '@supports ( -moz-appearance:none )': {
      minHeight: 3400
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      minHeight: 3400
    },
    '@media(max-width: 1080px)': {
      width: 800,
      minHeight: 3200,
      '@supports ( -moz-appearance:none )': {
        minHeight: 3200
      },
    },
    '@media screen and (max-width: 1080px) and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      minHeight: 3200
    },
    '@media(max-width: 850px)': {
      width: 650,
      minHeight: 3000
    },
    '@media(max-width: 675px)': {
      width: 475,
      minHeight: 4800,
    },
    '@media(max-width: 500px)': {
      width: 360,
      minHeight: 4400

    },
    '@media (max-width: 375px)': {
      width: 300,
      minHeight: 4200
    }
  },
  producerPortalAssetListHeader: {
    fontSize: "2.5rem",
    fontWeight: 400,
    color: "white",
    position: "relative",
    fontFamily: "Open Sans, sans-serif",
    top: 30,
    '@media(max-width: 850px)': {
      fontSize: "2rem"
    },
    '@media(max-width: 500px)': {
      fontSize: "1.8rem",
      lineHeight: 1.4
    }
  }
})
)

export { ProducerPortalAssetListStyles };