import { Divider, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import DownloadIcon from '@material-ui/icons/GetApp';
import UploadIcon from '@material-ui/icons/CloudUpload';
import AddIcon from '@material-ui/icons/AddBox';

import "../../../App.css";
import styles from './GuestsAndAttendees.module.css';
import { GuestsAndAttendeesStyles } from './GuestsAndAttendeesStyles';

interface Props {
  
}

export default function GuestsAndAttendees(props: Props): ReactElement {

  const classes = GuestsAndAttendeesStyles();
  
  return (
    <React.Fragment>
      <Typography variant="h2" className={`${classes.gridHeader} ${classes.guestsAttendeesHeader}`}>
        Guests and Attendees
      </Typography>

      <Divider className={`${classes.divider} ${classes.guestsAttendeesDivider}`}></Divider>

      <div className={styles.guestsAndAttendeesGrid}>

        <div className={styles.guestsAndAttendeesGridItem}>
          <Typography variant="h3" className={classes.guestsAndAttendeesSubheader}>
            Attendees
          </Typography>

          <img className={`image ${styles.loginProjectsImage}`}  alt="Attendees tab" src="assets/images/attendees-1.png" />

          <Typography variant="h4" className={classes.guestsAndAttendeesListHeader}>
            Attendee Information
          </Typography>

          <Typography variant="body1" className={classes.guestsAndAttendeesText}>
            Use the Attendee section to view the current attendees in the booking 
            or final attendance once the event ends
          </Typography>

          <ul className={`list ${styles.guestsAndAttendeesList}`}>

            <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
              <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                <b>Brand:</b> Green dot indicates which attendees are brand representatives
              </Typography>
            </li>

            <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
              <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                <b>Presenter:</b> Green dot indicates which attendees are presenters in the event
              </Typography>
            </li>

            <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
              <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                <b>In-room:</b> Green dot indicates which attendees are in the event
              </Typography>
            </li>

          </ul>

        </div>

        <div className={`${styles.guestsAndAttendeesGridItem} ${styles.guestsAndAttendeesGridItemDetails}`}>

          <img className={`image ${styles.guestsAndAttendeesImage}`} alt="Attendee details popup" src="assets/images/attendees-2.png" />

          <Typography variant="h4" className={`${classes.guestsAndAttendeesListHeader} ${classes.guestsAndAttendeesDetailsHeader}`}>
            Details
          </Typography>

          <Typography variant="body1" className={`${classes.guestsAndAttendeesText} ${classes.guestsAndAttendeesTextDetails}`}>
            Clicking the "Details" button next to a guest's name will prompt a screen giving
            details of guests such as:
          </Typography>

          <ul className={`list ${styles.guestsAndAttendeesListDetails}`}>

            <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
              <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                Name
              </Typography>
            </li>

            <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
              <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                E-mail
              </Typography>
            </li>

            <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
              <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                Phone Number
              </Typography>
            </li>

            <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
              <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                Address
              </Typography>
            </li>

          </ul>

          <Typography variant="body1" className={`${classes.guestsAndAttendeesText} ${classes.guestsAndAttendeesTextDetailsTwo}`}>
            You will also be able to see the times an attendee entered and left the space
          </Typography>

          <ul className={`list ${styles.guestsAndAttendeesListDetailsTwo}`}>
            <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
              <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                If an attendee changes their intake information but uses the same link to enter the 
                producer can see that as well
              </Typography>
            </li>
          </ul>
          
        </div>

        <div className={styles.guestsAndAttendeesGridItem}>
          <Typography variant="h3" className={classes.guestsAndAttendeesSubheader}>
            Guest List
          </Typography>

          <img className={`image ${styles.loginProjectsImage}`} alt="Guest list section" src="assets/images/attendees-3.png" />

          <Typography variant="h4" className={classes.guestsAndAttendeesListHeader}>
            Guest List Section 
          </Typography>

          <Typography variant="body1" className={`${classes.guestsAndAttendeesText} ${classes.guestsAndAttendeesTextGuestList}`}>
            You can find all the guests who signed up for your event here. Attendees are added 
            to the Guest List automatically when they register through the Sign-Up URL. Producers can also
            manually add guests to the Guest List. People in the Guest List can be communicated with using
            the Showboat email system, and can be assigned as being a brand ambassador.
          </Typography>

          <ul className={`list ${styles.guestsAndAttendeesList}`}>

            <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
              <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                <b>Name:</b> Name of registered guest (Last, First)
              </Typography>
            </li>

            <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
              <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                <b>Brand:</b> Designates a guest as belonging to the brand (or group) and will have a green dot
              </Typography>
            </li>

            <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
              <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                <b>Download:</b> You can download a guest list spreadsheet in CSV format
              </Typography>
            </li>

          </ul>

        </div>

        <div className={`${styles.guestsAndAttendeesGridItem} ${styles.guestsAndAttendeesGridItemDownloadGuestList}`}>

          <div className={`${styles.guestListDetailsHolder} `}>
            <Typography variant="h4" className={classes.guestListDetailsHeader}>
              Downloading Guest List 
            </Typography>

            <ul className="list">

              <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  <DownloadIcon className={classes.listIcon} />
                  Click the download button to get the guest list file
                </Typography>
              </li>

            </ul>

            <Typography variant="h4" className={classes.guestListDetailsHeader}>
              Upload Guest List
            </Typography>

            <ul className={`list ${styles.guestsAndAttendeesListUpload}`}>

              <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  <b>Upload:</b> You can upload an already-created guest list from your computer as well
                </Typography>
              </li>

              <li className={`listItem ${styles.listItemIcon}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  <UploadIcon className={classes.listIcon} />
                  Click the upload button and choose your guest CSV file
                </Typography>
              </li>

              <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  <b>Note:</b> The structure of the CSV is critical to data integrity. An example of a CSV
                  file is available by pressing the upload button and then clicking on "Download Attendee Template (.csv)"
                </Typography>
              </li>

              <ul className={styles.loginProjectsSublist}>

                <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                  <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                    Data parsing is checked on upload
                  </Typography>
                </li>

              </ul>

              <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  <b>Add:</b> Manually add guests by entering their details
                </Typography>
              </li>

              <li className={`listItem ${styles.listItemIcon}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  <AddIcon className={classes.listIcon} />
                  Click the add button to manually enter a guest's information
                </Typography>
              </li>

              <ul className={`${styles.guestsAndAttendeesSublistAdd}`}>

                <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                  <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                    Showboat does not automatically send email invites to guests who are added manually
                  </Typography>
                </li>

                <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                  <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                    To send an invite, save the guest information, click "Edit" to view the guest info again,
                    and click "Send Invite"
                  </Typography>
                </li>

              </ul>

            </ul>

          </div>

        </div>

        <div className={styles.guestsAndAttendeesGridItem}>
          <Typography variant="h3" className={classes.guestsAndAttendeesSubheader}>
            Edit Guest Information
          </Typography>

          <img className={`image ${styles.loginProjectsImage}`} alt="Edit guest information popup" src="assets/images/attendees-4.png" /> 

          <Typography variant="h4" className={classes.guestsAndAttendeesListHeader}>
            Edit Guest
          </Typography>
          
          <Typography variant="body1" className={`${classes.guestsAndAttendeesText} ${classes.guestsAndAttendeesTextGuestList}`}>
            Edit and access personalized guest settings and functionality:
          </Typography>

          <ul className={`list ${styles.guestsAndAttendeesList}`}>
            <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
              <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                Click the "edit" button which will prompt a guest information box that can be edited. When you are finished, click "Save"
              </Typography>
            </li>

            <ul className={styles.loginProjectsSublist}>

              <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  <b>Unique Event Link:</b> This is the guest's entrance link (each guest has one)
                </Typography>
              </li>

              <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  <b>Copy Link:</b> Copies link to clipboard
                </Typography>
              </li>

              <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  <b>Send Invite</b> Sends guest invite link
                </Typography>
              </li>

              <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  <b>Regenerate:</b> Generates new entrance link for guests
                </Typography>
              </li>
              
              <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  <b>Registration Information:</b> Edit guest's information if necessary
                </Typography>
              </li>

              <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  <b>Brand Toggle:</b> Assign a guest to be an event/brand ambassador
                </Typography>
              </li>

              <li className={`listItem ${styles.guestsAndAttendeesListItem} ${styles.guestsAndAttendeesListItemBottom}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  <b>Early Access Toggle:</b> Allow certain guests early access to the room before the event begins
                  by switching the toggle to on
                </Typography>
              </li>
            </ul>
          </ul>

        </div>

        <div className={styles.guestsAndAttendeesGridItem}>

          <Typography variant="h3" className={classes.guestsAndAttendeesSubheader}>
            Registration
          </Typography>

          <img className={`image ${styles.loginProjectsImage}`} alt="Edit guest information popup" src="assets/images/attendees-5.png" /> 

          <Typography variant="h4" className={classes.guestsAndAttendeesListHeader}>
            Registration Features
          </Typography>

          <Typography variant="body1" className={`${classes.guestsAndAttendeesText} ${classes.guestsAndAttendeesTextGuestList}`}>
            Manage the fields displayed on the Registration Page, and Event Intake pages
          </Typography>

          <ul className={`list ${styles.guestsAndAttendeesList}`}>

            <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
              <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                <b>Basic Information</b>
              </Typography>
            </li>

            <ul className={styles.loginProjectsSublist}>

              <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  First, Last Name
                </Typography>
              </li>

              <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  E-mail, Company
                </Typography>
              </li>

              <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  Phone Number
                </Typography>
              </li>

            </ul>

            <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
              <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                <b>Mailing Address</b>
              </Typography>
            </li>

            <ul className={styles.loginProjectsSublist}>
              <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  Choose from US or Generic International Fields
                </Typography>
              </li>
            </ul>

            <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
              <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                <b>Required Checkmark</b>
              </Typography>
            </li>

            <ul className={styles.loginProjectsSublist}>

              <li className={`listItem ${styles.guestsAndAttendeesListItem}`}>
                <Typography variant="body1" className={classes.guestsAndAttendeesListText}>
                  If checked, the field will be required during registration and intake
                </Typography>
              </li>

            </ul>

          </ul>

        </div>

      </div>

    </React.Fragment>
  )
}
