import { Divider, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react'

import styles from './EmailAssets.module.css';
import { EmailAssetStyles } from './EmailAssetsStyles';

interface Props {
  
}

export default function EmailAssets(props: Props): ReactElement {

  const classes = EmailAssetStyles();
  
  return (
    <React.Fragment>
      <Typography variant="h2" className={`${classes.gridHeader}`}>
        Email
      </Typography>

      <Divider className={`${classes.divider}`}></Divider>

      <div className={styles.emailGrid}>
        
        <div className={styles.emailGridItem}>
          <Typography variant="h3" className={classes.emailSubHeader}>
            Header Image
          </Typography>

          <img className={styles.emailImage} alt="Email header image asset" src="assets/images/emailAssets-1.png" />
          
          <div className={styles.emailContentHolder}>
            <ul className={styles.emailList}>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.emailListText}>
                  Banner for all email communications
                </Typography>
              </li>
              <li className={`listItem`}>
                <Typography variant="body1" className={classes.emailListText}>
                  Format
                </Typography>
              </li>
              <ul>
                <li className={`listItem`}>
                  <Typography variant="body1" className={classes.emailListText}>
                    .PNG
                  </Typography>
                </li>
                <li className={`listItem`}>
                  <Typography variant="body1" className={classes.emailListText}>
                    600px x 160px
                  </Typography>
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}
