import { Divider, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import AddIcon from '@material-ui/icons/AddBox';

import "../../../App.css";
import styles from './Email.module.css';
import { EmailStyles } from './EmailStyles';

interface Props {
  
}

export default function Email(props: Props): ReactElement {

  const classes = EmailStyles();
  
  return (
    <React.Fragment>
      <Typography variant="h2" className={`${classes.gridHeader} ${classes.gridHeaderEmail}`}>
        Email
      </Typography>

      <Divider className={`${classes.divider} ${classes.emailDivider}`}></Divider>

      <div className={styles.emailGrid}>

        <div className={styles.emailGridItem}>

          <Typography variant="h3" className={classes.emailSubheader}>
            Email
          </Typography>

          <img className={`image ${styles.emailsImage} ${styles.emailsImageTab}`} alt="Email tab" src="assets/images/email-1.png" />

          <Typography variant="h4" className={classes.emailListHeader}>
            Email Section
          </Typography>

          <Typography variant="body1" className={classes.emailText}>
            The Email section allows you to manage which guests you'd like to send certain links to
          </Typography>
        </div>

        <div className={styles.emailGridItem}>
          <Typography variant="h3" className={classes.emailListHeader}>
            System Emails
          </Typography>

          <ul className={`list ${styles.emailList}`}>
            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Presenter Invite Link:</b>
              </Typography>
            </li>

            <ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Sends invite link to the presenter
                </Typography>
              </li>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Not automatically sent when a presenter is added to a booking (in the Presenters tab)
                </Typography>
              </li>

            </ul>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Registration Confirmation</b>
              </Typography>
            </li>

            <ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Sent automatically when a guest completes registration
                </Typography>
              </li>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Not automatically sent if a guest is added manually via the producer portal or via a CSV upload
                </Typography>
              </li>

            </ul>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Both Contain</b>
              </Typography>
            </li>

            <ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Contains a link to the event
                </Typography>
              </li>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Contains a link to add event to their calendar
                </Typography>
              </li>

            </ul>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Attendee Invite Link</b>
              </Typography>
            </li>

            <ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  This is used for sending invitations to multiple attendees for the event
                </Typography>
              </li>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Is useful for when the intake link gets regenerated and needs to be sent to the attendees list again
                </Typography>
              </li>

            </ul>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Presenter/Registration/Attendee Emails</b>
              </Typography>
            </li>

            <ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  These emails use the standard Showboat template
                </Typography>
              </li>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Email copy can be edited
                </Typography>
              </li>

            </ul>
          </ul>

        </div>

        <div className={styles.emailGridItem}>

          <Typography variant="h3" className={classes.emailSubheader}>
            Custom Email Template
          </Typography>

          <img className={`image ${styles.emailsImage}`} alt="Custom email popup" src="assets/images/email-2.png" />

          <Typography variant="h4" className={classes.emailListHeader}>
            Email Section
          </Typography>
          
          <ul className={`list ${styles.emailList}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Custom Email</b>
              </Typography>
            </li>

            <ul>

              <li className={`listItem`}>
                <Typography variant="body1" style={{ color: "#EEAA00" }} >
                  <AddIcon className={classes.addIcon} />
                  Click the add button to create a new email template
                </Typography>
              </li>

            </ul>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>HTML Indication</b>
              </Typography>
            </li>

            <ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Indicates if the template uses the Showboat standard template or a custom HTML template
                  provided by the end user
                </Typography>
              </li>

            </ul>

          </ul>

        </div>

        <div className={styles.emailGridItem}>

          <Typography variant="h3" className={classes.emailSubheader}>
            Manage Emails
          </Typography>

          <img className={`image ${styles.emailsImage}`} alt="Manage email popup" src="assets/images/email-3.png" />

          <Typography variant="h4" className={classes.emailListHeader}>
            Email Section
          </Typography>

          <ul className={`list ${styles.emailList}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>From Name</b>
              </Typography>
            </li>

            <ul>
              <li className={`listItem`}>
                <Typography variant="body1">
                  This is the name that shows "who" the email is coming from when sending guests
                  invitations and such. Can be any person's name, a company name, or a group's name
                </Typography>
              </li>
            </ul>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Reply-To Address</b>
              </Typography>
            </li>

            <ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  This is an email address that guests can reply back to if they need to
                </Typography>
              </li>

               <li className={`listItem`}>
                <Typography variant="body1">
                  By default, Showboat emails are sent from an account that is NOT monitored. Change the Reply To address to
                  redirect mail to an account that IS monitored (if desired)
                </Typography>
              </li>

            </ul>
            
            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Email Subject</b>
              </Typography>
            </li>

            <ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  This will be the subject line of the email when guests receive an invitation, sign-up link, etc.
                </Typography>
              </li>

            </ul>

          </ul>

        </div>

        <div className={styles.emailGridItem}>

          <Typography variant="h3" className={classes.emailSubheader}>
            Basic Email Templates
          </Typography>

          <img className={`image ${styles.emailsImage} ${styles.imageEmailTemplate}`} alt="Showboat basic invite email" src="assets/images/email-4.png" />

        </div>

        <div className={styles.emailGridItem}>
          <Typography variant="h4" className={classes.emailListHeader}>
            Email Template
          </Typography>

          <Typography variant="body1" className={classes.emailText}>
            The Showboat standard template contains a simple configurable graphic at the top of each email.
            It is set in Branding &gt; Email &gt; Header Image
          </Typography>

          <ul className={`list ${styles.emailList}`}>
            
            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Email Body Copy</b>
              </Typography>
            </li>

            <ul>
              <li className={`listItem`}>
                <Typography variant="body1">
                  Enter your email text in the main text box on the right of the Manage Template window
                </Typography>
              </li>
            </ul>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Insert Template Element</b>
              </Typography>
            </li>

            <ul>
              <li className={`listItem`}>
                <Typography variant="body1">
                  Showboat allows access to the booking and registration data when building emails and also
                  a few levels of content formatting through the Insert Template Element drop down
                </Typography>
              </li>


              <li className={`listItem`}>
                <Typography variant="body1">
                  Dynamic Data presets include:
                </Typography>
              </li>

              <ul>

                <li className={`listItem`}>
                  <Typography variant="body1">
                    First Name
                  </Typography>
                </li>

                <li className={`listItem`}>
                  <Typography variant="body1">
                    Add to Calendar Link
                  </Typography>
                </li>

                <li className={`listItem`}>
                  <Typography variant="body1">
                    Event Details
                  </Typography>
                </li>

              </ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Formatting Options:
                </Typography>
              </li>

              <ul>

                <li className={`listItem`}>
                  <Typography variant="body1">
                    Main Header
                  </Typography>
                </li>

                <li className={`listItem`}>
                  <Typography variant="body1">
                    Section Header
                  </Typography>
                </li>

              </ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Adding Dynamic Data/Formatting to an Email
                </Typography>
              </li>

              <ul>

                <li className={`listItem`}>
                  <Typography variant="body1">
                    Select a preset such as "Insert Section Header"
                  </Typography>
                </li>

                <li className={`listItem`}>
                  <Typography variant="body1">
                    Click on where you'd like it to be in the text box and it will 
                    automatically place the preset in that spot
                  </Typography>
                </li>

              </ul>
              
              <li className={`listItem`}>
                <Typography variant="body1">
                  <b>HTML Toggle</b>
                </Typography>
              </li>

              <ul>

                <li className={`listItem`}>
                  <Typography variant="body1">
                    Turn on the HTML toggle to use a custom (non-Showboat) email template
                  </Typography>
                </li>

              </ul>

            </ul>

          </ul>

        </div>

        <div className={styles.emailGridItem}>

          <Typography variant="h3" className={classes.emailSubheader}>
            Send Emails      
          </Typography>

          <img alt="Send email popup" className={`image ${styles.emailsImage}`} src="assets/images/email-5.png" />

          <Typography variant="h4" className={classes.emailListHeader}>
            Sending to Guests and Attendees
          </Typography>

          <Typography variant="body1" className={classes.emailText}>
            You can send different links to multiple guests. To send guests an email for an Attendee Invite,
            Registration Confirmation, and Presenter Invite Links
          </Typography>

          <ul className={`list ${styles.emailList}`}>
            <li className={`listItem`}>
              <Typography variant="body1">
                Click the "Send" button for the type of link you want to send (ex: Attendee Invite)
              </Typography>
            </li>
          </ul>

          <Typography variant="body1" className={`${classes.emailText} ${classes.emailTextSendEmail}`}>
            This will prompt you with an attendees list box. You have the option to individually check attendees or you can:
          </Typography>

          <ul className={`list ${styles.emailListSendEmail}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Select All:</b> Selects all guest list attendees
              </Typography>
            </li>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Select All Brand:</b> Selects all guest list attendees that are brand representatives
              </Typography>
            </li>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Select All Sent:</b> Selects all guest list attendees that have been sent an email for a specific link before
              </Typography>
            </li>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Select All Unsent:</b> Selects all guest list attendees that have not received an email for a specific link
              </Typography>
            </li>

          </ul>

          
        </div>

      </div>

    </React.Fragment>
  )
}
