import React, { ReactElement, useEffect } from "react";
import ReactGA from "react-ga";
import TermsOfService from "../../components/TermsOfService/TermsOfService";
import WideImage from "../../components/WideImage/WideImage";
import styles from "./TermsOfServicePage.module.css";

interface Props {}

export default function TermsOfServicePage({}: Props): ReactElement {
  useEffect(() => {
    document.title = "Showboat Terms of Use";

    //Trigger Google Analytics pageview
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return <div className={styles.termsOfServiceWrapper}>
    <WideImage />

    <TermsOfService />
  </div>;
}
