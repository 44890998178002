import { makeStyles, createStyles } from '@material-ui/core/styles';

const MainLandingStyles = makeStyles((theme: any) =>
  createStyles({
    mainDisplayHolder: {
      /*  backgroundColor: theme.palette.background.paper, */
      background: "linear-gradient(180deg, black 50%, transparent 50%)",
      borderTop: "5px solid #FF0190",
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
      width: 1166,
      minHeight: 1800,
      height: "auto",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        width: 920,
      },
      [theme.breakpoints.down("sm")]: {
        width: 720,
        marginTop: "3%",
      },
      [theme.breakpoints.down("xs")]: {
        width: 600,
        marginTop: "4%",
      },
      "@media (max-width: 614.95px)": {
        width: "100%",
        borderRadius: 0,
        marginTop: "12%",
      },
      "@media (max-width: 425px)": {
        marginTop: "18%",
      },
      "@media (max-width: 374.95px)": {
        height: 2300,
        marginTop: "20%",
      },
    },
    mainHeader: {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "2.5rem",
      position: "relative",
      top: 73,
      display: "inline-block",
      width: 648,
      marginBottom: 113,
      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
        width: 500,
        marginBottom: 130,
      },
      [theme.breakpoints.down("xs")]: {
        top: 50,
        marginBottom: 100,
      },
      "@media (max-width: 614.95px)": {
        fontSize: "2rem",
        width: "100%",
      },
      "@media (max-width: 425px)": {
        width: "100%",
        fontSize: "1.75rem",
      },
    },
    gridHeader: {
      fontSize: "1.5rem",
      position: "relative",
      marginBottom: 15,
      fontWeight: 400,
      color: "#FFB700",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.1rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.98rem",
      },
      "@media (max-width: 614.95px)": {
        fontSize: "1.2rem",
      },
    },
    gridText: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.9,
      marginBottom: 50,
      [theme.breakpoints.down("sm")]: {
        fontSize: ".9rem",
        marginBottom: 120,
      },
      "@media (max-width: 614.95px)": {
        marginBottom: 35,
      },
    },
    gridTextNetworking: {
      "@media (max-width: 614.95px)": {
        marginBottom: 25,
      },
      "@media (max-width: 424.95px)": {
        marginBottom: 35,
      },
    },
    gridTwoHeader: {
      fontSize: "2.25rem",
      fontFamily: "Open Sans, sans-seif",
      position: "relative",
      marginBottom: 25,
      top: 25,
      [theme.breakpoints.down("md")]: {
        fontSize: "2rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.75rem",
      },
      "@media (max-width: 614.95px)": {},
    },
    gridTwoText: {
      lineHeight: 2,
      position: "relative",
      top: 25,
      marginBottom: 80,
      [theme.breakpoints.down("sm")]: {
        fontSize: ".9rem",
      },
      "@media (max-width: 614.95px)": {
        marginBottom: 80,
      },
    },
    tourHolder: {
      width: 1166,
      display: "inline-block",
      minHeight: 319,
      height: "auto",
      position: "relative",
      top: -20,
      backgroundColor: theme.palette.background.light,
      left: "50%",
      transform: "translateX(-50%)",
      marginTop: 30,
      marginBottom: 34,
      [theme.breakpoints.down("md")]: {
        width: 920,
      },
      [theme.breakpoints.down("sm")]: {
        width: 720,
        height: 275,
      },
      [theme.breakpoints.down("xs")]: {
        width: 600,
        minHeight: 175,
      },
      "@media (max-width: 614.95px)": {
        top: -90,
        minHeight: 150,
        width: "100vw",
        textAlign: "center",
      },
    },
    ebookHolder: {
      backgroundColor: "#2E0046",
      "@media (max-width: 520px)": {
        minHeight: 500,
      },
      "@media (max-width: 480px)": {
        top: 70,
        marginBottom: 230,
      },
      "@media (max-width: 425px)": {
        minHeight: 550,
      }
    },
    tourHeader: {
      fontFamily: "Open Sans, sans-serif",
      fontSize: 40,
      display: "inline-block",
      width: 650,
      position: "relative",
      top: 33,
      left: 85,
      [theme.breakpoints.down("md")]: {
        left: 66,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
        width: 550,
        left: 52,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.75rem",
        width: 500,
        left: 46,
      },
      "@media (max-width: 614.95px)": {
        width: 400,
        top: 40,
        left: 0,
      },
      "@media (max-width: 424.95px)": {
        fontSize: "1.5rem",
        width: 350,
      },
      "@media (max-width: 374.95px)": {
        width: 270,
      },
    },
    ebookHeader: {
      fontFamily: "Open Sans, sans-serif",
      fontSize: 50,
      position: "relative",
      marginBottom: 15,
      [theme.breakpoints.down("md")]: {
        fontSize: 40,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 30,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 23,
      },
      "@media (max-width: 425px)": {
        display: "inline-block",
        width: 305,
        textAlign: "center"
      }
    },
    ebookSubheader: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      color: "#C591FC",
      fontSize: 30,
      marginBottom: 35,
      [theme.breakpoints.down("md")]: {
        fontSize: 26,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 23,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 19,
      },
    },
    tourButton: {
      textTransform: "none",
      position: "absolute",
      left: 85,
      fontSize: 30,
      width: 324,
      height: 86,
      display: "inline-block",
      bottom: 50,
      [theme.breakpoints.down("md")]: {
        left: 66,
      },
      [theme.breakpoints.down("sm")]: {
        width: 250,
        height: 75,
        fontSize: 25,
        left: 52,
        bottom: 45,
      },
      [theme.breakpoints.down("xs")]: {
        left: 45,
        width: 200,
        height: 50,
        fontSize: 20,
      },
      "@media (max-width: 614.95px)": {
        display: "inline-block",
        position: "absolute",
        left: 0,
        bottom: -150,
      },
    },
    ebookButton: {
      position: "relative",
      left: 0,
      bottom: 0,
      [theme.breakpoints.down("md")]: {
        width: 290,
        height: 75,
        bottom: 15,
      },
      [theme.breakpoints.down("xs")]: {
        width: 220,
        height: 65,
      },
    },
    gridThreeHeader: {
      fontSize: "1.5rem",
      fontFamily: "Open Sans, sans-serif",
      display: "inline-block",
      color: "#FFB700",
      position: "relative",
      top: 40,
      marginBottom: 70,
      fontWeight: 400,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.25rem",
      },
    },
    gridThreeRightHeader: {
      fontSize: "1.5rem",
      fontFamily: "Open Sans, sans-serif",
      display: "inline-block",
      color: "#FFB700",
      position: "relative",
      top: 40,
      marginBottom: 65,
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        marginBottom: 60,
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: 55,
        fontSize: "1.25rem",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: 52,
      },
    },
    gridThreeSubHeader: {
      fontSize: "2.25rem",
      position: "relative",
      fontFamily: "Open Sans, sans-serif",
      display: "inline-block",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.75rem",
        width: 510,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
        width: 450,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.3rem",
        display: "block",
        marginBottom: 10,
        width: 325,
      },
      "@media (max-width: 614.95px)": {},
      "@media (max-width: 349.95px)": {
        width: 290,
      },
    },
    gridThreeSubheaderRight: {
      marginBottom: 15,
    },
    findOutMoreButton: {
      color: "white",
      backgroundColor: "#F84B59",
      width: "48%",
      height: 54,
      display: "inline-block",
      fontSize: 24,
      fontWeight: "bold",
      fontStyle: "italic",
      position: "absolute",
      right: 0,
      "&:hover": {
        backgroundColor: "#a6323b",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
      "@media (max-width: 374.95px)": {
        fontSize: 14,
      },
    },
    arrowForwardIcon: {
      fontSize: 16,
      display: "inline",
      marginLeft: 10,
      [theme.breakpoints.down("sm")]: {
        position: "relative",
        top: 2,
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      "@media (max-width: 614.95px)": {
        display: "initial",
      },
      "@media (max-width: 425px)": {
        display: "none",
      },
    },
  })
);

export { MainLandingStyles };