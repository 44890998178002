import { makeStyles, createStyles } from '@material-ui/core/styles';

const CalendarStyles = makeStyles((theme: any) => createStyles({
  gridHeader: {
    color: "#00B6FF",
    fontFamily: "Open Sans, sans-serif",
    position: "relative",
    fontSize: "2.25rem",
    fontWeight: 400,
    '@media(max-width: 850px)': {
      fontSize: "1.8rem"
    },
    '@media(max-width: 500px)': {
      fontSize: "1.7rem"
    }
  },
  calendarHeader: {
    top: 150,
  }, 
  divider: {
    color: "white",
    backgroundColor: "white",
    opacity: 1,
    height: 1,
    position: "relative",
  },
  calendarDivider: {
    top: 175,
    '@media screen and (max-width: 675px) and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      top: 176,
    }
  },
  loginProjectsSubheader: {
    color: "#FFB700",
    position: "relative",
    top: 0,
    left: 0,
    fontSize: "1.5rem",
    '@media(max-width: 850px)': {
      fontSize: "1.3rem"
    }
  },
  calendarText: {
    fontSize: "1rem",
    color: "white",
    position: "relative",
    top: 45,
    display: "inline-block",
    width: "95%",
    '@media (max-width: 675px)': {
      width: "100%"
    },
    '@media(max-width: 500px)': {
      top: 35,
    }
  },
  calendarListText: {
    color: "white",
    position:"relative",
    fontSize: "1rem",
  }
})
)

export { CalendarStyles };