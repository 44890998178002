import { Divider, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

import "../../../App.css";
import styles from './WebBranding.module.css';
import { WebBrandingStyles } from './WebBrandingStyles';

interface Props {
  
}

export default function WebBranding(props: Props): ReactElement {

  const classes = WebBrandingStyles();
  
  return (
    <React.Fragment>

      <Typography variant="h2" className={`${classes.gridHeader} ${classes.webBrandingGridHeader}`}>
        Web Branding
      </Typography>

      <Divider className={`${classes.divider} ${classes.webBrandingDivider}`}></Divider>

      <div className={styles.webBrandingGrid}>

        <div className={styles.webBrandingGridItem}>
          <Typography variant="h3" className={classes.webBrandingSubheader}>
            Web (Branding Tab)
          </Typography>

          <img className={`image ${styles.webBrandingImage}`} alt="Web branding upload popup" src="assets/images/webBranding-1.png" />

          <Typography variant="h4" className={classes.webBrandingListHeader}>
            Web Section
          </Typography>

          <Typography variant="body1" className={classes.webBrandingText}>
            In the "Web" tab of the Branding section, you will be able to modify settings and 
            upload art to match your brand or event
          </Typography>
        </div>
        
        <div className={styles.webBrandingGridItem}>

          <img className={`image ${styles.webBrandingImageNoHeader}`} alt="Intake homepage branding elements" src="assets/images/webBranding-2.png" />

          <ul className={`list ${styles.webBrandingListNoHeader}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Header Logo</b>
              </Typography>
            </li>

            <ul>
              <li className={`listItem`}>
                <Typography variant="body1">
                  Header logo displays on the top left corner of Event Link, Registration Link, Attendee Link, and Presenter Link
                </Typography>
              </li>
            </ul>

          </ul>
        </div>

        <div className={styles.webBrandingGridItem}>

          <img className={`image ${styles.webBrandingImage}`} alt="Highlight color example" src="assets/images/webBranding-3.png" />

          <ul className={`list ${styles.webBrandingListHighlightColor}`}>
            
            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Highlight Color</b>
              </Typography>
            </li>

            <ul>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Highlight color can also be changed to match your theme inside the Showboat 3D space
                </Typography>
              </li>

              <li className={`listItem`}>
                <Typography variant="body1">
                  Use the Color Picker or HEX number
                </Typography>
              </li>

            </ul>
          </ul>
        </div>

        <div className={styles.webBrandingGridItem}>

          <Typography variant="h3" className={classes.webBrandingSubheader}>
            Web Theme (Branding Tab)
          </Typography>

          <Typography className={`${classes.webBrandingText} ${classes.webBrandingTextTheme}`} variant="body1">
            A web theme is the option to have a light or dark mode for your event
          </Typography>

          <ul className={`list ${styles.webBrandingListTheme}`}>
            
            <li className={`listItem`}>
              <Typography variant="body1">
                <b>Dark Theme</b>
              </Typography>
            </li>
          </ul>

          <div className={styles.paletteHolder}>
            <div className={styles.paletteItem} style={{ backgroundColor: "#36363A" }}>

            </div>

            <div className={styles.paletteItem} style={{ backgroundColor: "#3D4043" }}>

            </div>
          </div>


          <ul className={`list ${styles.webBrandingListThemeLight}`}>
            <li className={`listItem`}>
                <Typography variant="body1">
                  <b>Light Theme</b>
                </Typography>
              </li>
          </ul>

          <div className={styles.paletteHolder}>

            <div className={styles.paletteItem} style={{ backgroundColor: "#EDEDED" }}>

            </div>

            <div className={styles.paletteItem} style={{ backgroundColor: "#FFFFFF" }}>

            </div>
            
          </div>

          
        </div>

        <div className={styles.webBrandingGridItem}>

          <Typography variant="h3" className={classes.webBrandingSubheader}>
            Welcome Asset (Branding Tab)
          </Typography>

          <img className={`image ${styles.webBrandingImage}`} alt="Showboat landing page" src="assets/images/webBranding-4.png" />

          <Typography variant="h4" className={classes.webBrandingListHeader}>
            Changing Your Welcome Asset
          </Typography>

          <ul className={`list ${styles.webBrandingList}`}>

            <li className={`listItem`}>
              <Typography variant="body1">
                The welcome image will display in the center of the Event Landing, Attendee Landing, and Presenter Landing
              </Typography>
            </li>

            <li className={`listItem`}>
              <Typography variant="body1">
                Upload an image or video as your welcome asset
              </Typography>
            </li>

            <li className={`listItem`}>
              <Typography style={{ color: "#FF0092" }} variant="body1">
                Image must be 960px x 540px and .png format
              </Typography>
            </li>

            <li className={`listItem`}>
              <Typography style={{ color: "#FF0092" }} variant="body1">
                Video File must be under 10MB and .mp4 format
              </Typography>
            </li>

          </ul>

        </div>
        
      </div>
      
    </React.Fragment>
  )
}
