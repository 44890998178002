import React, { ReactElement, useEffect } from 'react';
import EBookForm from '../../components/EBookForm/EBookForm';
import WideImage from '../../components/WideImage/WideImage';

import styles from "./EbookEffectiveVirtualNetworking.module.css";

interface Props {
  
}

export default function EbookEffectiveVirtualNetworking({}: Props): ReactElement {
  useEffect(() => {
    document.title = "eBook - Effective Virtual Networking";
  }, []);
  
  return (
    <div className={styles.pageWrapper}>
      <WideImage ebookEffectiveVirtualNetworking={true} />

      <EBookForm />
    </div>
  )
}
