import { Paper, Typography } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';

import styles from './PresenterTools.module.css';
import { PresenterToolsStyles } from './PresenterToolsStyles';

interface Props {
  
}

export default function PresenterTools(props: Props): ReactElement {

  const classes = PresenterToolsStyles();
  
  return (
    <div className={classes.presenterToolsHolder}>
      
      <Typography variant="h1" className={classes.presenterToolsHeader}>
        Presenter Tools Overview
      </Typography>

      <img src="assets/images/sb-presenter-toolsV2.png" className={styles.presenterToolsImage} alt="presenter tools" />

      <Typography variant="body1" className={classes.presenterToolsCaption}>
        Presenter tools are found at the bottom of the Showboat interface.
      </Typography>

      <Typography variant="h2" className={classes.modesHeader}>
        Modes
      </Typography>
      <Typography variant="body1" className={classes.modesSubheader}>
        As a presenter you have your choice of two modes.
      </Typography>

      <div className={styles.modesGridHolder}>

        <div className={`${styles.modesGridItem} ${styles.modesGridItemTop} ${styles.modesGridItemA}`}>
          <Typography variant="h3" className={classes.modesGridSubheader}>
            Attendee Mode
          </Typography>

          <img className={styles.modeImage} alt="attendee mode" src="assets/images/attendeeMode.png" />

          <Typography variant="body1" className={classes.modesGridText}>
            To interact with attendees before or after a presentation, or to watch another  presenter as 
            a member of the audience. Your avatar behaves exactly like a regular attendee.
          </Typography>
        </div>
        
        <div className={`${styles.modesGridItem} ${styles.modesGridItemB}`}>
          <Typography variant="h3" className={classes.modesGridSubheader}>
            Presentation Mode
          </Typography>

          <img className={styles.modeImage} alt="Presentation mode option" src="assets/images/presentationMode.png" />
          

          <Typography variant="body1" className={classes.modesGridText}>
            When in Presentation Mode you will be heard and seen by everyone in the event. If a booking has more
            attendance than the capacity of one room, multiple rooms will be dynamically created. When in Presentation Mode you will
            be present in each of the rooms at once.
          </Typography>

          <ul className={styles.modesGridList}>
            <li className={styles.modesGridListItem}>
              <Typography variant="body1" className={classes.modesGridListText}>
                Your avatar will be copied into each of the rooms
              </Typography>
            </li>

            <li className={styles.modesGridListItem}>
              <Typography variant="body1" className={classes.modesGridListText}>
                You will only see the attendees in your room
              </Typography>
            </li>

            <li className={styles.modesGridListItem}>
              <Typography variant="body1" className={classes.modesGridListText}>
                You will continue to get chat messages via room chat
              </Typography>
            </li>
          </ul>
        </div>

        <div className={`${styles.modesGridItem} ${styles.modesGridItemC}`}>
          
          
        </div>
      </div>

      <Typography variant="h2" className={classes.additionalFeaturesHeader}>
        Display Features
      </Typography>

      <img className={styles.displayFeaturesImage} src="assets/images/displayFeatures.png" alt="Display features toolbar" />

      <div className={styles.additionalFeaturesGridHolder}>
        <div className={`${styles.additionalFeaturesGridItem} ${styles.additionalFeaturesGridItemA}`}>

          <img className={styles.additionalFeaturesImage} alt="webcam feature" src="assets/images/webcam.png" />
          
          <Typography variant="body1" className={classes.additionalFeaturesGridText}>
            Share your webcam feed on the Main Presentation Display
          </Typography>

          <ul className={styles.additionalFeaturesList}>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                Must be in "Presentation" Mode to turn on this feature
              </Typography>
            </li>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                Webcam view also syncs with patio display screens
              </Typography>
            </li>
            
          </ul>
        </div>

        <div className={`${styles.additionalFeaturesGridItem} ${styles.additionalFeaturesGridItemE}`}>

          <img className={styles.additionalFeaturesImage} alt="Share slides feature" src="assets/images/slides.png" />

          <Typography variant="body1" className={classes.additionalFeaturesGridText}>
            Show your pre-uploaded slide deck that has been connected to your login (via the Producer Portal)
          </Typography>

          <ul className={`${styles.additionalFeaturesList}`}>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={styles.additionalFeaturesListText}>
                OFF: Not sharing slides to the presentation screen
              </Typography>
            </li>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={styles.additionalFeaturesListText}>
                ON: Sharing slides to presentation screen
              </Typography>
            </li>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={styles.additionalFeaturesListText}>
                ON &gt; OFF &gt; ON: Pick up where you left off (shows last slide queued up)
              </Typography>
            </li>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={styles.additionalFeaturesListText}>
                When presenting, a confidence screen will appear on the bottom left of the screen
              </Typography>
            </li>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={styles.additionalFeaturesListText}>
                You can adjust the size, position and the screen's opacity to help you stay on track when presenting
              </Typography>
            </li>

            <li className={`${styles.additionalFeaturesListItem} ${styles.additionalFeaturesListItemConfidenceScreen}`}>
              <img className={styles.confidenceScreenImage} alt="Confidence screen" src="assets/images/confidenceScreen.png" />
            </li>
          </ul>
        </div>

        <div className={`${styles.additionalFeaturesGridItem} ${styles.additionalFeaturesGridItemB}`}>

          <img className={styles.additionalFeaturesImageScreenshare} alt="screenshare feature" src="assets/images/screenshare.png" />

          <Typography variant="body1" className={classes.additionalFeaturesGridText}>
            Share any application or browser tab to the attendees.
          </Typography>

          <ul className={styles.additionalFeaturesList}>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                OFF: No screenshare 
              </Typography>
            </li>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                ON: Activates browser screen share tools where you can share a tab or app or screen
              </Typography>
            </li>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                ON &gt; OFF: Disconnects your screenshare
              </Typography>
            </li>

          </ul>
        </div>

        <div className={`${styles.additionalFeaturesGridItem} ${styles.additionalFeaturesGridItemC}`}>

          <img className={styles.additionalFeaturesImage} alt="Play local video toggle" src="assets/images/playVideo.png" />
          
          <Typography variant="body1" className={classes.additionalFeaturesGridText}>
            Presenters have the option of uploading a video file to play or sharing a livestream on
            the Main Presentation Display
          </Typography>

        </div>

        <div className={`${styles.additionalFeaturesGridItem} ${styles.additionalFeaturesGridItemD}`}>

          <img className={styles.localVideoImage} alt="Play local video interface" src="assets/images/playVideo2.png" />

          <Typography variant="body1" className={classes.additionalFeaturesGridText}>
            As an alternative to screenshare, presenters can also select a video from their
            hard drive and display it on the presentation screen
          </Typography> 

          <ul className={styles.additionalFeaturesList}>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                Play Local Video (underlined)
              </Typography>
            </li>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                Choose File: Opens dialog to select a video from your computer
              </Typography>
            </li>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                Stream Video OFF: File can be loaded and ready to play, but not playing
              </Typography>
            </li>
            <li className={`${styles.additionalFeaturesListItem} ${styles.additionalFeaturesListItemStreamVideo}`}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                Stream Video ON: File will play on Presentation Displays
              </Typography>
            </li>
          </ul>
        </div>

        

        <div className={`${styles.additionalFeaturesGridItem} ${styles.additionalFeaturesGridItemF}`}>
        
          <img className={styles.localVideoImage} alt="Play livestream feature" src="assets/images/playVideo3.png" />

          <Typography variant="body1" className={classes.additionalFeaturesGridText}>
            Presenters can also play a livestream for the audience
          </Typography>

          <ul className={styles.additionalFeaturesList}>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                Play Livestream (underlined)
              </Typography>
            </li>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                Paste Livestream URL
              </Typography>
            </li>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                Share Livestream OFF: Livestream will not be displayed on Presentation Display
              </Typography>
            </li>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                Share Livestream ON: Livestream will play on Presentation Displays
              </Typography>
            </li>
          </ul>
        </div>

        <div className={`${styles.additionalFeaturesGridItem} ${styles.additionalFeaturesGridItemG}`}>
          <Typography variant="h2" className={`${classes.additionalFeaturesHeader} ${classes.additionalFeaturesHeaderControls}`}>
            Controls
          </Typography>

          <img className={styles.controlsImage} alt="Controls toolbar" src="assets/images/controls.png" />

          <img className={`${styles.additionalFeaturesImage} ${styles.additionalFeaturesImageUnderDisplay}`} alt="Mute audience toggle" src="assets/images/muteAudience.png" />

          <Typography variant="body1" className={`${classes.additionalFeaturesGridText} ${classes.additionalFeaturesGridTextBottom}`}>
            To cut off talk in the room and focus attention on the presentation/presenter
          </Typography>

          <ul className={`${styles.additionalFeaturesList} ${styles.additionalFeaturesListControls}`}>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                OFF: Audience communicates normally to each other
              </Typography>
            </li>

            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                ON: Audience mic and camera are muted (devices still enabled, but signal not being seen or heard by anyone)
              </Typography>
            </li>

            <ul>
              <li className={styles.additionalFeaturesListItem}>
                <Typography variant="body1" className={classes.additionalFeaturesListText}>
                  At this point attendees can use the room chat to communicate with the presenter or one another
                </Typography>
              </li>
            </ul>
          </ul>

        </div>

        <div className={`${styles.additionalFeaturesGridItem} ${styles.additionalFeaturesGridItemH}`}>
          <img className={`${styles.additionalFeaturesImage} ${styles.additionalFeaturesImageLaserPointer}`} alt="Laser pointer toggle" src="assets/images/laserPointer.png" />

          <Typography variant="body1" className={`${classes.additionalFeaturesGridText} ${classes.additionalFeaturesGridTextBottom}`}>
            Turn on to activate the laser pointer. A small dot will appear where you are looking. Attendees will see a laser line
            beginning at the middle of the presenter's head notch and extending to the surface that the presenter is
            currently looking at.
          </Typography>

          <ul className={styles.additionalFeaturesList}>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                OFF: No laser line
              </Typography>
            </li>

            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                ON: Laser line visible
              </Typography>
            </li>

            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                Click + drag to adjust where laser line is pointing
              </Typography>
            </li>
          </ul>

          
        </div>

        <div className={`${styles.additionalFeaturesGridItem} ${styles.additionalFeaturesGridItemI}`}>
          <Typography variant="h2" className={`${classes.additionalFeaturesHeader} ${classes.additionalFeaturesHeaderControls}`}>
            Teleport
          </Typography>

          <img className={styles.controlsImage} alt="Teleport toolbar" src="assets/images/teleport.png" />

          <img className={`${styles.additionalFeaturesImage} ${styles.additionalFeaturesImageUnderDisplay}`} alt="Teleport to stage" src="assets/images/stageTeleport.png" />

          <Typography variant="body1" className={`${classes.additionalFeaturesGridText} ${classes.additionalFeaturesGridTextBottom}`}>
            Only presenters can get on-stage, and this button is the only way to get there
          </Typography>

          <ul className={`${styles.additionalFeaturesList} ${styles.additionalFeaturesListTeleport}`}>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                Press to teleport to the stage
              </Typography>
            </li>
          </ul>

          <img className={`${styles.additionalFeaturesImage} ${styles.additionalFeaturesImageBackstage}`} alt="Teleport backstage" src="assets/images/backstageTeleport.png" />

          <Typography variant="body1" className={`${classes.additionalFeaturesGridText} ${classes.additionalFeaturesGridTextBottom}`}>
            Presenters can go backstage to an area of the space where they can be with other presenters
            to prepare themselves without the possibility of being overheard by other attendees
          </Typography>

          <ul className={`${styles.additionalFeaturesList} ${styles.additionalFeaturesListTeleport}`}>
            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                Press to teleport to backstage
              </Typography>
            </li>

            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                Regular attendees will not be able to hear or see you regardless of what mode you are in while backstage (Attendee or Presentation mode)
              </Typography>
            </li>

            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                You can still walk around the space without being seen or heard
              </Typography>
            </li>

            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                Your name will continue to show up in the attendee directory
              </Typography>
            </li>

            <li className={styles.additionalFeaturesListItem}>
              <Typography variant="body1" className={classes.additionalFeaturesListText}>
                You can still participate in room chat as well as send direct messages to other attendees and presenters
              </Typography>
            </li>
          </ul>

        </div>

      </div>
      
    </div>
  )
}
