import { Button, Link, Paper, Typography } from '@material-ui/core'
import React, { ReactElement } from 'react'
import ReactGA from "react-ga";
import { ArrowForwardIos } from '@material-ui/icons';

import { MainLandingStyles } from './MainLandingStyles'
import styles from './MainDisplay.module.css';


interface Props {
  
}

export default function MainDisplay2(props: Props): ReactElement {
  
  const handleTourButtonClick = () => {
    //Trigger Google Analytics event
    ReactGA.event({
      category: "Actions",
      action: "Clicked Tour Link"
    })
    
    window.location.replace("/tour");
  }

  const handleGetEbookClick = () => {
    window.location.replace("/ebook/effective-virtual-networking");
  }

  const handleFindOutMoreClick = () => {
    window.location.replace("/north-pole")
  };
  
  const classes = MainLandingStyles();
  
  return (
    <Paper elevation={0} className={classes.mainDisplayHolder}>
      <div className={styles.mainTextWrapper}>
        <Typography variant="h1" className={classes.mainHeader}>
          A virtual platform for facilitating natural face-to-face conversations
        </Typography>

        <div className={styles.mainGridHolderOne}>
          <div className={styles.gridOneItem}>
            <Typography variant="h2" className={classes.gridHeader}>
              Next-Level Presentations
            </Typography>

            <Typography variant="body1" className={classes.gridText}>
              Shift away from low engagement 2D presentations. Showboat has the features you need, 
              all in a state-of-the-art immersive web-based environment.
            </Typography>
          </div>
          <div className={styles.gridOneItem}>
            <Typography variant="h2" className={classes.gridHeader}>
              Effective Networking
            </Typography>

            <Typography
              variant="body1"
              className={classes.gridText}
            >
              Showboat is the most in-person-like experience available. Spatial audio allows for multiple
              conversations without trampling each other.
            </Typography>
          </div>
          <div className={styles.gridOneItem}>
            <Typography variant="h2" className={classes.gridHeader}>
              Hybrid Workflows
            </Typography>

            <Typography
              variant="body1"
              className={classes.gridText}
            >
              Include everyone and not just those who choose to travel. Custom environments are available
              to mirror your in-person aesthetics and maximize your brand.
            </Typography>
          </div>
        </div>

        <Paper square className={classes.tourHolder}>
          <div className={styles.tourTextHolder}>
            <Typography variant="h2" className={classes.tourHeader}>
              Showboat tours are happening daily. Book yours today!
            </Typography>

            <Button
              onClick={handleTourButtonClick}
              className={classes.tourButton}
              variant="contained"
              color="primary"
            >
              Request a Tour
            </Button>
          </div>
        </Paper>

        <div className={styles.mainGridHolderTwo}>
          <div className={styles.gridTwoTextHolder}>
            <Typography variant="h2" className={classes.gridTwoHeader}>
              Face-to-face in virtual space
            </Typography>
            <Typography variant="body1" className={classes.gridTwoText}>
              In Showboat, each visitor configures a simple avatar which allows
              freedom of movement around the virtual environment. With webcam
              feeds streaming right on each avatar's face, communication happens
              naturally, just like you would in the real world.
            </Typography>
          </div>
          <div className={styles.gridTwoImageHolder}>
            <img
              className={styles.groupImage}
              alt="showboat avatar example"
              src="assets/images/sb-group.png"
            />
          </div>
        </div>

        <Paper className={`${classes.tourHolder} ${classes.ebookHolder}`}>
          <img
            className={styles.ebookImage}
            alt="ebook cover"
            src="assets/images/ebook-networking.png"
          />

          <div className={styles.ebookTextHolder}>
            <Typography variant="h2" className={classes.ebookHeader}>
              Effective Networking for Virtual Events
            </Typography>

            <Typography variant="h3" className={classes.ebookSubheader}>
              How to rescue virtual ROI.
            </Typography>

            <Button
              onClick={handleGetEbookClick}
              variant="contained"
              color="primary"
              className={`${classes.tourButton} ${classes.ebookButton}`}
            >
              Get the eBook
            </Button>
          </div>
        </Paper>

        <div className={styles.mainGridHolderThree}>
          <div className={styles.leftTextHolder}>
            <Typography variant="h2" className={classes.gridThreeHeader}>
              Easy
            </Typography>
            <div className={styles.leftSubHeaderHolder}>
              <Typography variant="h2" className={classes.gridThreeSubHeader}>
                Showboat runs in a browser.
              </Typography>
              <Typography variant="h2" className={classes.gridThreeSubHeader}>
                No software to download or learn.
              </Typography>
            </div>
          </div>
          <div className={styles.rightTextHolder}>
            <Typography variant="h2" className={classes.gridThreeRightHeader}>
              Powerful
            </Typography>

            <div className={styles.rightSubHeaderHolder}>
              <Typography
                variant="h2"
                className={`${classes.gridThreeSubHeader} ${classes.gridThreeSubheaderRight}`}
              >
                Event Chat
              </Typography>
              <Typography
                variant="h2"
                className={`${classes.gridThreeSubHeader} ${classes.gridThreeSubheaderRight}`}
              >
                Direct Messages
              </Typography>
              <Typography
                variant="h2"
                className={`${classes.gridThreeSubHeader} ${classes.gridThreeSubheaderRight}`}
              >
                Teleport
              </Typography>
              <Typography
                variant="h2"
                className={`${classes.gridThreeSubHeader} ${classes.gridThreeSubheaderRight}`}
              >
                Screenshare
              </Typography>
              <Typography
                variant="h2"
                className={`${classes.gridThreeSubHeader} ${classes.gridThreeSubheaderRight}`}
              >
                Slide Presentations
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
}
