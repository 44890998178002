import React, { ReactElement, useEffect } from 'react'
import ReactGA from "react-ga";

import ProducerPortalAssetList from '../../components/ProduerPortalAssetList/ProducerPortalAssetList'

interface Props {
  
}

export default function AssetList(props: Props): ReactElement {
  
  useEffect(() => {
    document.title = "Showboat Asset List";

    //Trigger Google Analytics pageview
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])
  
  return (
    <React.Fragment>
      <ProducerPortalAssetList />
    </React.Fragment>
  )
}
