import { Link, Paper, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import { TermsOfServiceStyles } from "./TermsOfServiceStyles";
import styles from "./TermsOfService.module.css";

interface Props {}

export default function TermsOfService({}: Props): ReactElement {
  const classes = TermsOfServiceStyles();

  return (
    <Paper className={classes.termsOfServiceHolder}>
      <div className={styles.termsOfServiceTextWrapper}>
        <Typography variant="h1" className={classes.termsOfServiceHeader}>
          Terms of Use
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Acceptance of the Terms of Use
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          These terms of use are entered into by and between You and No Small
          Magic, LLC ("Company," "we," or "us"). The following terms and
          conditions ("Terms of Use") govern your access to and use of
          showboat.live including any subdomains of showboat.live, and any
          content, functionality and services offered on or through
          showboat.live (the "Website"), whether as a guest or a registered
          user. BY REGISTERING FOR AND/OR USING THE WEBSITE OR SERVICES IN ANY
          MANNER, YOU AGREE THAT YOU HAVE READ, UNDERSTAND AND ACCEPT THIS
          AGREEMENT AND ALL OTHER OPERATING RULES, POLICIES AND PROCEDURES
          REFERENCED HEREIN, EACH OF WHICH IS INCORPORATED HEREIN BY THIS
          REFERENCE AND EACH OF WHICH MAY BE UPDATED FROM TIME TO TIME AS SET
          FORTH BELOW, TO THE EXCLUSION OF ALL OTHER TERMS. You may not use the
          Website or Services if you do not unconditionally accept this
          Agreement. If you are accepting on behalf of an organization, you
          represent and warrant that you have the authority to do so; however,
          if your organization has entered into a separate contract with No
          Small Magic covering its use of the Services, then that contract shall
          govern instead.
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          Please read the Terms of Use carefully before you start to use the
          Website. By using the Website or by clicking to accept or agree to the
          Terms of Use when this option is made available to you, you accept and
          agree to be bound and abide by these Terms of Use and our Privacy
          Policy, found at https://showboat.live/privacy, incorporated herein by
          reference. If you do not want to agree to these Terms of Use or the
          Privacy Policy, you must not access or use the Website.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          This Website and its services are offered and available to users who
          are 16 years of age or older. By using this Website, you represent and
          warrant that you are of legal age to form a binding contract with the
          Company and meet all of the foregoing eligibility requirements. If you
          do not meet all of these requirements, you must not access or use the
          Website.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Changes to the Terms of Use
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          We may revise and update these Terms of Use from time to time in our
          sole discretion. All changes are effective immediately when we post
          them, and apply to all access to and use of the Website thereafter. As
          used herein, reference to use of the Website includes use of the
          Website’s functionality, such as video conferencing.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          Your continued use of the Website following the posting of revised
          Terms of Use means that you accept and agree to the changes. You are
          expected to check this page from time to time so you are aware of any
          changes, as they are binding on you.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Accessing the Website and Account Security
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          We reserve the right to withdraw or amend this Website, and any
          service or material we provide on the Website, in our sole discretion
          without notice. We will not be liable if for any reason all or any
          part of the Website is unavailable at any time or for any period. From
          time to time, we may restrict access to some parts of the Website, or
          the entire Website, to users, including registered users.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextListHeader}`}
        >
          You are responsible for:
        </Typography>

        <ul className={styles.list}>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Ensuring your use of the Website complies with all applicable
              laws.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Making all arrangements necessary for you to have access to the
              Website.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Ensuring that all persons who access the Website through your
              internet connection are aware of these Terms of Use and comply
              with them.
            </Typography>
          </li>
        </ul>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          To access the Website to include its service, you may be asked to
          provide certain registration details or other information. It is a
          condition of your use of the Website that all the information you
          provide on the Website is correct, current and complete. You agree
          that all information you provide to register with this Website or
          otherwise, including, but not limited to, through the use of any
          interactive features on the Website, is governed by our Privacy
          Policy, and you consent to all actions we take with respect to your
          information consistent with our Privacy Policy.
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          If you choose, or are provided with, a user name, password or any
          other piece of information as part of our security procedures, you
          must treat such information as confidential, and you must not disclose
          it to any other person or entity. You also acknowledge that your
          account is personal to you and agree not to provide any other person
          with access to this Website or portions of it using your user name,
          password or other security information. You agree to notify us
          immediately of any unauthorized access to or use of your user name or
          password or any other breach of security. You also agree to ensure
          that you exit from your account at the end of each session. You should
          use particular caution when accessing your account from a public or
          shared computer so that others are not able to view or record your
          password or other personal information.
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          We have the right to disable any user name, password or other
          identifier, whether chosen by you or provided by us, at any time in
          our sole discretion for any or no reason, including if, in our
          opinion, you have violated any provision of these Terms of Use.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          You are responsible for compliance with all recording laws.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Payments, Charges, and Cancellations
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          a. Paid Website and services. Customer agrees to pay for its use of
          the Website and services as set forth on the pricing page on the Site,
          as may be updated from time to time, except and only to the extent
          Customer has entered a separate written agreement or addendum with No
          Small Magic for special pricing on certain Services and/or
          country-specific pricing, or No Small Magic has otherwise conveyed
          special pricing in writing to Customer. No Small Magic reserves the
          right to change pricing from time to time. Customer’s continued use of
          the Services after a price change becomes effective constitutes
          Customer’s agreement to pay the changed amount.
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          b. Prepayment. Except as set forth in the next sub-section, Customer
          shall pay in advance for the Services in the currency specified by No
          Small Magic, without the right of set-off, deductions, or
          counterclaim. Some of the Services may accept recurring period charges
          as agreed to by Customer. By choosing such auto-reload payment plan,
          Customer acknowledges that such Services have a recurring payment
          feature and Customer accepts responsibility for all recurring charges
          prior to cancellation. NO SMALL MAGIC MAY SUBMIT PERIODIC CHARGES
          (E.G., MONTHLY) WITHOUT FURTHER AUTHORIZATION FROM CUSTOMER, UNTIL
          CUSTOMER PROVIDES PRIOR NOTICE (RECEIPT OF WHICH IS CONFIRMED BY No
          Small Magic) THAT CUSTOMER HAS TERMINATED THIS AUTHORIZATION OR WISHES
          TO CHANGE ITS PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES
          SUBMITTED BEFORE No Small Magic REASONABLY COULD ACT. CUSTOMER MUST
          PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR ITS BILLING
          ACCOUNT. CUSTOMER MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP ITS
          BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN
          BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE),
          AND CUSTOMER MUST PROMPTLY NOTIFY NO SMALL MAGIC OR ITS PAYMENT
          PROCESSOR IF CUSTOMER’S PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR
          THEFT) OR IF CUSTOMER BECOMES AWARE OF A POTENTIAL BREACH OF SECURITY,
          SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF CUSTOMER’S USER NAME OR
          PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE IN THE ACCOUNT
          DASHBOARD. IF CUSTOMER FAILS TO PROVIDE ANY OF THE FOREGOING
          INFORMATION, CUSTOMER AGREES THAT NO SMALL MAGIC MAY CONTINUE CHARGING
          CUSTOMER FOR ANY USE OF PAID SERVICES UNDER THE BILLING ACCOUNT
          TERMINATED ITS PAID SERVICES AS SET FORTH ABOVE. Customer may change
          their payment method by notifying No Small Magic via email to
          accounting@nosmallmagic.com.
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          c. Post-payment. Where authorized by No Small Magic in writing, No
          Small Magic will invoice Customer for the Services monthly in arrears,
          and payment shall be due 30 days following the date of invoice,
          without deduction. Invoices may be sent to the e-mail address
          specified in Customer’s Account, and shall be deemed received on the
          date sent. Customer shall pay interest on past due amounts at a rate
          of 1.5% per month or the maximum rate permitted by law, whichever is
          less, and shall be responsible for all costs of collection. If
          Customer disputes any invoiced charges exceeding USD 1,000 in a
          detailed writing provided to No Small Magic, and such disputes are
          reasonable and made in good faith (as reasonably determined by No
          Small Magic), then (i) the Parties shall discuss and attempt to
          resolve the dispute in good faith in a timely manner, and (ii)
          Customer may withhold the disputed portion of the invoice exceeding
          USD 1,000 if Customer has paid the undisputed portion of the invoice
          in accordance with the Agreement, until the dispute is resolved.
          Customer’s credit shall be limited to the amount indicated by No Small
          Magic from time-to-time in connection with Customer’s Account.
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          d. Taxes. Unless otherwise provided on the pricing page on the Site,
          all charges and fees for the Services are exclusive of any country,
          province, federal, state or local taxes, including without limitation,
          use, sales, value-added, privilege, or other taxes, levies, imports,
          duties, fees, surcharges, governmental assessments and withholdings
          (“Taxes”). Customer will be solely liable for and will pay upon demand
          all Taxes associated with Customer’s access to and use of the Services
          and shall not deduct any such amounts, or any other withholdings,
          set-offs or deductions, from amounts Customer owes No Small Magic, but
          will not be responsible for taxes based on No Small Magic’s net
          income. Customer may present No Small Magic with an exemption
          certificate eliminating Customer’s and No Small Magic’s liability to
          pay certain Taxes. Once No Small Magic has received and approved the
          exemption certificate, Customer shall be exempt from those Taxes on a
          going-forward basis. If, a taxing jurisdiction determines that
          Customer is not exempt from Taxes and assesses those taxes, Customer
          shall pay those Taxes to No Small Magic, plus any applicable interest
          or penalties. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED BEFORE No
          Small Magic REASONABLY COULD ACT.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          e. Billing Disputes. Billing disputes must be initiated within 30 days
          of the date the Service was provided, by contacting No Small Magic at
          accounting@nosmallmagic.com. Upon expiration of such 30-day period,
          Customer will not be entitled to dispute any fees paid or payable to
          No Small Magic. The Parties will work together in good faith to
          resolve billing disputes. A pending billing dispute shall not exempt
          Customer from timely paying any undisputed amounts owed. Any disputes
          that cannot be resolved in accordance with the foregoing shall be
          handled in accordance with Section 13 below. Other than in accordance
          with the foregoing, fees paid are non-refundable.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Intellectual Property Rights
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          The Website and its entire contents, features and functionality
          (including but not limited to all information, software, text,
          displays, images, video and audio, and the design, selection and
          arrangement thereof), are owned by the Company, its licensors or other
          providers of such material and are protected by United States and
          international copyright, trademark, patent, trade secret and other
          intellectual property or proprietary rights laws.
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          These Terms of Use permit you to use the Website for your personal,
          non-commercial use only. You must not reproduce, decompile,
          distribute, modify, create derivative works of, publicly display,
          publicly perform, republish, download, store or transmit any of the
          material or functionality of our Website, except as follows:
        </Typography>

        <ul className={styles.list}>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Your computer may temporarily store copies of such materials in
              RAM incidental to your accessing and viewing those materials.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              You may store files that are automatically cached by your Web
              browser for display enhancement purposes.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              You may print one copy of a reasonable number of pages of the
              Website for your own personal, non-commercial use and not for
              further reproduction, publication or distribution.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              If we provide desktop, mobile or other applications for download,
              you may download a single copy to your computer or mobile device
              solely for your own personal, non-commercial use, provided you
              agree to be bound by our end user license agreement for such
              applications.
            </Typography>
          </li>
        </ul>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          You must not:
        </Typography>

        <ul className={styles.list}>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Modify copies of any materials from this site.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Delete or alter any copyright, trademark or other proprietary
              rights notices from copies of materials from this site.
            </Typography>
          </li>
        </ul>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          You must not access or use for any commercial purposes any part of the
          Website or any services or materials available through the Website.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          If you reproduce, sell or distribute, or provide any other person with
          access to any part of the Website in breach of the Terms of Use, your
          right to use the Website will cease immediately and you must, at our
          option, return or destroy any copies of the materials you have made.
          No right, title or interest in or to the Website or any services or
          content on the Website is transferred to you, and all rights not
          expressly granted are reserved by the Company. Any use of the Website
          not expressly permitted by these Terms of Use is a breach of these
          Terms of Use and may violate copyright, trademark and other laws.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Trademarks
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          The Company name, the terms Showboat, the Company logo, the Showboat
          logo and all related names, logos, product and service names, designs
          and slogans are trademarks of the Company or its affiliates or
          licensors. You must not use such marks without the prior written
          permission of the Company. All other names, logos, product and service
          names, designs and slogans on this Website are the trademarks of their
          respective owners.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Prohibited Uses
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          You may use the Website and services only for lawful purposes and in
          accordance with these Terms of Use. You agree not to use the Website:
        </Typography>

        <ul className={styles.list}>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              In any way that violates any applicable federal, state, local or
              international law or regulation (including, without limitation,
              any laws regarding the export of data or software to and from the
              US or other countries).
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              For the purpose of exploiting, harming or attempting to exploit or
              harm minors in any way by exposing them to inappropriate content,
              asking for personally identifiable information or otherwise.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              To send, knowingly receive, upload, download, use or re-use any
              material which does not comply with the Content Standards set out
              in these Terms of Use.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              To transmit, or procure the sending of, any advertising or
              promotional material, including any "junk mail," "chain letter,"
              "spam," or any other similar solicitation.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              To impersonate or attempt to impersonate the Company, a Company
              employee, another user or any other person or entity (including,
              without limitation, by using e-mail addresses or screen names
              associated with any of the foregoing).
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              To engage in any other conduct that restricts or inhibits anyone's
              use or enjoyment of the Website, or which, as determined by us,
              may harm the Company or users of the Website, or expose them to
              liability.
            </Typography>
          </li>
        </ul>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          Additionally, you agree not to:
        </Typography>

        <ul className={styles.list}>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Use the Website in any manner that could disable, overburden,
              damage, or impair the site or interfere with any other party's use
              of the Website, including their ability to engage in real time
              activities through the Website.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Use any robot, spider or other automatic device, process or means
              to access the Website for any purpose, including monitoring or
              copying any of the material on the Website.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Use any manual process to monitor or copy any of the material on
              the Website, or for any other purpose not expressly authorized in
              these Terms of Use, without our prior written consent.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Use any device, software or routine that interferes with the
              proper working of the Website.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Introduce any viruses, trojan horses, worms, logic bombs or other
              material which is malicious or technologically harmful.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Attempt to gain unauthorized access to, interfere with, damage or
              disrupt any parts of the Website, the server on which the Website
              is stored, or any server, computer or database connected to the
              Website.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Attack the Website via a denial-of-service attack or a distributed
              denial-of-service attack.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Otherwise attempt to interfere with the proper working of the
              Website.
            </Typography>
          </li>
        </ul>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          User Contributions
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          The Website may contain video conferencing, message boards, chat
          rooms, personal web pages or profiles, forums, bulletin boards, and
          other interactive features (collectively, "Interactive Services") that
          allow users to post, submit, publish, display or transmit to other
          users or other persons (hereinafter, "post") content or materials
          (collectively, "User Contributions") on or through the Website.
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          All User Contributions must comply with the Content Standards set out
          in these Terms of Use.
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          Any User Contribution you post to the site will be considered
          non-confidential and non-proprietary. By providing any User
          Contribution on the Website, you grant us and our licensees,
          successors and assigns the right to use, reproduce, modify, perform,
          display, distribute and otherwise disclose to third parties any such
          material according to your account settings.
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          You represent and warrant that:
        </Typography>

        <ul className={styles.list}>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              You own or control all rights in and to the User Contributions and
              have the right to grant the license granted above to us and our
              licensees, successors and assigns.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              All of your User Contributions do and will comply with these Terms
              of Use.
            </Typography>
          </li>
        </ul>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          You understand and acknowledge that you are responsible for any User
          Contributions you submit or contribute, and you, not the Company, have
          full responsibility for such content, including its legality,
          reliability, accuracy and appropriateness.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          We are not responsible, or liable to any third party, for the content
          or accuracy of any User Contributions posted by you or any other user
          of the Website.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Monitoring and Enforcement; Termination
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          We have the right to:
        </Typography>

        <ul className={styles.list}>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Remove or refuse to post any User Contributions for any or no
              reason in our sole discretion.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Take any action with respect to any User Contribution that we deem
              necessary or appropriate in our sole discretion, including if we
              believe that such User Contribution violates the Terms of Use,
              including the Content Standards, infringes any intellectual
              property right or other right of any person or entity, threatens
              the personal safety of users of the Website or the public or could
              create liability for the Company.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Disclose your identity or other information about you to any third
              party who claims that material posted by you violates their
              rights, including their intellectual property rights or their
              right to privacy.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Take appropriate legal action, including without limitation,
              referral to law enforcement, for any illegal or unauthorized use
              of the Website.
            </Typography>
          </li>
          <li className={styles.listItem}>
            <Typography
              variant="body1"
              className={classes.privacyPolicyTextList}
            >
              Terminate or suspend your access to all or part of the Website for
              any or no reason, including without limitation, any violation of
              these Terms of Use.
            </Typography>
          </li>
        </ul>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          Without limiting the foregoing, we have the right to fully cooperate
          with any law enforcement authorities or court order requesting or
          directing us to disclose the identity or other information of anyone
          posting any materials on or through the Website. YOU WAIVE AND HOLD
          HARMLESS THE COMPANY FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN
          BY THE COMPANY DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM
          ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER THE
          COMPANY OR LAW ENFORCEMENT AUTHORITIES.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          However, we do not undertake to review material before it is posted on
          the Website, and cannot ensure prompt removal of objectionable
          material after it has been posted. Accordingly, we assume no liability
          for any action or inaction regarding transmissions, communications or
          content provided by any user or third party. We have no liability or
          responsibility to anyone for performance or nonperformance of the
          activities described in this section.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Copyright Infringement
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          If you believe that any User Contributions violate your copyright,
          please see our Copyright Policy for instructions on sending us a
          notice of copyright infringement. It is the policy of the Company to
          terminate the user accounts of repeat infringers.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Reliance on Information Posted
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          The information presented on or through the Website is made available
          solely for general information purposes. We do not warrant the
          accuracy, completeness or usefulness of this information. Any reliance
          you place on such information is strictly at your own risk. We
          disclaim all liability and responsibility arising from any reliance
          placed on such materials by you or any other visitor to the Website,
          or by anyone who may be informed of any of its contents. This Website
          may include content provided by third parties, including materials
          provided by other users, bloggers and third-party licensors,
          syndicators, aggregators and/or reporting services. All statements
          and/or opinions expressed in these materials, and all articles and
          responses to questions and other content, other than the content
          provided by the Company, are solely the opinions and the
          responsibility of the person or entity providing those materials.
          These materials do not necessarily reflect the opinion of the Company.
          We are not responsible, or liable to you or any third party, for the
          content or accuracy of any materials provided by any third parties.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Changes to the Website
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          We may update the content on this Website from time to time, but its
          content is not necessarily complete or up-to-date. Any of the material
          on the Website may be out of date at any given time, and we are under
          no obligation to update such material.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Information About You and Your Visits to the Website
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          All information we collect on this Website is subject to our Privacy
          Policy. By using the Website, you consent to all actions taken by us
          with respect to your information in compliance with the Privacy
          Policy.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Links from the Website
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          If the Website contains links to other sites and resources provided by
          third parties, these links are provided for your convenience only.
          This includes links contained in advertisements, including banner
          advertisements and sponsored links. We have no control over the
          contents of those sites or resources, and accept no responsibility for
          them or for any loss or damage that may arise from your use of them.
          If you decide to access any of the third party websites linked to this
          Website, you do so entirely at your own risk and subject to the terms
          and conditions of use for such websites.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Geographic Restrictions
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          The owner of the Website is based in the State of Wisconsin in the
          United States. We provide this Website for use only by persons located
          in the United States. We make no claims that the Website or any of its
          content is accessible or appropriate outside of the United States.
          Access to the Website may not be legal by certain persons or in
          certain countries. If you access the Website from outside the United
          States, you do so on your own initiative and are responsible for
          compliance with local laws.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Disclaimer of Warranties
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          You understand that we cannot and do not guarantee or warrant that
          files available for downloading from the internet or the Website will
          be free of viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our site for
          any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY
          LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
          VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
          COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY
          MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL
          POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
          CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE
          PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY
          NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
          REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
          RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT
          LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH
          THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR
          ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE,
          RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
          THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
          VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY
          SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET
          YOUR NEEDS OR EXPECTATIONS.
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
          EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED
          TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR
          PARTICULAR PURPOSE.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Limitation on Liability
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY,
          ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
          AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER
          ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR
          INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT
          ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY
          DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
          DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
          SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS
          OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
          LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
          BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Indemnification
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          You agree to defend, indemnify and hold harmless the Company, its
          affiliates, licensors and service providers, and its and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors and assigns from and against any
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses or fees (including reasonable attorneys' fees) arising out of
          or relating to your violation of these Terms of Use or your use of the
          Website, including, but not limited to, your User Contributions, any
          use of the Website's content, services and products other than as
          expressly authorized in these Terms of Use, or your use of any
          information obtained from the Website.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Governing Law and Jurisdiction
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          All matters relating to the Website and these Terms of Use, and any
          dispute or claim arising therefrom or related thereto (in each case,
          including non-contractual disputes or claims), shall be governed by
          and construed in accordance with the internal laws of the State of
          Wisconsin without giving effect to any choice or conflict of law
          provision or rule (whether of the State of Wisconsin or any other
          jurisdiction).
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          Any legal suit, action or proceeding arising out of, or related to,
          these Terms of Use or the Website shall be instituted exclusively in
          the federal courts of the United States or the courts of the State of
          Wisconsin, in each case located in the City of Milwaukee and County of
          Milwaukee, although we retain the right to bring any suit, action or
          proceeding against you for breach of these Terms of Use in your
          country of residence or any other relevant country. You waive any and
          all objections to the exercise of jurisdiction over you by such courts
          and to venue in such courts.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Arbitration
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          Company and You may submit any disputes arising from these Terms of
          Use or use of the Website, including disputes arising from or
          concerning their interpretation, violation, invalidity,
          non-performance, or termination, to final and binding arbitration
          under the Rules of Arbitration of the American Arbitration
          Association, to be resolved on an expedited basis by a single arbiter
          with such arbitration to occur in Milwaukee, Wisconsin law.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Limitation on Time to File Claims
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
          TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1)
          YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF
          ACTION OR CLAIM IS PERMANENTLY BARRED.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Waiver and Severability
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          No waiver of by the Company of any term or condition set forth in
          these Terms of Use shall be deemed a further or continuing waiver of
          such term or condition or a waiver of any other term or condition, and
          any failure of the Company to assert a right or provision under these
          Terms of Use shall not constitute a waiver of such right or provision.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          If any provision of these Terms of Use is held by a court or other
          tribunal of competent jurisdiction to be invalid, illegal or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms of Use will continue in full force and effect.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Entire Agreement
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.termsOfServiceText} ${classes.termsOfServiceTextBottom}`}
        >
          The Terms of Use and our Privacy Policy constitute the sole and entire
          agreement between you and No Small Magic, LLC with respect to the
          Website and services and supersede all prior and contemporaneous
          understandings, agreements, representations and warranties, both
          written and oral, with respect to the Website.
        </Typography>

        <Typography variant="h2" className={classes.termsOfServiceTextHeader}>
          Your Comments and Concerns
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          This website is operated by No Small Magic, LLC 1207 W. Canal St.,
          Milwaukee, WI 32223. All notices of copyright infringement claims
          should be sent to the copyright agent designated in our Copyright
          Policy in the manner and by the means set forth therein.
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          All other feedback, comments, requests for technical support and other
          communications relating to the Website should be directed to:
          support@showboat.live.
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          Last Modified: October 14, 2021
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          <Link
            underline="always"
            className={classes.link}
            onClick={null}
          >
            Terms of Use
          </Link>
          <Link
            target="_blank"
            href="https://showboat.live/privacy"
            underline="always"
            className={classes.link}
          >
            Privacy Policy
          </Link>
        </Typography>

        <Typography variant="body1" className={classes.termsOfServiceText}>
          © 2021 No Small Magic. All Rights Reserved.
        </Typography>
      </div>
    </Paper>
  );
}
