import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Link,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import ReactGA from "react-ga";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import axios from "axios";
import InputMask from "react-input-mask";

import styles from "./TourForm.module.css";
import { TourFormStyles } from "./TourFormStyles";

interface Props {}

export default function TourForm(props: Props): ReactElement {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [notes, setNotes] = useState("");

  const [events, setEvents] = useState(false);
  const [salesEnvironments, setSalesEnvironments] = useState(false);
  const [internalUse, setInternalUse] = useState(false);

  const [nameRequiredError, setNameRequiredError] = useState(false);
  const [emailRequiredError, setEmailRequiredError] = useState(false);
  const [intendedUseError, setIntendedUseError] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitLoadingSpinner, setSubmitLoadingSpinner] = useState(false);

  const handleNameChange = (e) => {
    setNameRequiredError(false);

    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmailRequiredError(false);
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleEventsChange = (e) => {
    setIntendedUseError(false);
    setEvents(e.target.checked);
  };

  const handleSalesEnvironmentsChange = (e) => {
    setIntendedUseError(false);
    setSalesEnvironments(e.target.checked);
  };

  const handleInternalMeetingsChange = (e) => {
    setIntendedUseError(false);
    setInternalUse(e.target.checked);
  };

  const handleFormSubmit = async () => {
    let formHasError;

    //Check for required inputs
    if (!name.trim().length) {
      setNameRequiredError(true);
      formHasError = true;
    }

    if (!email.trim().length) {
      setEmailRequiredError(true);
      formHasError = true;
    }

    if (!events && !internalUse && !salesEnvironments) {
      setIntendedUseError(true);
      formHasError = true;
    }

    if (formHasError) return;

    let interestList = "";
    let interestArray = [];

    if (events) interestArray.push("Events");
    if (internalUse) interestArray.push("Internal Use");
    if (salesEnvironments) interestArray.push("Sales Environments");

    interestList = interestArray.join(", ");
    
    try {
      let dataObj = {
        name,
        email,
        phone,
        company,
        interestList,
        notes,
      };

      setSubmitLoadingSpinner(true);

      const dataSubmitForm = await axios.post(
        "/tour-email",
        JSON.stringify(dataObj)
      );

      setSubmitLoadingSpinner(false);

      if (dataSubmitForm.data.result === false) {
        console.log("ERROR");
        return;
      }

      //Trigger Google Analytics event
      ReactGA.event({
        category: "Actions",
        action: "Submitted Tour Form"
      })

      //Scroll to top of page
      document.body.scrollTop = document.documentElement.scrollTop = 0;

      setFormSubmitted(true);
    } catch (error) {
      console.log("ERROR", error);
      setSubmitLoadingSpinner(false);
    }
  };

  const classes = TourFormStyles();

  return (
    <Paper
      elevation={0}
      className={
        formSubmitted
          ? `${classes.mainDisplayHolder} ${classes.mainDisplayHolderFormSubmitted}`
          : classes.mainDisplayHolder
      }
    >
      <div className={styles.mainTextWrapper}>
        <div className={styles.showboatLogoHeader}>
          <img
            alt="Showboat logo"
            className={styles.showboatLogoHeader}
            src="assets/images/sb-logo.png"
          />

          <Typography variant="h1" className={classes.showboatLogoHeaderText}>
            Request a Tour
          </Typography>
        </div>

        {!formSubmitted &&
          <div className={styles.tourSubheaderHolder}>
            <Typography variant="h1" className={classes.tourSubheader}>
              Showboat is a virtual platform for facilitating natural face-to-face conversations.
              We'd love to give you a demo.
            </Typography>
          </div>
        }

        {formSubmitted ? (
          <div className={styles.formMessageHolder}>
            <Typography className={classes.formMessage}>
              Thanks for submitting a tour request.
            </Typography>
            <Typography className={classes.formMessage}>
              One of our team members will be in touch shortly.
            </Typography>

            <Typography
              variant="h1"
              className={`${classes.showboatLogoHeaderText} ${classes.showboatLogoHeaderTextFormMessage}`}
            >
              See you in Showboat!
            </Typography>
          </div>
        ) : (
          <>
            <div className={styles.formWrapper}>
              <div className={styles.formHolder}>
                <Typography className={classes.formHeader}>
                  About You
                </Typography>

                <TextField
                  variant="outlined"
                  value={name}
                  onChange={handleNameChange}
                  className={classes.tourFormField}
                  label="Name"
                  placeholder="Name"
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                      root: classes.inputRoot,
                      focused: classes.inputFocused,
                    },
                  }}
                  error={nameRequiredError}
                />

                <TextField
                  variant="outlined"
                  value={email}
                  onChange={handleEmailChange}
                  className={classes.tourFormField}
                  name="Email"
                  label="Email"
                  placeholder="Email"
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                  }}
                  error={emailRequiredError}
                />

                <InputMask
                  mask="(999) 999-9999"
                  value={phone}
                  disabled={false}
                  onChange={handlePhoneChange}
                  maskChar=" "
                  key="input mask"
                >
                  {() => (
                    <TextField
                      variant="outlined"
                      className={classes.tourFormField}
                      name="Phone"
                      placeholder="Phone"
                      label="Phone"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                    />
                  )}
                </InputMask>

                <TextField
                  variant="outlined"
                  value={company}
                  onChange={handleCompanyChange}
                  className={classes.tourFormField}
                  name="Company"
                  label="Company"
                  placeholder="Company"
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                  }}
                />

                <FormControl component="fieldset" variant="standard">
                  <FormLabel
                    component="legend"
                    className={`${classes.formHeader} ${classes.formLegend}`}
                  >
                    Intended Use
                  </FormLabel>

                  {intendedUseError && (
                    <Typography className={classes.primaryInterestError}>
                      Please make a selection
                    </Typography>
                  )}

                  <FormGroup
                    style={{
                      position: "relative",
                      left: 15,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={events}
                          onChange={handleEventsChange}
                          name="events"
                          value="events"
                          color="primary"
                        />
                      }
                      label="Events"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={salesEnvironments}
                          onChange={handleSalesEnvironmentsChange}
                          name="salesEnvironments"
                          value="salesEnvironments"
                          color="primary"
                        />
                      }
                      label="Sales Environments"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={internalUse}
                          onChange={handleInternalMeetingsChange}
                          name="internalMeetings"
                          value="internalMeetings"
                          color="primary"
                        />
                      }
                      label="Internal Meetings"
                    />
                  </FormGroup>
                </FormControl>

                <TextField
                  variant="outlined"
                  value={notes}
                  onChange={handleNotesChange}
                  className={classes.tourFormField}
                  name="Notes"
                  label="Notes"
                  placeholder="Notes"
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                  }}
                  inputProps={{ maxLength: 500 }}
                  multiline={true}
                  rows={5}
                />

                <Button
                  className={classes.requestTourButton}
                  variant="contained"
                  color="primary"
                  onClick={handleFormSubmit}
                  disabled={submitLoadingSpinner}
                >
                  Request a Tour
                </Button>

                {submitLoadingSpinner && (
                  <CircularProgress className={classes.loadingSpinner} />
                )}
              </div>
            </div>

            <div className={styles.mainGridHolderTwo}>
              <div className={styles.gridTwoTextHolder}>
                <Typography variant="h2" className={classes.gridTwoHeader}>
                  Face-to-face in virtual space
                </Typography>
                <Typography variant="body1" className={classes.gridTwoText}>
                  In Showboat, each visitor configures a simple avatar which
                  allows freedom of movement around the virtual environment.
                  With webcam feeds streaming right on each avatar's face,
                  communication happens naturally, just like you would in the
                  real world.
                </Typography>
              </div>
              <div className={styles.gridTwoImageHolder}>
                <img
                  className={styles.groupImage}
                  alt="showboat avatar example"
                  src="assets/images/sb-group.png"
                />
              </div>
            </div>
          </>
        )}

        <div className={styles.mainGridHolderThree}>
          <div className={styles.leftTextHolder}>
            <Typography variant="h2" className={classes.gridThreeHeader}>
              Easy
            </Typography>
            <div className={styles.leftSubHeaderHolder}>
              <Typography variant="h2" className={classes.gridThreeSubHeader}>
                Showboat runs in a browser.
              </Typography>
              <Typography
                variant="h2"
                className={`${classes.gridThreeSubHeader} ${classes.gridThreeSubheaderBottom}`}
              >
                No software to download or learn.
              </Typography>
            </div>
          </div>
          <div className={styles.rightTextHolder}>
            <Typography variant="h2" className={classes.gridThreeRightHeader}>
              Powerful
            </Typography>

            <div className={styles.rightSubHeaderHolder}>
              <Typography
                variant="h2"
                className={`${classes.gridThreeSubHeader} ${classes.gridThreeSubheaderRight}`}
              >
                Event Chat
              </Typography>
              <Typography
                variant="h2"
                className={`${classes.gridThreeSubHeader} ${classes.gridThreeSubheaderRight}`}
              >
                Direct Messages
              </Typography>
              <Typography
                variant="h2"
                className={`${classes.gridThreeSubHeader} ${classes.gridThreeSubheaderRight}`}
              >
                Teleport
              </Typography>
              <Typography
                variant="h2"
                className={`${classes.gridThreeSubHeader} ${classes.gridThreeSubheaderRight}`}
              >
                Screenshare
              </Typography>
              <Typography
                variant="h2"
                className={`${classes.gridThreeSubHeader} ${classes.gridThreeSubheaderRight}`}
              >
                Slide Presentations
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
}
