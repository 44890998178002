import { makeStyles, createStyles } from '@material-ui/core/styles';

const TermsOfServiceStyles = makeStyles((theme: any) =>
  createStyles({
    termsOfServiceHolder: {
      backgroundColor: theme.palette.background.paper,
      borderTop: "5px solid #FF0190",
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
      width: 1166,
      height: 12850,
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        width: 920,
        marginBottom: 1550,
      },
      [theme.breakpoints.down("sm")]: {
        width: 720,
        marginBottom: 4150,
        marginTop: "3%",
      },
      [theme.breakpoints.down("xs")]: {
        width: 600,
        marginTop: "4%",
        marginBottom: 7300,
      },
      "@media (max-width: 614.95px)": {
        width: "100%",
        borderRadius: 0,
        marginTop: "12%",
        marginBottom: 9100,
      },
      "@media (max-width: 480px)": {
        marginBottom: 12500,
      },
      "@media (max-width: 450px)": {
        marginBottom: 14300,
      },
      "@media (max-width: 425px)": {
        marginTop: "18%",
        marginBottom: 15700,
      },
      "@media (max-width: 374.95px)": {
        marginBottom: 19700,
      },
      "@media (max-width: 349.95px)": {
        marginTop: "20%",
        marginBottom: 19800,
      },
    },
    termsOfServiceHeader: {
      fontSize: "2.5rem",
      fontFamily: "Open Sans, sans-serif",
      top: 59,
      position: "relative",
      marginBottom: 120,
      fontWeight: 300,
      "@media (max-width: 479.95px)": {
        fontSize: "2.25rem",
      },
      "@media (max-width: 449.95px)": {
        fontSize: "2rem",
      },
      "@media (max-width: 374.95px)": {
        fontSize: "1.75rem",
      },
    },
    termsOfServiceTextHeader: {
      fontSize: "1.5rem",
      color: "#FFB700",
      fontFamily: "Open Sans, sans-serif",
      marginBottom: 25,
      position: "relative",
      fontWeight: 400,
      left: 2,
      "@media (max-width: 374.95px)": {
        fontSize: "1.4rem",
      },
    },
    termsOfServiceTextSubheader: {
      fontSize: "1.25rem",
    },
    termsOfServiceText: {
      fontSize: "1rem",
      lineHeight: 2,
      marginBottom: 25,
      wordWrap: "break-word",
    },
    termsOfServiceTextListHeader: {},
    termsOfServiceTextBottom: {
      marginBottom: 45,
    },
    privacyPolicyTextList: {
      lineHeight: 2,
      fontSize: "1rem",
    },
    termsOfServiceTextEnd: {
      marginBottom: 100,
    },
    link: {
      color: "white",
      marginRight: 10,
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

export { TermsOfServiceStyles }