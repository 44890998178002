import React, { ReactElement, useEffect } from 'react';
import ReactGA from "react-ga";

import ProducerPortalGuide from '../../components/ProducerPortalGuide/ProducerPortalGuide';

import styles from './ProducerPortalPage.module.css';

interface Props {
  
}

export default function ProducerPortalPage(props: Props): ReactElement {

  useEffect(() => {
    document.title = "Showboat Producer Portal Guide";

    //Trigger Google Analytics pageview
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])
  
  return (
    <div className={styles.producerPortalPageWrapper}>
      <ProducerPortalGuide />
    </div>
  )
}
