import React, { ReactElement } from 'react'

interface Props {
  
}

export default function EbookIcon({}: Props): ReactElement {
  return (
    <svg
      id="auto_stories_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_344" data-name="Group 344">
        <rect
          id="Rectangle_627"
          data-name="Rectangle 627"
          width="24"
          height="24"
          fill="none"
        />
      </g>
      <g id="Group_345" data-name="Group 345">
        <path
          id="Path_995"
          data-name="Path 995"
          d="M22.47,5.2A10.571,10.571,0,0,0,21,4.59V16.62A10.288,10.288,0,0,0,17.5,16,10.6,10.6,0,0,0,12,17.58V5.48A10.928,10.928,0,0,0,1.53,5.2.973.973,0,0,0,1,6.08V18.16a.988.988,0,0,0,1,.99,1.065,1.065,0,0,0,.48-.12A8.694,8.694,0,0,1,6.5,18,9.044,9.044,0,0,1,12,20a9.044,9.044,0,0,1,5.5-2,8.582,8.582,0,0,1,4.02,1.04,1.065,1.065,0,0,0,.48.12.994.994,0,0,0,1-.99V6.08A.973.973,0,0,0,22.47,5.2ZM10,16.62a10.189,10.189,0,0,0-7,0V6.71a8.924,8.924,0,0,1,7,.01ZM19,.5l-5,5V15l5-4.5Z"
          fill="#3f01a8"
        />
      </g>
    </svg>
  );
}
