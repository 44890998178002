import { makeStyles, createStyles } from '@material-ui/core/styles';

const PrivacyPolicyStyles = makeStyles((theme: any) =>
  createStyles({
    privacyPolicyHolder: {
      backgroundColor: theme.palette.background.paper,
      borderTop: "5px solid #FF0190",
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
      width: 1166,
      height: 2750,
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        width: 920,
        marginBottom: 250,
      },
      [theme.breakpoints.down("sm")]: {
        width: 720,
        marginBottom: 450,
        marginTop: "3%",
      },
      [theme.breakpoints.down("xs")]: {
        width: 600,
        marginTop: "4%",
        marginBottom: 1000,
      },
      "@media (max-width: 614.95px)": {
        width: "100%",
        borderRadius: 0,
        marginTop: "12%",
        marginBottom: 1500,
      },
      "@media (max-width: 480px)": {
        marginBottom: 1900,
      },
      "@media (max-width: 425px)": {
        marginTop: "18%",
        marginBottom: 2400,
      },
      "@media (max-width: 374.95px)": {
        marginBottom: 2800,
      },
      "@media (max-width: 349.95px)": {
        marginTop: "20%",
        marginBottom: 3200,
      },
    },
    privacyPolicyHeader: {
      fontSize: "2.5rem",
      fontFamily: "Open Sans, sans-serif",
      top: 59,
      position: "relative",
      marginBottom: 80,
      fontWeight: 300,
      "@media (max-width: 479.95px)": {
        fontSize: "2.25rem",
      },
      "@media (max-width: 449.95px)": {
        fontSize: "2rem",
      },
      "@media (max-width: 374.95px)": {
        fontSize: "1.75rem",
      },
    },
    privacyPolicySubheader: {
      fontSize: "1rem",
      position: "relative",
      marginBottom: 40,
      "@media (max-width: 449.95px)": {
        fontSize: "1rem",
      },
    },
    privacyPolicyTextHeader: {
      fontSize: "1.5rem",
      color: "#FFB700",
      fontFamily: "Open Sans, sans-serif",
      marginBottom: 25,
      position: "relative",
      fontWeight: 400,
      left: 2,
      "@media (max-width: 374.95px)": {
        fontSize: "1.4rem",
      },
    },
    privacyPolicyText: {
      fontSize: "1rem",
      lineHeight: 2,
      marginBottom: 25,
      wordWrap: "break-word",
    },
    privacyPolicyTextBottom: {
      marginBottom: 45,
    },
    privacyPolicyTextSubheader: {
      fontSize: "1.25rem",
    },
    privacyPolicyTextList: {
      lineHeight: 2,
      fontSize: "1rem",
    },
    link: {
      color: "white",
      marginRight: 10,
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

export { PrivacyPolicyStyles }; 