import { Typography } from '@material-ui/core';
import React, { ReactElement } from 'react'
import EmailAssets from './EmailAssets/EmailAssets';
import InRoomAssets from './InRoomAssets/InRoomAssets';
import { ProducerPortalAssetListStyles } from './ProducerPortalAssetListStyles'
import WebAssets from './WebAssets/WebAssets';

interface Props {
  
}

export default function ProducerPortalAssetList(props: Props): ReactElement {

  const classes = ProducerPortalAssetListStyles();
  
  return (
    <div className={classes.producerPortalAssetListHolder}>
      <Typography variant="h1" className={classes.producerPortalAssetListHeader}>
        Showboat Asset Specifications
      </Typography>

      <WebAssets />

      <InRoomAssets />

      <EmailAssets />
    </div>
  )
}
