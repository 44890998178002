import { makeStyles, createStyles } from '@material-ui/core/styles';

const ProducerPortalGuideStyles = makeStyles((theme: any) => createStyles({
  producerPortalGuideHolder: {
    width: 996,
    minHeight: 20650,
    height: "auto",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    top: 80,
    textAlign: "left",
    ['@supports ( -moz-appearance:none )']: {
      minHeight: 20700
    },
    ['@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)']: {
      minHeight: 20500
    },
    ['@media(max-width: 1080px)']: {
      width: 800,
      minHeight: 20450,
      ['@supports ( -moz-appearance:none )']: {
        minHeight: 20300
      },
    },
    ['@media screen and (max-width: 1080px) and (-ms-high-contrast: none), (-ms-high-contrast: active)']: {
      minHeight: 20300
    },
    ['@media(max-width: 850px)']: {
      width: 650,
      minHeight: 20300
    },
    ['@media(max-width: 675px)']: {
      width: 475,
      minHeight: 32450,
    },
    ['@media(max-width: 500px)']: {
      width: 360,
      minHeight: 31700

    },
    ['@media (max-width: 375px)']: {
      width: 300,
      minHeight: 32150
    }
  },
  producerPortalGuideHeader: {
    fontSize: "2.5rem",
    fontWeight: 400,
    color: "white",
    position: "relative",
    fontFamily: "Open Sans, sans-serif",
    top: 30,
    ['@media(max-width: 850px)']: {
      fontSize: "2rem"
    },
    ['@media(max-width: 500px)']: {
      fontSize: "1.8rem"
    }
  },
  gridHeader: {
    color: "#00B6FF",
    fontFamily: "Open Sans, sans-serif",
    position: "relative",
    fontSize: "2.25rem",
    fontWeight: 400,
    ['@media(max-width: 500px)']: {
      fontSize: "1.8rem"
    }
  },
  loginProjectsHeader: {
    top: 75,
  },
  divider: {
    color: "white",
    backgroundColor: "white",
    opacity: 1,
    height: 1,
    position: "relative",
  },
  loginProjectsDivider: {
    top: 100,
  },
  loginProjectsSubheader: {
    color: "#FFB700",
    position: "relative",
    top: 0,
    left: 0,
    fontSize: "1.5rem",
  },
  loginProjectsListHeader: {
    fontSize: "1.25rem",
    position: "relative",
    color: "#FFB700",
    fontWeight: 400,
    top: 55,
  },
  loginProjectsListText: {
    color: "white",
    position:"relative",
    fontSize: "1rem"
  },
  loginProjectsText: {
    color: "white",
    position:"relative",
    fontSize: "1rem",
    top: 40,
  },
  bookingsOverviewHeader: {
    top: 150,
  },
  bookingsOverviewDivider: {
    top: 175,
  },
  bookingsOverviewText: {
    fontSize: "1rem",
    color: "white",
    position: "relative",
    top: 50,
    display: "inline-block",
    width: "95%"
  },
  bookingsOverviewListText: {
    color: "white",
    position:"relative",
    fontSize: "1rem"
  },
  bookingsOverviewSubheader: {
    fontSize: "1.25rem",
    color: "#EEAA00",
    position: "relative",
    top: 55,
  },
  bookingsOverviewSubheaderIntake: {
    top: 15,
  },
  bookingsOverviewTextIntake: {
    top: 30,
  },
  bookingsOverviewSubheaderSignUp: {
    marginBottom: 20,
  }
})
)

export { ProducerPortalGuideStyles };