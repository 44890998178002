import React, { ReactElement, useEffect } from 'react';
import ReactGA from "react-ga";

import MainFooter from '../../components/MainFooter/MainFooter';
import TourDisplay from '../../components/TourDisplay/TourDisplay';
import WideImage from '../../components/WideImage/WideImage';
import { TourFormPageStyles } from './TourFormPageStyles';
import styles from './TourFormPage.module.css';
import TourForm from '../../components/TourForm/TourForm';

interface Props {
  
}

export default function TourFormPage(props: Props): ReactElement {

  useEffect(() => {
    //Trigger Google Analytics pageview
    ReactGA.pageview(window.location.pathname + window.location.search)
    
    document.title = "Showboat Tour Form"
  }, [])
  
  const classes = TourFormPageStyles();
  
  return (
    <div className={styles.tourPageWrapper}>
      <WideImage />
      <TourForm />
    </div>
  )
}
