import React, { ReactElement } from 'react';
import { Button, IconButton, Paper, Typography } from '@material-ui/core';
import ReactGA from "react-ga";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import HomeIcon from '@material-ui/icons/HomeOutlined';

import styles from './Nav.module.css';
import { NavStyles } from './NavStyles';

interface Props {
  presenterPage: boolean,
  producerPortalGuide: boolean,
  tourFormPage: boolean,
  northPolePage?: boolean,
}

export default function Nav(props: Props): ReactElement {

  const handleTourButtonClick = () => {
    //Trigger Google Analytics event
    ReactGA.event({
      category: "Actions",
      action: "Clicked Tour Link",
    });

    window.location.replace("/tour");
  }

  const handleHomeButtonClick = () => {
    window.location.replace("/");
  }
  
  const classes = NavStyles();

  let contentHolderClasses;

  if (props.presenterPage) {
    contentHolderClasses = `${styles.navContentHolder} ${styles.navContentHolderPresenter}`;
  } else {

    if (props.producerPortalGuide) {
      contentHolderClasses = `${styles.navContentHolder} ${styles.navContentHolderProducerPortal}`
    } else {
      contentHolderClasses = styles.navContentHolder
    }
    
  }

  let imageClasses;
  
  if (props.presenterPage) {
    imageClasses = `${styles.showboatLogo} ${styles.showboatLogoPresenter}`
  } else {

    if (props.producerPortalGuide) {
      imageClasses = `${styles.showboatLogo} ${styles.showboatLogoProducerPortal}`
    } else {
      imageClasses = styles.showboatLogo
    }
    
  }

  let headerClasses;

  if (props.presenterPage) {
    headerClasses = `${classes.showboatHeader} ${classes.showboatHeaderPresenter}`
  } else {
    
    if (props.producerPortalGuide) {
      headerClasses = `${classes.showboatHeader} ${classes.showboatHeaderProducerPortal}`
    } else {
      headerClasses = classes.showboatHeader
    }

  }
  
  return (
    <Paper className={classes.nav}>
      <div className={contentHolderClasses}>
        <img
          alt="showboat logo"
          className={imageClasses}
          src="/assets/images/sb-logo.png"
        />

        <Typography variant="h1" className={props.northPolePage ? `${headerClasses} ${classes.headerNorthPole}` : headerClasses}>
          Showboat
        </Typography>

        {!props.presenterPage &&
        !props.producerPortalGuide /*  && !props.tourFormPage */ &&
        props.northPolePage ? (
          <>
            <IconButton
              className={`${classes.homeButton} ${classes.homeButtonNorthPole}`}
              onClick={handleHomeButtonClick}
            >
              <HomeIcon className={classes.homeIcon} />
            </IconButton>
            <Button
              className={classes.requestTourButton}
              variant="contained"
              color="primary"
              onClick={handleTourButtonClick}
            >
              Request a Tour
            </Button>
          </>
        ) : props.tourFormPage ? (
          <IconButton
            className={classes.homeButton}
            onClick={handleHomeButtonClick}
          >
            <HomeIcon className={classes.homeIcon} />
          </IconButton>
        ) : (
          <Button
            className={classes.requestTourButton}
            variant="contained"
            color="primary"
            onClick={handleTourButtonClick}
          >
            Request a Tour
          </Button>
        )}
      </div>
    </Paper>
  );
}
