import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import logo from "./logo.svg";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import Nav from "./components/Nav/Nav";
import PrivacyPage from "./Pages/PrivacyPage/PrivacyPage";
import MainFooter from "./components/MainFooter/MainFooter";
import TourFooter from "./components/TourFooter/TourFooter";
import MainPage from "./Pages/MainPage/MainPage";
import PresenterToolsPage from "./Pages/PresenterToolsPage/PresenterToolsPage";
import ProducerPortalPage from "./Pages/ProducerPortalPage/ProducerPortalPage";
import TourPage from "./Pages/TourPage/TourPage";
import TourFormPage from "./Pages/TourFormPage/TourFormPage";
import AssetList from "./Pages/AssetList/AssetList";
import TermsOfServicePage from "./Pages/TermsOfServicePage/TermsOfServicePage";
import NorthPolePage from "./Pages/NorthPolePage/NorthPolePage";
import EbookEffectiveVirtualNetworking from "./Pages/EbookEffectiveVirtualNetworking/EbookEffectiveVirtualNetworking";

function App() {
  const DarkTheme = createMuiTheme({
    breakpoints: {
      values: {
        xxxs: 0,
        xxs: 320,
        xs: 615,
        sm: 740,
        md: 960,
        lg: 1281,
      },
    },
    palette: {
      type: "dark",
      primary: {
        main: "#FF0092",
      },
      secondary: {
        main: "rgb(148, 248, 75)",
      },
      background: {
        light: "#1A1A1A",
        paper: "black",
      },
    },
  });

  return (
    <ThemeProvider theme={DarkTheme}>
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/producer-portal">
              <Nav
                presenterPage={false}
                producerPortalGuide={true}
                tourFormPage={false}
              />
              <ProducerPortalPage />
            </Route>
            <Route exact path="/privacy">
              <Nav
                presenterPage={false}
                producerPortalGuide={false}
                tourFormPage={false}
              />
              <PrivacyPage />
            </Route>
            <Route exact path="/north-pole">
              <Nav
                presenterPage={false}
                producerPortalGuide={false}
                tourFormPage={false}
                northPolePage={true}
              />
              <NorthPolePage ptp={false} />
              <MainFooter />
            </Route>
            <Route exact path="/ptp">
              <Nav
                presenterPage={false}
                producerPortalGuide={false}
                tourFormPage={false}
                northPolePage={true}
              />
              <NorthPolePage ptp={true} />
              <MainFooter />
            </Route>
            <Route exact path="/terms">
              <Nav
                presenterPage={false}
                producerPortalGuide={false}
                tourFormPage={false}
              />
              <TermsOfServicePage />
            </Route>
            <Route exact path="/presenter-tools">
              <Nav
                presenterPage={true}
                producerPortalGuide={false}
                tourFormPage={false}
              />
              <PresenterToolsPage />
            </Route>
            {/*  <Route exact path="/tour">
              <Nav
                presenterPage={false}
                producerPortalGuide={false}
                tourFormPage={false}
              />
              <TourPage />
              <TourFooter />
            </Route> */}
            <Route exact path="/tour">
              <Nav
                presenterPage={false}
                producerPortalGuide={false}
                tourFormPage={true}
              />
              <TourFormPage />
              <TourFooter />
            </Route>
            <Route exact path="/asset-list">
              <Nav
                presenterPage={false}
                producerPortalGuide={true}
                tourFormPage={false}
              />
              <AssetList />
            </Route>
            <Route exact path="/ebook/effective-virtual-networking">
              <Nav
                presenterPage={false}
                producerPortalGuide={false}
                tourFormPage={true}
              />
              <EbookEffectiveVirtualNetworking />
              <MainFooter />
            </Route>
            <Route exact path="/">
              <Nav
                presenterPage={false}
                producerPortalGuide={false}
                tourFormPage={false}
              />
              <MainPage />
              <MainFooter />
            </Route>
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
