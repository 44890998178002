import { Divider, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

import "../../../App.css";
import styles from './LoginProjects.module.css';
import {LoginProjectsStyles } from './LoginProjectsStyles';

interface Props {
  
}

export default function LoginProjects(props: Props): ReactElement {

  const classes = LoginProjectsStyles();
  
  return (
    <React.Fragment>
      <Typography variant="h2" className={`${classes.gridHeader} ${classes.loginProjectsHeader}`}>
        Login/Projects
      </Typography>

      <Divider className={`${classes.divider} ${classes.loginProjectsDivider}`}></Divider>

      <div className={styles.loginProjectsGrid}>
        
        <div className={styles.loginProjectsGridItem}>
          <Typography variant="h3" className={classes.loginProjectsSubheader}>
            Login
          </Typography>

          <img className={`image ${styles.loginProjectsImage}`} alt="Login screenshot" src="assets/images/loginProjects-1.png" />
          
          <Typography variant="h4" className={classes.loginProjectsListHeader}>
            Logging In
          </Typography>

          <ul className={`list ${styles.loginProjectsList}`}>
            <li className={`listItem ${styles.loginProjectsListItem}`}>
              <Typography variant="body1" className={classes.loginProjectsListText}>
                Enter the login name and password that was provided to you by Showboat
              </Typography>
            </li>
          </ul>

        </div>

        <div className={styles.loginProjectsGridItem}>
          <Typography variant="h3" className={classes.loginProjectsSubheader}>
            Project and Space
          </Typography>

          <img className={`image ${styles.loginProjectsImage}`} alt="Project and Space selection" src="assets/images/loginProjects-2.png" />

          <Typography variant="h4" className={classes.loginProjectsListHeader}>
            Choose a Project
          </Typography>

          <ul className={`list ${styles.loginProjectsList}`}>
            <li className={`listItem ${styles.loginProjectsListItem}`}>
              <Typography variant="body1" className={classes.loginProjectsListText}>
                A Project Name is set by the Showboat Staff
              </Typography>
            </li>
          </ul>

          <Typography variant="h4" className={classes.loginProjectsListHeader}>
            Choose a Space
          </Typography>

          <ul className={`list ${styles.loginProjectsList}`}>
            <li className={`listItem ${styles.loginProjectsListItem}`}>
              <Typography variant="body1" className={classes.loginProjectsListText}>
                These are the 3D spaces in which your company can book 
                multiple events
              </Typography>
            </li>
          </ul>
        </div>

        <div className={styles.loginProjectsGridItem}>
          <Typography variant="h3" className={classes.loginProjectsSubheader}>
            Space Overview
          </Typography>

          <img className={`image ${styles.loginProjectsImage}`} alt="Space overview" src="assets/images/loginProjects-3.png" />
          
          <Typography variant="body1" className={classes.loginProjectsText}>
            On the left-hand side, you will see your space’s name along with 
            the usage information.
          </Typography>

          <ul className={`list ${styles.loginProjectsList} ${styles.loginProjectsSpaceOverviewList}`}>
            <li className={`listItem ${styles.loginProjectsListItem}`}>
              <Typography variant="body1" className={classes.loginProjectsListText}>
                Opens: Starting date of bookings
              </Typography>
            </li>

            <li className={`listItem ${styles.loginProjectsListItem}`}>
              <Typography variant="body1" className={classes.loginProjectsListText}>
                Closes: Closing date of bookings
              </Typography>
            </li>

            <li className={`listItem ${styles.loginProjectsListItem}`}>
              <Typography variant="body1" className={classes.loginProjectsListText}>
                Usage: Number of hours used out of total purchased
              </Typography>
            </li>

            <ul className={styles.loginProjectsSublist}>
              <li className={`listItem${styles.loginProjectsListItem}`}>
                <Typography variant="body1" className={classes.loginProjectsListText}>
                  If you are running low on hours contact a Showboat Sales Representative
                </Typography>
            </li>
            </ul>
          </ul>

        </div>

      </div>
    </React.Fragment>
  )
}
