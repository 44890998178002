import React, { ReactElement, useEffect } from 'react';
import ReactGA from "react-ga";

import PresenterTools from '../../components/PresenterTools/PresenterTools';
import styles from './PresenterToolsPage.module.css';

interface Props {
  
}

export default function PresenterToolsPage(props: Props): ReactElement {

  useEffect(() => {
    document.title = "Showboat Presenter Tools"

    //Trigger Google Analytics pageview
    ReactGA.pageview(window.location.pathname + window.location.search);
    
  }, [])
  
  return (
    <div className={styles.presenterToolsWrapper}>
      <PresenterTools />
    </div>
  )
}
